import React, { useRef, useState, useEffect } from 'react';
import { Button, Row, Col, Card, Modal } from 'antd';
import { useModel, history, useIntl } from 'umi';



const VideoCall = () => {
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const [isCallActive, setIsCallActive] = useState(false);
  const [localStream, setLocalStream] = useState(null);
  const [peerConnection, setPeerConnection] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([{ username: 'User1' }, { username: 'User2' }]); // Beispielbenutzer





  const { socket, setSocket } = useModel('socket', (ret) => ({
    socket: ret.socket,
    setSocket: ret.setSocket
  }));

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const startCall = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    localVideoRef.current.srcObject = stream;
    setLocalStream(stream);

    const pc = new RTCPeerConnection();
    stream.getTracks().forEach(track => pc.addTrack(track, stream));

    pc.onicecandidate = event => {
      if (event.candidate) {
        // Senden Sie den ICE-Kandidaten an den Remote-Peer
        sendToServer({
          type: 'candidate',
          candidate: event.candidate,
        });
      }
    };

    pc.ontrack = event => {
      remoteVideoRef.current.srcObject = event.streams[0];
    };

    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);
    // Senden Sie das Angebot an den Remote-Peer
    sendToServer({
      type: 'offer',
      offer: offer,
    });

    setPeerConnection(pc);
    setIsCallActive(true);
  };

  const endCall = () => {
    peerConnection.close();
    localStream.getTracks().forEach(track => track.stop());
    setIsCallActive(false);
  };

  const handleMessage = async (message) => {
    if (message.type === 'offer') {
      const pc = new RTCPeerConnection();
      setPeerConnection(pc);

      pc.onicecandidate = event => {
        if (event.candidate) {
          sendToServer({
            type: 'candidate',
            candidate: event.candidate,
          });
        }
      };

      pc.ontrack = event => {
        remoteVideoRef.current.srcObject = event.streams[0];
      };

      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      localVideoRef.current.srcObject = stream;
      setLocalStream(stream);

      stream.getTracks().forEach(track => pc.addTrack(track, stream));

      await pc.setRemoteDescription(new RTCSessionDescription(message.offer));
      const answer = await pc.createAnswer();
      await pc.setLocalDescription(answer);

      sendToServer({
        type: 'answer',
        answer: answer,
      });
    } else if (message.type === 'answer') {
      await peerConnection.setRemoteDescription(new RTCSessionDescription(message.answer));
    } else if (message.type === 'candidate') {
      await peerConnection.addIceCandidate(new RTCIceCandidate(message.candidate));
    }
  };

  const sendToServer = (message) => {
    //socket.emit('broadcast', message);
    // Implementieren Sie die Logik zum Senden von Nachrichten an den Server
    // Dies könnte über WebSockets oder eine andere Echtzeit-Kommunikationsmethode erfolgen
  };


  useEffect(() => {
    console.log(socket);
    if(socket) {
        socket.on('livecam_message', handleMessage);
    }

  }, [socket]);

  return (
    <div>
      <Button shape="circle" size={'large'} className="btn-webcam" type="primary" onClick={showModal}>
        Videoanruf starten
      </Button>
      <Modal title="Videoanruf" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className="video-container">
          <Card title="Lokales Video">
            <video ref={localVideoRef} autoPlay muted style={{ width: '100%' }} />
          </Card>
          <Card title="Remote Video">
            <video ref={remoteVideoRef} autoPlay style={{ width: '100%' }} />
          </Card>
          <Row justify="center" style={{ marginTop: '20px' }}>
            {!isCallActive ? (
              <Button type="primary" onClick={startCall}>
                Anruf starten
              </Button>
            ) : (
              <Button type="danger" onClick={endCall}>
                Anruf beenden
              </Button>
            )}
          </Row>
        </div>
        <div className="channel-user">
          <ul>
            {users.map((user, index) => (
              <li key={index}>{user.username}</li>
            ))}
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default VideoCall;