import React, { Component } from 'react';
import Countdown from 'react-countdown';
import jwt_decode from "jwt-decode";
import { Modal, Button, Alert, Divider, Space } from 'antd';
import { history } from 'umi';
import { refreshToken, currentUser } from '@/services/ant-design-pro/api';
import Helper from '@/helper/Helper';

import moment from 'dayjs';


class SessionCountdown extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isModalVisible: false,
      jwtToken: null,
      decodedToken: null,
      serverTime: null,
      currentTime: null,
      logoutTimer: Date.now() + 30000
    }


    this.countdownRef = React.createRef();
    this.logoutCountdownRef = React.createRef();

    this.onRefreshToken = this.onRefreshToken.bind(this);
    this.initTimer = this.initTimer.bind(this);
  }


  componentDidUpdate(previousProps, previousState) {
    if (previousProps.initialState !== this.props.initialState) {
      this.initTimer();
    }
  }

  componentDidMount() {
    this.initTimer();
  }

  

  initTimer() {




    let component = this;
    let jwtToken = Helper.getAccessToken();

    let result = currentUser({}).then(function (response) {
      if (jwtToken) {
        let decodedToken = jwt_decode(jwtToken);
  
        if ((decodedToken.exp * 1000) - 250000 <= 0) {
          component.setState({
            isModalVisible: true,
          })
        }
        
  
        component.setState({
          jwtToken: jwtToken,
          decodedToken: decodedToken,
          serverTime: response.server_time
        });


        setInterval(function() {
          component.setState({
            serverTime: component.state.serverTime+1
          });
        }, 1000);
 
      }
    });

    
  }

  onRefreshToken(e) {
    const component = this;
    let result = refreshToken({}).then(function (response) {
      let user = Helper.getUser();
      let accessToken = Helper.getAccessToken();
      if (user && accessToken && response) {
        user.access_token = response.token;

        let serverTime = response.server_time;
        console.log(serverTime);

        Helper.setItem('user', JSON.stringify(user));

        let jwtToken = accessToken;
        let decodedToken = jwt_decode(jwtToken);
        component.setState({
          jwtToken: jwtToken,
          decodedToken: decodedToken,
          serverTime: serverTime,
          isModalVisible: false,
        }, function (e) {
          component.countdownRef.current.start();
          //component.logoutCountdownRef.current.stop();
        })
      } else {
        Helper.clearLocalStorage();
        window.location = '/user/login';
      }
    })
  }


  render() {
    let component = this;
    return (
      <>
        {this.state.decodedToken &&
          <>
            <div style={{ background: 'black', color: '#fff', display: 'none' }}>

              SessionCounter:
              <Countdown
                now={() => {
                  return moment(0).add(component.state.serverTime, 'seconds');
                }}
                ref={this.countdownRef}
                onComplete={() => {
                  component.onRefreshToken();
                  /*
                  component.setState({
                    isModalVisible: true,
                    logoutTimer: Date.now() + 30000
                  })*/
                }}
                date={(this.state.decodedToken.exp * 1000) - 250000}
              />
            </div>

            <Modal zIndex={10001} title={false} closable={false} footer={false} visible={this.state.isModalVisible}>
              <Alert
                message="Session Expires"
                description="Ihre Sitzung läuft bald ab. Möchten Sie diese erneuern?"
                type="warning"
                showIcon
              />

              <Divider />

              {this.state.isModalVisible &&
                <Countdown
                  ref={this.logoutCountdownRef}
                  onComplete={() => {
                    window.location = '/user/login';
                    //history.push('/user/login')
                  }}
                  date={this.state.logoutTimer}
                />
              }

              <Divider />

              <Space>
                <Button type="success" onClick={(e) => this.onRefreshToken(e)}>{'Ja'}</Button>
                <Button type="danger" onClick={() => history.push('/user/login')}>{'Logout'}</Button>
              </Space>
            </Modal>
          </>
        }
      </>
    );
  }
}

export default SessionCountdown;
