import React, { useState, useEffect } from 'react';
import { Collapse, Divider, Card, Spin } from 'antd';

import { getBlock } from '@/services/ant-design-pro/api';




// You need to import the CSS only once
import './assets/css/index.css';



const Block = (props) => {

  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(false);
  const [title, setTitle] = useState('loading...');


  const getContent = async () => {

    setLoading(true);
    let result = await getBlock({ block_content: props.blockContent }, {});


    setContent(result.body[0].value);
    setTitle(result.info[0].value);

    //console.log(result);

    setLoading(false);

  }


  useEffect(() => {

    getContent();

    return () => {
      //Unmount
    };
  }, []);


  return (
    <>
      <Card loading={loading} className={'cms-block ' + ((props.noPadding) ? 'no-padding' : '')}>
        {!loading && content &&
          <div
            dangerouslySetInnerHTML={{ __html: content }}
          />
        }
      </Card>
    </>
  );
};

export default Block;
