// new file called DogPicture.jsx
import React, { useEffect, useState } from 'react';
import Helper from '@/helper/Helper';
import { loadTerms } from '@/services/ant-design-pro/api';
import { Tag, Space, Card } from 'antd';

const { CheckableTag } = Tag;
import { useModel, useIntl, history } from 'umi';
import ReactGA from "react-ga4";


const Intro = (props) => {


  const intl = useIntl();

  ReactGA.event({
    category: "cumcierge",
    action: "cumcierge_intro",
    //label: "credits confirm", // optional
    //value: 99, // optional, must be a number
    //nonInteraction: true, // optional, true/false
    //transport: "xhr", // optional, beacon/xhr/image
  });



  const { isOpen, setIsOpen } = useModel('statusMessage', (ret) => ({
    isOpen: ret.isOpen,
    setIsOpen: ret.setIsOpen
  }));


  const handleSearch = async () => {
    props.actions.handleRegion();
  }

  const handleSupport = async () => {
    props.handleCancel();
    history.push('/support');

    ReactGA.event({
      category: "cumcierge",
      action: "cumcierge_support",
      //label: "credits confirm", // optional
      //value: 99, // optional, must be a number
      //nonInteraction: true, // optional, true/false
      //transport: "xhr", // optional, beacon/xhr/image
    });
  }

  const handleLogin = async () => {
    props.handleCancel();
    history.push('/user/login');

    ReactGA.event({
      category: "cumcierge",
      action: "cumcierge_login",
      //label: "credits confirm", // optional
      //value: 99, // optional, must be a number
      //nonInteraction: true, // optional, true/false
      //transport: "xhr", // optional, beacon/xhr/image
    });
  }

  const handleStatusMessages = async () => {
    props.handleCancel();
    setIsOpen(true);

    ReactGA.event({
      category: "cumcierge",
      action: "cumcierge_statusmessage",
      //label: "credits confirm", // optional
      //value: 99, // optional, must be a number
      //nonInteraction: true, // optional, true/false
      //transport: "xhr", // optional, beacon/xhr/image
    });
  }




  return (
    <div>


      <Space wrap>
        <Tag onClick={() => handleSearch()} >
          {intl.formatMessage({ id: 'cumcierge.escort.label', defaultMessage: 'Escort-Suche' })}
        </Tag>
        <Tag onClick={() => handleSupport()} >
          {intl.formatMessage({ id: 'cumcierge.support.label', defaultMessage: 'Support' })}
        </Tag>
        <Tag onClick={() => handleLogin()} >
          {intl.formatMessage({ id: 'cumcierge.login.label', defaultMessage: 'Login' })}
        </Tag>
        <Tag onClick={() => handleStatusMessages()} >
          {intl.formatMessage({ id: 'cumcierge.statusmessage.label', defaultMessage: 'Statusnachrichten' })}
        </Tag>
      </Space>

    </div>
  );
};

export default Intro;