import { useState } from 'react';

import { Button, Space } from 'antd';
import { Link } from "react-router-dom";
import { useModel, useAccess, Access, history, useIntl } from 'umi';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllDTIcons from '@fortawesome/pro-duotone-svg-icons';

import { Planet } from 'react-planet';

import NoticeIconMobile from '@/components/NoticeIcon/NoticeIconMobile';
import Filter from '@/pages/Home/components/Filter';
import MobileSettings from '@/pages/User/Settings/Mobile';
import WishlistList from '@/components/Wishlist/components/List'

import Helper from '@/helper/Helper';


// You need to import the CSS only once
import './assets/css/index.css';

const AppTabBar = () => {

  const intl = useIntl();


  const { filterState, setFilterState, defaultFilter } = useModel('filter', (ret) => ({
    filterState: ret.filterState,
    setFilterState: ret.setFilterState,
    defaultFilter: ret.defaultFilter,
  }));

  const { escortListState, setEscortListState } = useModel('escortList', (ret) => ({
    escortListState: ret.escortListState,
    setEscortListState: ret.setEscortListState,
  }));


  const { pageContext } = useModel('pageContext', (ret) => ({
    pageContext: ret.pageContext,
  }));

  const { escortNextId, escortPrevId } = useModel('escortList', (ret) => ({
    escortNextId: ret.escortNextId,
    escortPrevId: ret.escortPrevId,
  }));


  const { showCumcierge, setShowCumcierge } = useModel('cumcierge', (ret) => ({
    showCumcierge: ret.showCumcierge,
    setShowCumcierge: ret.setShowCumcierge,
  }));


  const access = useAccess();

  const [isOpen, setIsOpen] = useState(false);


  const onHomeButtonClick = async () => {



    setFilterState(defaultFilter);

    setEscortListState({
      ...escortListState,
      current: 1,
    })

    await Helper.waitTime(250);
    history.push('/?t=' + new Date().getTime());
    Helper.scrollToTop();
  }


  return (
    <div className="app-tab-bar">


      {access.isVisitor() === true &&
        <Space>
          <Link to={'/user/login'} title="Login"><Button title="Login" size={'large'} text icon={<FontAwesomeIcon icon={AllDTIcons.faUser} />}></Button></Link>

          {pageContext == 'profile' &&
            <>
              <Button title="Prev" size={'large'} disabled={!escortPrevId} text onClick={() => history.push('/profile/detail-' + escortPrevId)}>
                <FontAwesomeIcon icon={AllDTIcons.faArrowLeft} />
              </Button>
            </>
          }

          {false && pageContext != 'profile' &&
            <Button onClick={(e) => setShowCumcierge(!showCumcierge)} title="Cumcierge" size={'large'} text icon={<FontAwesomeIcon icon={AllDTIcons.faRobot} />}></Button>
          }

          {pageContext != 'profile' &&
            <Button title="Prev" size={'large'} text onClick={() => history.push('/support')}>
              <FontAwesomeIcon icon={AllDTIcons.faHeadset} />
            </Button>
          }


          <Button title="Home" onClick={() => onHomeButtonClick()} size={'large'} text icon={<FontAwesomeIcon icon={AllDTIcons.faHome} />}></Button>

          {pageContext == 'profile' &&
            <>
              <Button title="Next" size={'large'} disabled={!escortNextId} text onClick={() => history.push('/profile/detail-' + escortNextId)}>
                <FontAwesomeIcon icon={AllDTIcons.faArrowRight} />
              </Button>
            </>
          }

          {pageContext != 'profile' &&
            <WishlistList showButton />
          }

          <Button title="Filter" size={'large'} text >
            <Filter redirect iconOnly />
          </Button>
        </Space>
      }

      {access.isUser() === true &&
        <Space>

          <Link to={'/'}><Button size={'large'} text icon={<FontAwesomeIcon icon={AllDTIcons.faHome} />}></Button></Link>

          {/*
          <Dropdown
      menu={{
        items,
      }}
      placement="topLeft"
      arrow
    >
      <Button size={'large'} text icon={<FontAwesomeIcon icon={AllDTIcons.faBars} />}></Button>
    </Dropdown>
         */}


          <NoticeIconMobile key={'mobile'} placement={'top'} />
          <div className={(isOpen) ? 'planet-menu open' : 'planet-menu'}>
            <Planet
              open={isOpen}
              onClick={() => setIsOpen(!isOpen)}
              onClose={() => setIsOpen(false)}
              centerContent={(isOpen) ? <Button size={'large'} text icon={<FontAwesomeIcon icon={AllDTIcons.faXmark} />}></Button> : <Button size={'large'} text icon={<FontAwesomeIcon icon={AllDTIcons.faGrid2Plus} />}></Button>}
              hideOrbit
              autoClose
              orbitRadius={80}
              bounceOnClose
              rotation={102}
              bounceDirection="BOTTOM"
            >

              <Link to={'/user/credits'} onClick={() => setIsOpen(false)}><Button className="btn-sub" text icon={<FontAwesomeIcon icon={AllDTIcons.faCircleDollar} />}></Button></Link>
              <Link to={'/user/profile'} onClick={() => setIsOpen(false)}><Button className="btn-sub" text icon={<FontAwesomeIcon icon={AllDTIcons.faRectangleAd} />}></Button></Link>
              <Link to={'/user/banner'} onClick={() => setIsOpen(false)}><Button className="btn-sub" text icon={<FontAwesomeIcon icon={AllDTIcons.faBrowser} />}></Button></Link>
              <div />
              <div />
              <div />
              <div />
            </Planet>
          </div>
          <MobileSettings />
          <Button size={'large'} text >
            <Filter redirect iconOnly />
          </Button>
        </Space>
      }




      <WishlistList showList />


    </div>
  );
};

export default AppTabBar;
