import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { ProTable, TableDropdown } from '@ant-design/pro-components';
import { Button, Dropdown, Space, Tag, Modal, Badge } from 'antd';
import { useRef, useState } from 'react';
import request from 'umi-request';
import moment from 'moment';

import { getSupportTicket } from '@/services/ant-design-pro/api';

import CommentView from './CommentView'
import AddTicket from './AddTicket'


export default () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [supportTicket, setSupportTicket] = useState(false);

    const showModal = (e, supportTicket) => {
      setSupportTicket(supportTicket);
       setIsModalOpen(true);
     };

     const handleOk = () => {
       setIsModalOpen(false);
     };

     const handleCancel = () => {
       setIsModalOpen(false);
       setSupportTicket(false);
     };


     const onTicketClose = async (uuid) => {
       let params = {
          action: 'closeTicketAction',
          uuid: uuid
       }

       let result = await getSupportTicket(params, {});

       actionRef.current.reload();
     };

    const actionRef = useRef();

    const columns = [
        {
            hideInTable: true,
            hideInSearch: true,
            title: 'nid',
            dataIndex: 'uuid',
            width: 48,
            dataIndex: 'nid',
            render: (_, record) =>{
              //console.log(_, record);
              let node = record[1];
              return  <>{node.nid[0].value}</>;
            },
        },
        {
          hideInSearch: true,
            title: 'title',
            dataIndex: 'title',
            render: (_, record) =>{
              //console.log(_, record);
              let node = record[1];
              return  <>{node.title[0].value}</>;
            },
        },
        {
          hideInSearch: true,
            title: 'body',
            dataIndex: 'body',
            render: (_, record) =>{
              //console.log(_, record);
              let node = record[1];
              return  <>{node.body[0].value}</>;
            },
        },
        {
          hideInTable: true,
          hideInSearch: true,
            title: 'created',
            dataIndex: 'created',
            render: (_, record) =>{
              //console.log(_, record);
              let node = record[1];
              return  <>{moment(node.created[0].value).fromNow()}</>;
            },
        },
        {
          hideInSearch: true,
            title: 'changed',
            dataIndex: 'changed',
            render: (_, record) =>{
              //console.log(_, record);
              let node = record[1];
              return  <>{moment(node.changed[0].value).fromNow()}</>;
            },
            sorter: true
        },
        {
          hideInTable: true,
            title: 'field_closed',
            dataIndex: 'field_closed',
            render: (_, record) =>{
              //console.log(_, record);
              let node = record[1];
              return  <>{node.field_closed[0].value.toString()}</>;
            },
            sorter: false
        },
        {
            title: '',
            dataIndex: 'action',
            render: (_, record) =>{
              let node = record[1];
              return  <>
              <Space>
                <Badge count={node.comments.length}>
                  <Button type="primary" onClick={(e) => showModal(e, node)}>
                    Kommentarverlauf
                   </Button>
                 </Badge>
                 {node.field_closed[0].value !== true &&
                   <Button type="primary" onClick={(e) => onTicketClose(node.uuid[0].value)}>
                     Ticket schliesen
                   </Button>
                 }
              </Space>
              </>;
            },
        },
    ];

    return (
      <>



      <ProTable
        options={{
            setting: false,
            density: false
        }}
        columns={columns}
        actionRef={actionRef}
        cardBordered
        request={async (params = {}, sort, filter) => {

          console.log(params, sort, filter);

          params.field_sort = Object.keys(sort)[0];
          params.sort_direction = Object.values(sort)[0];
          params.action = 'listAction';

          let result = await getSupportTicket(params, {});

          console.log(result);

          return {
              data: Object.entries(result.data),
              // Please return true for success.
              // otherwise the table will stop parsing the data, even if there is data
              success: result.success,
              // not passed will use the length of the data, if it is paged you must pass
              total: result.total,
          };

        }}
        editable={{
              type: 'multiple',
        }}


        rowKey="uuid"

        search={false}



        form={{
              // 由于配置了 transform，提交的参与与定义的不同这里需要转化一下
              syncToUrl: (values, type) => {
                  if (type === 'get') {
                      return Object.assign(Object.assign({}, values), { created_at: [values.startTime, values.endTime] });
                  }
                  return values;
              },
        }}

        pagination={{
              pageSize: 5,
              onChange: (page) => console.log(page),
        }}

        dateFormatter="string"
        headerTitle="Tickets"

        toolBarRender={() => [
          <AddTicket actionRef={actionRef} />
        ]}

        />


        <Modal style={{ top: 10 }} footer={false} title="Verlauf" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          {supportTicket &&
            <CommentView supportTicket={supportTicket} key={supportTicket.changed[0].value} actionRef={actionRef}  />
          }
        </Modal>


      </>
    );
};
