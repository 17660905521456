// new file called DogPicture.jsx
import React, { useEffect, useState } from 'react';
import Helper from '@/helper/Helper';
import { loadTerms } from '@/services/ant-design-pro/api';
import { Tag, Space, Card } from 'antd';

const { CheckableTag } = Tag;
import { useModel, useIntl, history } from 'umi';
import ReactGA from "react-ga4";


const Result = (props) => {


  ReactGA.event({
    category: "cumcierge",
    action: "cumcierge_result",
    //label: "credits confirm", // optional
    //value: 99, // optional, must be a number
    //nonInteraction: true, // optional, true/false
    //transport: "xhr", // optional, beacon/xhr/image
  });
  


  const { escortListState, setEscortListState, defaultEscortListState } = useModel('escortList', (ret) => ({
    escortListState: ret.escortListState,
    setEscortListState: ret.setEscortListState,
    defaultEscortListState: ret.defaultEscortListState
  }));


  const { filterState, setFilterState, defaultFilter } = useModel('filter', (ret) => ({
    filterState: ret.filterState,
    setFilterState: ret.setFilterState,
    defaultFilter: ret.defaultFilter
  }));

  console.log(props);




  const handleClick = async () => {


    props.handleCancel();

    filterState.field_cumciere = new Date().toTimeString();

    setFilterState({
      ...filterState,
    })

    await Helper.waitTime(250);

    history.push('/');
  }

  const handleReset = async () => {

    setFilterState(defaultFilter)
    props.actions.handleIntro();
  }


  

  return (
    <div>


      <Space wrap>
      <Tag onClick={() => handleClick()} >
          Ergebnisse anzeigen
        </Tag>
        <Tag onClick={() => handleReset()} >
          Beginne von vorne
        </Tag>
      </Space>

    </div>
  );
};

export default Result;