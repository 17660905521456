// new file called DogPicture.jsx
import React, { useEffect, useState } from 'react';
import Helper from '@/helper/Helper';
import { loadTerms } from '@/services/ant-design-pro/api';
import { Tag, Space, Card } from 'antd';

const { CheckableTag } = Tag;
import { useModel, useIntl } from 'umi';
import ReactGA from "react-ga4";


const Region = (props) => {

  const intl = useIntl();


  ReactGA.event({
    category: "cumcierge",
    action: "cumcierge_region",
    //label: "credits confirm", // optional
    //value: 99, // optional, must be a number
    //nonInteraction: true, // optional, true/false
    //transport: "xhr", // optional, beacon/xhr/image
  });
  

  const { escortListState, setEscortListState, defaultEscortListState } = useModel('escortList', (ret) => ({
    escortListState: ret.escortListState,
    setEscortListState: ret.setEscortListState,
    defaultEscortListState: ret.defaultEscortListState
}));


const { filterState, setFilterState, defaultFilter } = useModel('filter', (ret) => ({
  filterState: ret.filterState,
  setFilterState: ret.setFilterState,
  defaultFilter: ret.defaultFilter
}));

  console.log(props);

  const [terms, setTerms] = useState(false);



  useEffect(async () => {
    if(!terms) {
      let result = await loadTerms({ vid: 'regions' }, {});
      setTerms(Helper.prepareTermSelectOptions(result.data));
    }
   
  });

  const handleClick = async (regionId) => {
    setTerms(false);


    
    filterState.field_regions.push(regionId);


    setFilterState({
        ...filterState,
    })

    await Helper.waitTime(250);

    props.actions.handleRubric();
  }

  return (
    <div>

      {terms &&
        <Space wrap>
          {terms.map((tag, i) => {
            //console.log(tag);
            return (
              <Tag
                key={tag.value}
                onClick={() => handleClick(tag.value)}
              >
                {tag.label}
              </Tag>
            )
          })}
        </Space>
      }
    </div>
  );
};

export default Region;