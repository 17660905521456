// in ActionProvider.jsx
import React from 'react';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {



  const handleHello = () => {
    const botMessage = createChatBotMessage('Hello. Nice to meet you.');

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };


  const handleRegion = () => {
    const botMessage = createChatBotMessage(
      "Hallo! Ich bin der virtuelle Assistent von escort.ch. Ich helfe dir, Escorts schnell zu finden. Bitte wähle zuerst deine Region: ",
      {
        widget: 'region',
      }
    );

    setState((prev) => ({
      ...prev,
      messages: [botMessage],
    }));
  };


  const handleRubric = () => {

    const botMessage = createChatBotMessage(
      "Vielen Dank! Bitte wähle jetzt deine Rubri.",
      {
        widget: 'rubric',
      }
    );

    setState((prev) => ({
      ...prev,
      messages: [botMessage],
    }));
  };


  const handleResult = () => {

    const botMessage = createChatBotMessage(
      "Perfekt, ich habe alles, was ich brauche, um dir zu helfen. Möchtest du jetzt die Ergebnisse sehen?",
      {
        widget: 'result',
      }
    );

    setState((prev) => ({
      ...prev,
      messages: [botMessage],
    }));
  };


  const handleIntro = () => {

    const botMessage = createChatBotMessage(
      "Hallo! Ich bin der virtuelle Assistent von escort.ch. Wie kann ich dir helfen",
      {
        widget: 'intro',
      }
    );

    setState((prev) => ({
      ...prev,
      messages: [botMessage],
    }));
  };


  // Put the handleHello function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
            handleRegion,
            handleRubric,
            handleResult,
            handleIntro
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;