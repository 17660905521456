import React, { useState, useEffect } from 'react';


import Chatbot from "react-chatbot-kit";
import 'react-chatbot-kit/build/main.css';

import config from './config.js';
import MessageParser from './components/MessageParser';
import ActionProvider from './components/ActionProvider';

import { Button, Modal } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllDTIcons from '@fortawesome/pro-duotone-svg-icons';
import * as AllLIcons from '@fortawesome/pro-light-svg-icons';

import { useModel, useIntl } from 'umi';


// You need to import the CSS only once
import './assets/css/index.css';




const Cumcierge = (props) => {

    const intl = useIntl();

    const { showCumcierge, setShowCumcierge } = useModel('cumcierge', (ret) => ({
        showCumcierge: ret.showCumcierge,
        setShowCumcierge: ret.setShowCumcierge,
    }));


    const showModal = () => {
        setShowCumcierge(true);
    };
    const handleOk = () => {
        setShowCumcierge(false);
    };
    const handleCancel = () => {
        setShowCumcierge(false);
    };

    return (
        <>






            <Button shape="circle" size={'large'} type="primary" className="btn-cumcierge" onClick={showModal}>
                <FontAwesomeIcon icon={AllLIcons.faRobot} />
            </Button>


            <Modal style={{ top: 10 }} footer={false} title="Virtueller Assistent" open={showCumcierge} onOk={handleOk} onCancel={handleCancel}>
                <Chatbot
                    handleCancel={handleCancel}
                    config={config}
                    messageParser={MessageParser}
                    actionProvider={ActionProvider}
                />
            </Modal>



        </>
    );
};

export default Cumcierge;
