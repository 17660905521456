import { AlipayOutlined, DingdingOutlined, TaobaoOutlined } from '@ant-design/icons';
import { List } from 'antd';
import React, { Fragment } from 'react';


import TableView from './components/TableView';

const SupportView = () => {


  return (
    <Fragment>

    <TableView />

    </Fragment>
  );
};

export default SupportView;
