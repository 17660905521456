import { useState } from 'react';

export default () => {
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  return { 
    autoRefresh, 
    setAutoRefresh,
    isOpen, 
    setIsOpen
   };
};
