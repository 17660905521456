import { forwardRef, useImperativeHandle } from 'react';

import { useModel } from 'umi';


const FilterContext = forwardRef((props, ref) => {

  const { filterState, setFilterState, defaultFilter } = useModel('filter', (ret) => ({
    filterState: ret.filterState,
    setFilterState: ret.setFilterState,
    defaultFilter: ret.defaultFilter,
  }));


  const { escortListState, setEscortListState } = useModel('escortList', (ret) => ({
    escortListState: ret.escortListState,
    setEscortListState: ret.setEscortListState,
  }));


  

  useImperativeHandle(ref, () => {
    return {
      setFilterState() {
        setFilterState(defaultFilter);
      },

      setEscortListState() {
        setEscortListState({
          ...escortListState,
          current: 1,
        })
      }
    };
  });

  return (
    <></>
  );
});


export default FilterContext;
