import { useState, useRef } from 'react';
import { ProFormCheckbox, ProFormText, QueryFilter, ProForm, ProFormRadio } from '@ant-design/pro-components';
import { loadTerms } from '@/services/ant-design-pro/api';
import Helper from '@/helper/Helper';
import { history, useModel, useIntl } from 'umi';
import { Button, Drawer, message, Divider, Collapse, Tag, Space, Badge } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllDTIcons from '@fortawesome/pro-duotone-svg-icons';
import Banner from '@/components/Banner';

import Geolocation from './components/Geolocation';

import ReactGA from "react-ga4";
import { useMediaQuery } from 'react-responsive'

import './assets/css/index.css';




const Filter = (props) => {

  const intl = useIntl();

  const isMobile = useMediaQuery({ query: '(max-width: 767.98px)' })


  const { Panel } = Collapse;

  const { CheckableTag } = Tag;

  const tagsData = [
    'field_expired',
    'field_verified',
    'field_secret_gallery',
    //'field_hot',
    'field_video',
    'field_stories',
    'field_trans',
    'field_female'
  ];


  const tagsTitle = [
    intl.formatMessage({ id: 'home.filter.tag.field_expired', defaultMessage: 'Bald wieder da' }),
    intl.formatMessage({ id: 'home.filter.tag.field_verified', defaultMessage: 'Verifiziert' }),
    intl.formatMessage({ id: 'home.filter.tag.field_secret_gallery', defaultMessage: 'Secret Gallery' }),
    //intl.formatMessage({ id: 'home.filter.tag.field_hot', defaultMessage: 'HOT' }),
    intl.formatMessage({ id: 'home.filter.tag.field_video', defaultMessage: 'Video' }),
    intl.formatMessage({ id: 'home.filter.tag.field_stories', defaultMessage: 'Story' }),
    intl.formatMessage({ id: 'home.filter.tag.field_trans', defaultMessage: 'Trans' }),
    intl.formatMessage({ id: 'home.filter.tag.field_female', defaultMessage: 'Frau' }),
  ];


  const tagIcons = [
    <FontAwesomeIcon icon={AllDTIcons.faClock} />,
    <FontAwesomeIcon icon={AllDTIcons.faBadgeCheck} />,
    <FontAwesomeIcon icon={AllDTIcons.faLockKeyhole} />,
    //<FontAwesomeIcon icon={AllDTIcons.faFire} />,
    <FontAwesomeIcon icon={AllDTIcons.faVideo} />,
    <FontAwesomeIcon icon={AllDTIcons.faRectangleHistory} />,
    <FontAwesomeIcon icon={AllDTIcons.faTransgender} />,
    <FontAwesomeIcon icon={AllDTIcons.faVenus} />
  ];


  const { escortListState, setEscortListState, defaultEscortListState } = useModel('escortList', (ret) => ({
    escortListState: ret.escortListState,
    setEscortListState: ret.setEscortListState,
    defaultEscortListState: ret.defaultEscortListState
  }));

  const { filterState, setFilterState, defaultFilter } = useModel('filter', (ret) => ({
    filterState: ret.filterState,
    setFilterState: ret.setFilterState,
    defaultFilter: ret.defaultFilter
  }));


  const queryFormRef = useRef();

  const [formLoading, setFormLoading] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [lat, setLat] = useState(false);
  const [lng, setLng] = useState(false);
  const [loadingRegion, setLoadingRegion] = useState(false);
  const [regionType, setRegionType] = useState('region');
  const [open, setOpen] = useState(false);


  const showDrawer = async () => {
    setOpen(true);

    await Helper.waitTime(100);

    if (queryFormRef.current) {
      queryFormRef.current.setFieldsValue(filterState);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const onFinish = async (values) => {

    setFormLoading(true);

    //console.log(values);
    //console.log(filterState);

    tagsData.forEach((item, i) => {
      filterState[item] = false;
    });

    filterState.field_popular_tags.forEach((item, i) => {
      filterState[item] = true;
    });

    delete values["field_popular_tags"];


    if (regionType === 'near') {
      filterState.field_regions = [];
    }

    if (regionType === 'region') {
      delete filterState.field_lat;
      delete filterState.field_lng;
      delete filterState.field_radius;
    }

    
    setEscortListState(defaultEscortListState)
    await Helper.waitTime(200);


    setFilterState({
      ...filterState,
      ...values,
      time: new Date().getTime()
    })


    ReactGA.event({
      category: "filter",
      action: "filter_submit",
      //label: "credits confirm", // optional
      //value: 99, // optional, must be a number
      //nonInteraction: true, // optional, true/false
      //transport: "xhr", // optional, beacon/xhr/image
    });


    Helper.scrollToTop();

    /*
    window.scrollTo({
      top: -10000,
    })*/

    setFormLoading(false);
    onClose();

    if (props.redirect) {
      history.push('/');
    }
  }

  const onReset = async (values) => {

    setFormLoading(true);

    setEscortListState(defaultEscortListState);
    setRegionType('region');
    setLng(false);
    setLat(false);
    setFormLoading(false);

    await Helper.waitTime(200);


    setFilterState({
      ...defaultFilter,
      time: new Date().getTime()
    });


   
    onClose();

    Helper.scrollToTop();

    if (props.redirect) {
      history.push('/');
    }
  }

  const getCurrentLocation = (regionType) => {

    if (regionType === 'near') {
      setLoadingRegion(true);
      setFormDisabled(true);


      navigator.geolocation.getCurrentPosition(function (position) {
        //console.log("Latitude is :", position.coords.latitude);
        //console.log("Longitude is :", position.coords.longitude);



        setFilterState({
          ...filterState,
          field_region_type: regionType,
          field_radius: 10,
          field_lat: position.coords.latitude,
          field_lng: position.coords.longitude,
          field_regions: [],
          time: new Date().getTime()
        })

        setLng(position.coords.longitude);
        setLat(position.coords.latitude);

        queryFormRef.current.setFieldValue('field_lat', position.coords.latitude);
        queryFormRef.current.setFieldValue('field_lng', position.coords.longitude);
        queryFormRef.current.setFieldValue('field_regions', false);

        setLoadingRegion(false);
        setFormDisabled(false);


      },
        function (error) {
          if (error.code == error.PERMISSION_DENIED) {
            setLng(false);
            setLat(false);
            queryFormRef.current.setFieldValue('region', 'region');
            setRegionType('region');
            message.error(intl.formatMessage({ id: 'home.filter.location.error', defaultMessage: 'Du musst deinem Browser den Standort-Zugriff erlauben um diese Funktion nutzen zu können' }));
            setLoadingRegion(false);
            setFormDisabled(false);
          }
        });

    } else {
      setLoadingRegion(false);
      setFilterState({
        ...filterState,
        field_region_type: regionType,
        field_radius: 10,
        field_lat: false,
        field_lng: false,
        field_regions: [],
        time: new Date().getTime()
      })
    }


  }

  const handleChange = async (tag, checked) => {
    const nextSelectedTags = checked
      ? [...filterState.field_popular_tags, tag]
      : filterState.field_popular_tags.filter((t) => t !== tag);
    //console.log('You are interested in: ', nextSelectedTags);


    tagsData.forEach((item, i) => {
      filterState[item] = false;
    });

    nextSelectedTags.forEach((item, i) => {
      filterState[item] = true;
    });

    setEscortListState(defaultEscortListState)
    await Helper.waitTime(200);



    setFilterState({
      ...filterState,
      field_popular_tags: [...nextSelectedTags],
      time: new Date().getTime()
    })

    onClose();

    if (props.redirect) {
      history.push('/');
    }
  };


  return (
    <>

      <div className="filter-bar">


        {props.iconOnly &&
         <span onClick={showDrawer} ><FontAwesomeIcon className="mr-3" icon={AllDTIcons.faSliders} /></span> 
        }

        {!props.iconOnly &&
          <Button title="Filter" className={'btn-purple'} type={'primary'} onClick={showDrawer} icon={<FontAwesomeIcon className="mr-3" icon={AllDTIcons.faSliders} />}>
            <span>
              {intl.formatMessage({ id: 'home.filter.btn.show', defaultMessage: 'Filter' })}
            </span>
          </Button>
        }


        <Drawer className="filter-drawer" destroyOnClose title={intl.formatMessage({ id: 'home.filter.drawer.title', defaultMessage: 'Filter' })} placement="right" onClose={onClose} open={open} width={'700px'} >
          <div className="profile-filter-bar">

            <QueryFilter
              loading={formLoading}
              disabled={formDisabled}
              formRef={queryFormRef}
              className="filter-form"
              collapsed={false}
              defaultCollapsed={false}
              onReset={(values) => {
                onReset(values);
              }}
              onFinish={(values) => {
                onFinish(values);
              }}
            >

              <div className="keyword-bar">

                <Divider orientation="left"><FontAwesomeIcon className="mr-3" icon={AllDTIcons.faMagnifyingGlass} />{intl.formatMessage({ id: 'home.filter.keyword.divider', defaultMessage: 'Suche' })}</Divider>

                <ProFormText
                  name="field_keywords"
                  label=""
                  size={'large'}
                  placeholder={intl.formatMessage({ id: 'home.filter.keyword.placeholder', defaultMessage: 'Name oder anderen Suchbegriff...' })}
                />
              </div>

              <Divider orientation="left"><FontAwesomeIcon className="mr-3" icon={AllDTIcons.faTags} />{intl.formatMessage({ id: 'home.filter.popular_tags.divider', defaultMessage: 'Popular Tags' })}</Divider>

              <Space className="popular-tags" wrap>
                <ProForm.Item name={'field_popular_tags'} label={''} valuePropName="checked">
                  {tagsData.map((tag, index) => (
                    <CheckableTag
                      key={tag}
                      checked={(filterState.field_popular_tags.includes(tag)) ? true : false}
                      onChange={(checked) => handleChange(tag, checked)}
                    >
                      {tagIcons[index]} {tagsTitle[index]}
                    </CheckableTag>
                  ))}
                </ProForm.Item>
              </Space>

              <Divider orientation="left"><FontAwesomeIcon className="mr-3" icon={AllDTIcons.faList} />{intl.formatMessage({ id: 'home.filter.more.divider', defaultMessage: 'Weitere Filter' })}</Divider>

              <Collapse accordion defaultActiveKey={['field_regions']}>

                <Panel
                  forceRenderDisabled
                  header={
                    <span>
                      {intl.formatMessage({ id: 'home.filter.field_regions.header', defaultMessage: 'Regionen' })}
                      {filterState.field_regions.length > 0 &&
                        <Badge className="ml-3" count={filterState.field_regions.length} />
                      }
                    </span>
                  }
                  key={'field_regions'}>
                  <>
                    <ProFormRadio.Group
                      value={'region'}
                      name="region"
                      loading={loadingRegion}
                      onChange={(value) => {



                        //setRegionType(value.target.value);
                        getCurrentLocation(value.target.value);
                      }}
                      label=""
                      options={[

                        {
                          label: intl.formatMessage({ id: 'home.filter.field_regions.option.region', defaultMessage: 'Region / Kanton' }),
                          value: 'region',
                        },
                        {
                          label: intl.formatMessage({ id: 'home.filter.field_regions.option.near', defaultMessage: 'Umkreis' }),
                          value: 'near',
                        }
                      ]}
                    /><br />

                    {filterState.field_region_type === 'near' && lat && lng && !loadingRegion &&
                      <Geolocation queryFormRef={queryFormRef} />
                    }

                    {filterState.field_region_type === 'region' &&
                      <ProFormCheckbox.Group

                        label=""
                        name="field_regions"

                        onChange={(value) => {
                          queryFormRef.current.setFieldValue('field_radius', 10);
                        }}

                        request={async (values) => {
                          let result = await loadTerms({ vid: 'regions' }, {});
                          return Helper.prepareTermSelectOptions(result.data);
                        }}
                      />
                    }
                  </>
                </Panel>
                <Panel
                  forceRenderDisabled
                  header={
                    <span>
                      {intl.formatMessage({ id: 'home.filter.field_rubrics.header', defaultMessage: 'Rubriken' })}
                      {filterState.field_rubrics.length > 0 &&
                        <Badge className="ml-3" count={filterState.field_rubrics.length} />
                      }
                    </span>
                  }
                  key={'field_rubrics'}>
                  <>
                    <ProFormCheckbox.Group
                      label=""
                      name="field_rubrics"

                      request={async (values) => {
                        let result = await loadTerms({ vid: 'rubrics' }, {});
                        return Helper.prepareTermSelectOptions(result.data);
                      }}
                    />
                  </>
                </Panel>
                <Panel
                  forceRenderDisabled
                  header={
                    <span>
                      {intl.formatMessage({ id: 'home.filter.field_offers.header', defaultMessage: 'Services' })}
                      {filterState.field_offers.length > 0 &&
                        <Badge className="ml-3" count={filterState.field_offers.length} />
                      }
                    </span>
                  }
                  key={'field_offers'}>
                  <>
                    <ProFormCheckbox.Group
                      label=""
                      name="field_offers"

                      request={async (values) => {
                        let result = await loadTerms({ vid: 'offer' }, {});
                        return Helper.prepareTermSelectOptions(result.data);
                      }}
                    />
                  </>
                </Panel>
                <Panel
                  forceRenderDisabled
                  header={
                    <span>
                      {intl.formatMessage({ id: 'home.filter.field_age.header', defaultMessage: 'Alter' })}
                      {filterState.field_age.length > 0 &&
                        <Badge className="ml-3" count={filterState.field_age.length} />
                      }
                    </span>
                  }
                  key={'field_age'}>
                  <>
                    <ProFormCheckbox.Group
                      label=""
                      name="field_age"

                      request={async (values) => {
                        let result = await loadTerms({ vid: 'age' }, {});
                        return Helper.prepareTermSelectOptions(result.data);
                      }}
                    />
                  </>
                </Panel>
                <Panel
                  forceRenderDisabled
                  header={
                    <span>
                      {intl.formatMessage({ id: 'home.filter.field_height.header', defaultMessage: 'Grösse' })}
                      {filterState.field_height.length > 0 &&
                        <Badge className="ml-3" count={filterState.field_height.length} />
                      }
                    </span>
                  }
                  key={'field_height'}>
                  <>
                    <ProFormCheckbox.Group
                      label=""
                      name="field_height"

                      request={async (values) => {
                        let result = await loadTerms({ vid: 'height' }, {});
                        return Helper.prepareTermSelectOptions(result.data);
                      }}
                    />
                  </>
                </Panel>
                {/** 
                  <Panel
                    forceRenderDisabled
                    header={
                      <span>
                        {intl.formatMessage({ id: 'home.filter.field_weight.header', defaultMessage: 'Gewicht' })}
                        {filterState.field_weight.length > 0 &&
                          <Badge className="ml-3" count={filterState.field_weight.length} />
                        }
                      </span>
                    }
                    key={'field_weight'}>
                    <>
                      <ProFormCheckbox.Group
                        label=""
                        name="field_weight"

                        request={async (values) => {
                          let result = await loadTerms({vid: 'weight'}, {});
                          return Helper.prepareTermSelectOptions(result.data);
                        }}
                      />
                    </>
                  </Panel>*/}

                {/** 
                  <Panel
                    forceRenderDisabled
                    header={
                      <span>
                        {intl.formatMessage({ id: 'home.filter.field_eye_color.header', defaultMessage: 'Augenfarbe' })}
                        {filterState.field_eye_color.length > 0 &&
                          <Badge className="ml-3" count={filterState.field_eye_color.length} />
                        }
                      </span>
                    }
                    key={'field_eye_color'}>
                    <>
                      <ProFormCheckbox.Group
                        label=""
                        name="field_eye_color"

                        request={async (values) => {
                          let result = await loadTerms({vid: 'eye_color'}, {});
                          return Helper.prepareTermSelectOptions(result.data);
                        }}
                      />
                    </>
                  </Panel>*/}

                <Panel
                  forceRenderDisabled
                  header={
                    <span>
                      {intl.formatMessage({ id: 'home.filter.field_hair_color.header', defaultMessage: 'Haarfarbe' })}
                      {filterState.field_hair_color.length > 0 &&
                        <Badge className="ml-3" count={filterState.field_hair_color.length} />
                      }
                    </span>
                  }
                  key={'field_hair_color'}>
                  <>
                    <ProFormCheckbox.Group
                      label=""
                      name="field_hair_color"

                      request={async (values) => {
                        let result = await loadTerms({ vid: 'hair_color' }, {});
                        return Helper.prepareTermSelectOptions(result.data);
                      }}
                    />
                  </>
                </Panel>
                {/** 
                  <Panel
                    forceRenderDisabled
                    header={
                      <span>
                        {intl.formatMessage({ id: 'home.filter.field_hair_length.header', defaultMessage: 'Haarlänge' })}
                        {filterState.field_hair_length.length > 0 &&
                          <Badge className="ml-3" count={filterState.field_hair_length.length} />
                        }
                      </span>
                    }
                    key={'field_hair_length'}>
                    <>
                      <ProFormCheckbox.Group
                        label=""
                        name="field_hair_length"

                        request={async (values) => {
                          let result = await loadTerms({vid: 'hair_length'}, {});
                          return Helper.prepareTermSelectOptions(result.data);
                        }}
                      />
                    </>
                  </Panel>*/}
                <Panel
                  forceRenderDisabled
                  header={
                    <span>
                      {intl.formatMessage({ id: 'home.filter.field_language.header', defaultMessage: 'Sprachen' })}
                      {filterState.field_language.length > 0 &&
                        <Badge className="ml-3" count={filterState.field_language.length} />
                      }
                    </span>
                  }
                  key={'field_language'}>
                  <>
                    <ProFormCheckbox.Group
                      label=""
                      name="field_language"

                      request={async (values) => {
                        let result = await loadTerms({ vid: 'language' }, {});
                        return Helper.prepareTermSelectOptions(result.data);
                      }}
                    />
                  </>
                </Panel>
                <Panel
                  forceRenderDisabled
                  header={
                    <span>
                      {intl.formatMessage({ id: 'home.filter.field_person_types.header', defaultMessage: 'Typ' })}
                      {filterState.field_person_types.length > 0 &&
                        <Badge className="ml-3" count={filterState.field_person_types.length} />
                      }
                    </span>
                  }
                  key={'field_person_types'}>
                  <>
                    <ProFormCheckbox.Group
                      label=""
                      name="field_person_types"

                      request={async (values) => {
                        let result = await loadTerms({ vid: 'person_types' }, {});
                        return Helper.prepareTermSelectOptions(result.data);
                      }}
                    />
                  </>
                </Panel>
                {/** 
                  <Panel
                    forceRenderDisabled
                    header={
                      <span>
                        {intl.formatMessage({ id: 'home.filter.field_cup_size.header', defaultMessage: 'Körbchengrösse' })}
                        {filterState.field_cup_size.length > 0 &&
                          <Badge className="ml-3" count={filterState.field_cup_size.length} />
                        }
                      </span>
                    }
                    key={'field_cup_size'}>
                    <>
                      <ProFormCheckbox.Group
                        label=""
                        name="field_cup_size"

                        request={async (values) => {
                          let result = await loadTerms({vid: 'cup_size'}, {});
                          return Helper.prepareTermSelectOptions(result.data);
                        }}
                      />
                    </>
                  </Panel>*/}
                <Panel
                  forceRenderDisabled
                  header={
                    <span>
                      {intl.formatMessage({ id: 'home.filter.field_physique.header', defaultMessage: 'Figur' })}
                      {filterState.field_physique.length > 0 &&
                        <Badge className="ml-3" count={filterState.field_physique.length} />
                      }
                    </span>
                  }
                  key={'field_physique'}>
                  <>
                    <ProFormCheckbox.Group
                      label=""
                      name="field_physique"

                      request={async (values) => {
                        let result = await loadTerms({ vid: 'physique' }, {});
                        return Helper.prepareTermSelectOptions(result.data);
                      }}
                    />
                  </>
                </Panel>
                {/** 
                  <Panel
                    forceRenderDisabled
                    header={
                      <span>
                        {intl.formatMessage({ id: 'home.filter.field_smoking.header', defaultMessage: 'Rauchen' })}
                        {filterState.field_smoking.length > 0 &&
                          <Badge className="ml-3" count={filterState.field_smoking.length} />
                        }
                      </span>
                    }
                    key={'field_smoking'}>
                    <>
                      <ProFormCheckbox.Group
                        label=""
                        name="field_smoking"

                        request={async (values) => {
                          let result = await loadTerms({vid: 'smoking'}, {});
                          return Helper.prepareTermSelectOptions(result.data);
                        }}
                      />
                    </>
                  </Panel>*/}
                {/** 
                  <Panel
                    forceRenderDisabled
                    header={
                      <span>
                        {intl.formatMessage({ id: 'home.filter.field_shoe_size.header', defaultMessage: 'Schuhgrösse' })}
                        {filterState.field_shoe_size.length > 0 &&
                          <Badge className="ml-3" count={filterState.field_shoe_size.length} />
                        }
                      </span>
                    }
                    key={'field_shoe_size'}>
                    <>
                      <ProFormCheckbox.Group
                        label=""
                        name="field_shoe_size"

                        request={async (values) => {
                          let result = await loadTerms({vid: 'shoe_size'}, {});
                          return Helper.prepareTermSelectOptions(result.data);
                        }}
                      />
                    </>
                  </Panel>*/}
                {/** 
                  <Panel
                    forceRenderDisabled
                    header={
                      <span>
                        {intl.formatMessage({ id: 'home.filter.field_intimate_area.header', defaultMessage: 'Intimbereich' })}
                        {filterState.field_intimate_area.length > 0 &&
                          <Badge className="ml-3" count={filterState.field_intimate_area.length} />
                        }
                      </span>
                    }
                    key={'field_intimate_area'}>
                    <>
                      <ProFormCheckbox.Group
                        label=""
                        name="field_intimate_area"

                        request={async (values) => {
                          let result = await loadTerms({vid: 'intimate_area'}, {});
                          return Helper.prepareTermSelectOptions(result.data);
                        }}
                      />
                    </>
                  </Panel>*/}

                <Panel
                  forceRenderDisabled
                  header={
                    <span>
                      {intl.formatMessage({ id: 'home.filter.field_body_features.header', defaultMessage: 'Körpermerkmale' })}
                      {filterState.field_body_features.length > 0 &&
                        <Badge className="ml-3" count={filterState.field_body_features.length} />
                      }
                    </span>
                  }
                  key={'field_body_features'}>
                  <>
                    <ProFormCheckbox.Group
                      label=""
                      name="field_body_features"

                      request={async (values) => {
                        let result = await loadTerms({ vid: 'body_features' }, {});
                        return Helper.prepareTermSelectOptions(result.data);
                      }}
                    />
                  </>
                </Panel>

              </Collapse>

              <Divider orientation="left">{intl.formatMessage({ id: 'home.filter.banner.divider', defaultMessage: 'Filter werden präsentiert von' })}</Divider>
              <Banner size={'skyscraper'} slidesPerView={2} />

            </QueryFilter>
          </div>

        </Drawer>
      </div>

    </>
  );
};

export default Filter;
