import { Button, Tooltip } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons';
import * as AllSolidIcons from '@fortawesome/free-solid-svg-icons';

import { useModel, useIntl } from 'umi';

import Helper from '@/helper/Helper';

// You need to import the CSS only once
import './assets/css/index.css';

const Add = (props) => {

  const intl = useIntl();


  const { wishlistItems, setWishlistItems, setShowWishlist, setWishlistItemsStorage } = useModel('wishlist', (ret) => ({
    wishlistItems: ret.wishlistItems,
    setWishlistItems: ret.setWishlistItems,
    setShowWishlist: ret.setShowWishlist,
    setWishlistItemsStorage: ret.setWishlistItemsStorage
  }));


  const { initialState } = useModel('@@initialState');

  const onAdd = async (profile) => {

    let newState = [
      profile,
      ...wishlistItems,
    ]

    setWishlistItems(newState)

    setWishlistItemsStorage(newState);

    await Helper.waitTime(100);
    setShowWishlist(true);
  }

  const onRemove = (uuid) => {
    let items = wishlistItems;
    let newItems = items.filter(function (v) { return v.uuid[0].value !== uuid });


    setWishlistItems(newItems)
    setWishlistItemsStorage(newItems);
  }

  const checkItemExists = (uuid) => {
    //console.log(wishlistItems);
    let uuids = wishlistItems.map((item, i) => {
      return item.uuid[0].value;
    });

    return uuids.includes(uuid);
  }


  return (


    <span className="wishlist-button">
      {!initialState.currentUser && checkItemExists(props.profile.uuid[0].value) &&
          <Button title="Add" type="text" onClick={() => onRemove(props.profile.uuid[0].value)} icon={<FontAwesomeIcon icon={AllSolidIcons.faHeart} />} shape={'cicle'} />
      }

      {!initialState.currentUser && !checkItemExists(props.profile.uuid[0].value) &&
          <Button title="Remove" type="text" onClick={() => onAdd(props.profile)} icon={<FontAwesomeIcon icon={AllLightIcons.faHeart} />} shape={'cicle'} />
      }
    </span>
  );
};

export default Add;
