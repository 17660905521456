import { useState } from 'react';

export default () => {

  const defaultFilter = {
    field_popular_tags: [],
    field_offers: false,
    field_regions: [],
    field_rubrics: [],
    field_age: false,
    field_cup_size: false,
    field_eye_color: false,
    field_hair_color: false,
    field_hair_length: false,
    field_height: false,
    field_language: false,
    field_person_types: false,
    field_smoking: false,
    field_weight: false,
    field_verified: false,
    field_vip: false,
    field_video: false,
    field_body_features: false,
    field_intimate_area: false,
    field_physique: false,
    field_shoe_size: false,
    field_hot: false,
    title: false,
    field_keywords: '',
    uid: false,
    field_status: false,
    field_region_type: 'region',
    field_radius: 10,
    field_lng: false,
    field_lat: false,
  }

  const [filterState, setFilterState] = useState(defaultFilter);
  

  return { filterState, setFilterState, defaultFilter };
};
