import { useState } from 'react';
import { Drawer, Button, Badge } from 'antd';
import { useModel, useIntl } from 'umi';

import NoticeList from './NoticeList';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllDTIcons from '@fortawesome/pro-duotone-svg-icons';

const NoticeIconView = () => {

  const intl = useIntl();

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };



  const { notifications } = useModel('notifications', (ret) => ({
    notifications: ret.notifications,
  }));


  return (
    <>
      <Badge
        count={notifications}
        style={{
          boxShadow: 'none',
        }}
        className="notice-badge"
      >
        <Button type="text" onClick={showDrawer} icon={<FontAwesomeIcon icon={AllDTIcons.faBell} />}></Button>
      </Badge>


      <Drawer
        destroyOnClose
        height={600}
        title={intl.formatMessage({ id: 'noticeicon.title', defaultMessage: 'Nachrichten' })}
        placement={'bottom'}
        closable={true}
        onClose={onClose}
        open={open}
      >
        <NoticeList key={'notice-list-mobile'} />
      </Drawer>
    </>
  );
};

export default NoticeIconView;
