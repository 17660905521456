import { useState, useEffect, useRef } from 'react';
import { Image, Modal, Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

import { getBanner, postClick } from '@/services/ant-design-pro/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllDTIcons from '@fortawesome/pro-duotone-svg-icons';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import Helper from '@/helper/Helper';


import ReactGA from "react-ga4";
import Block from '@/components/Block';
import { useIntl } from 'umi';
import moment from 'dayjs';


import './assets/css/index.css';


const Banner = (props) => {

  let bannerImageSize = {
    'large': {
      width: '500',
      height: '500'
    },
    'menu': {
      width: '500',
      height: '500'
    },
    'story': {
      width: '410',
      height: '730'
    },
    'grid': {
      width: '400',
      height: '500'
    },
    'skyscraper': {
      width: '300',
      height: '600'
    },
  }


  const intl = useIntl();

  const [dataSource, setDataSource] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const swiperRef = useRef(null);

  const getBannerRequest = async () => {

    let bannserSizes = {
      'large': 191,
      'menu': 194,
      'story': 193,
      'grid': 192,
      'skyscraper': 335,
    }

    
    let params = {
      use_cache: true,
      pageSize: 5,
      current: 0,
      action: 'listAction',
      field_banner_size: bannserSizes[props.size]
    };

    let result = await getBanner(params, {});

    if (result.success) {
      setDataSource(result.data);

      if (swiperRef.current) {
        Object.assign(swiperRef.current, {
          slidesPerView: props.slidesPerView,
          breakpoints: {
            0: {
              slidesPerView: (props.slidesPerView > 1) ? 2 : 1,
            },
            768: {
              slidesPerView: props.slidesPerView,
            },
          },
        });
        swiperRef.current.initialize();
      }
    }

  }
  useEffect(() => {
    if (!dataSource) {
      getBannerRequest();
    }
  }, []);

  const countClick = async (nid) => {

    ReactGA.event({
      category: "banner",
      action: "banner_click_" + nid,
      //label: "credits error", // optional
      //value: creditCosts, // optional, must be a number
      //nonInteraction: true, // optional, true/false
      //transport: "xhr", // optional, beacon/xhr/image
    });

    await postClick({ nid: nid }, {});
  }
  
  

  return (
    <>
      {dataSource.length > 0 && props.type === 'image' &&
        <a rel="noreferrer" className="single-image-banner" onClick={() => countClick(Object.entries(dataSource)[0][1].nid[0].value)} href={'http://' + Object.entries(dataSource)[0][1].field_url[0].value} target={'_blank'}>
          <Image
            preview={false}
            width={'100%'}
            src={Object.entries(dataSource)[0][1].field_image[0].url}
          />
        </a>
      }

      {dataSource.length > 0 && props.type !== 'image' &&
        <div className="banner-swiper">

          <div className="info-bar">
            <Button type="primary" onClick={toggleModal} icon={<QuestionCircleOutlined />}>
            </Button>
          </div>


          <swiper-container
            effect={(props.slidesPerView === 1) ? 'fade' : 'slide'}
            grab-cursor="true"
            cube-effect-shadow="true"
            cube-effect-slide-shadows="true"
            cube-effect-shadow-offset="0"
            cube-effect-shadow-scale="0"
            ref={swiperRef}
            navigation="false"
            slides-per-view={props.slidesPerView}
            space-between={24}
            loop={'true'}
            autoplay-delay="5000"
            init="false"
          >
            {Object.entries(dataSource).map((banner, index) => (
              <swiper-slide key={'banner-swiper-'+index}>
                <a title={banner[1].field_url[0].value} onClick={() => countClick(banner[1].nid[0].value)} href={'http://' + banner[1].field_url[0].value + ''} target={'_blank'}>

                <LazyLoadImage
                alt={banner[1].field_url[0].value}
                 threshold={2000}
                 useIntersectionObserver={false}
                 visibleByDefault={false}
                      width={'100%'}
                      effect="blur"
                      placeholderSrc={banner[1].field_image[0].placeholder}
                      src={banner[1].field_image[0].url}
                    />

                </a>

                {props.size === 'grid' &&
                   <div className="bottom-bar">
                   <div className="name">
                     Sponsored
                   </div>
                   <div className="location">Ad</div>
                   <div className="time">
                   <a onClick={() => countClick(banner[1].nid[0].value)} href={'http://' + banner[1].field_url[0].value + ''} target={'_blank'}>
                     {intl.formatMessage({ id: 'banner.visit.website', defaultMessage: 'Website besuchen' })}
                    </a>
                   </div>
                 </div>
                }

              </swiper-slide >
            ))}
          </swiper-container>


          <Modal style={{ top: '10px' }} title={intl.formatMessage({ id: 'footer.link.banner', defaultMessage: 'Banner Preise' })} width={350} footer={false} open={isModalOpen} onCancel={toggleModal}>
            <Block blockContent={14} noPadding />
            <Link to={'/prices/banner'}>
              <Button type="primary" className="btn-purple"> <FontAwesomeIcon className="mr-2" icon={AllDTIcons.faArrowRight} /> 
                {intl.formatMessage({ id: 'footer.link.banner', defaultMessage: 'Banner Preise' })}
              </Button>
            </Link>
          </Modal>

        </div>
      }
    </>
  );
};

export default Banner;
