// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/Loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-layout__Layout' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/.umi/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/home",
        "name": "Home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Home'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/user/login",
        "name": "Login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Login' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Login'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/user/logout",
        "name": "Logout",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Logout' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Logout'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/profile",
        "name": "Profile",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Profile' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Profile'), loading: LoadingComponent}),
        "hideInMenu": true,
        "routes": [
          {
            "path": "/profile/:page",
            "name": "Profile",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Profile' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Profile'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/location",
        "name": "Kanton",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Location' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Location'), loading: LoadingComponent}),
        "hideInMenu": true,
        "routes": [
          {
            "path": "/location/:page",
            "name": "Location",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Location' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Location'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/blog",
        "name": "Blog",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Blog' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Blog'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/blog/post/:page",
        "name": "Blog Post",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Blog__pages__Post' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Blog/pages/Post'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/filter/:page",
        "name": "Filter",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Filter' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Filter'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/faq",
        "name": "FAQ",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Faq' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Faq'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/login-as-creator",
        "name": "LoginAsCreator",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LoginAsCreator' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/LoginAsCreator'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/prices/banner",
        "name": "Banner Preise",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Prices__Banner' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Prices/Banner'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/prices/profile",
        "name": "Profil Preise",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Prices__Profile' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Prices/Profile'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/support",
        "name": "Support",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Support' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Support'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/user",
        "name": "Benutzermenü",
        "hideInMenu": true,
        "access": "isUser",
        "routes": [
          {
            "path": "/user/settings",
            "name": "Kontoeinstellungen",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Settings' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/User/Settings'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/user/profile",
            "name": "Profil Manager",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Profile' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/User/Profile'), loading: LoadingComponent}),
            "access": "isCreator",
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/user/banner",
            "name": "Banner Manager",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Banner' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/User/Banner'), loading: LoadingComponent}),
            "access": "isCreator",
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/user/credits",
            "name": "Credits",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Credits' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/User/Credits'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/payment",
        "name": "payment",
        "access": "isUser",
        "hideInMenu": true,
        "routes": [
          {
            "path": "/payment/confirm",
            "name": "Zahlung erfolgreich",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Payment__Confirm' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Payment/Confirm'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/payment/cancel",
            "name": "Zahlung abgebrochen",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Payment__Cancel' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Payment/Cancel'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/payment/error",
            "name": "Fehler während des Zahlungsvorgangs",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Payment__Error' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Payment/Error'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/legal",
        "name": "legal",
        "hideInMenu": true,
        "routes": [
          {
            "path": "/legal/compliance",
            "name": "Hinweis zur Einhaltung",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Legal__Compliance' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Legal/Compliance'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/legal/imprint",
            "name": "Impressum",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Legal__Imprint' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Legal/Imprint'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/legal/gtc",
            "name": "AGB",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Legal__GTC' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Legal/GTC'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/legal/privacy",
            "name": "Datenschutz",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Legal__Privacy' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Legal/Privacy'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/legal/refund",
            "name": "Rückgaberecht ",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Legal__Refund' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Legal/Refund'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/index.html",
        "name": "Home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Home'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/",
        "name": "Home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Home'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/partner",
        "name": "Partner",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Partner' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/Partner'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/503",
        "name": "Wartung",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__503' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/503'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__403' */'/var/www/vhosts/dev.escort.ch/page.dev.escort.ch/application/www/src/pages/403'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
