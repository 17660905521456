import type { SpinProps } from 'antd';
import { Spin } from 'antd';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';


const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

export default () => {
  return (

    <div id="root-loader">

      <div
        style={
          {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            minHeight: '420px',
          }}
      >
        <img src="/images/mailLogo.png" alt="logo" width="256" />

        <div className="page-loading-warp">

          <div className="ant-spin ant-spin-lg ant-spin-spinning">
            <span className="ant-spin-dot ant-spin-dot-spin">
              <i className="ant-spin-dot-item"></i>
              <i className="ant-spin-dot-item"></i>
              <i className="ant-spin-dot-item"></i>
              <i className="ant-spin-dot-item"></i>
            </span>
          </div>
        </div>

      </div>
    </div>


  );
};
