import { Space, Button, Tooltip, Dropdown, Menu } from 'antd';
import { useEffect } from 'react';
import { useModel, SelectLang, history, useIntl } from 'umi';
import styles from './index.less';
import NoticeIcon from '../NoticeIcon';
import BalanceIcon from '../BalanceIcon';
import { Link } from "react-router-dom";

import Banner from '@/components/Banner';


import Helper from '@/helper/Helper';

import WishlistList from '@/components/Wishlist/components/List'
import StatusMessengerButton from '@/components/StatusMessenger/components/Button'
import MobileSettings from '@/pages/User/Settings/Mobile';


import { useMediaQuery } from 'react-responsive'



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllDTIcons from '@fortawesome/pro-duotone-svg-icons';
import * as AllLIcons from '@fortawesome/pro-light-svg-icons';

import './assets/css/index.css';


const GlobalHeaderRight = () => {


  const isMobile = useMediaQuery({ query: '(max-width: 767.98px)' })

  const intl = useIntl();

  const { initialState } = useModel('@@initialState');

  //console.log(initialState);

  const { navTheme, layout } = initialState.settings;
  let className = styles.right;
  className += ' hide-md';

  if ((navTheme === 'dark' && layout === 'top') || layout === 'mix') {
    className = `${styles.right}  ${styles.dark}`;
  }


  const { notifications, setNotifications } = useModel('notifications', (ret) => ({
    notifications: ret.notifications,
    setNotifications: ret.setNotifications,
  }));

  const { balance, setBalance } = useModel('balance', (ret) => ({
    balance: ret.balance,
    setBalance: ret.setBalance,
  }));





  const guestMenuDropdown = (
    <Menu forceSubMenuRender className={'header-menu'}>
      <Menu.Item onClick={() => history.push('/')}>
        <FontAwesomeIcon icon={AllDTIcons.faHouse} /> Home
      </Menu.Item>
      <Menu.Item onClick={() => history.push('/jobs-realestate')}>
        <FontAwesomeIcon icon={AllDTIcons.faList} />Jobs & Immobilien
      </Menu.Item>
    </Menu>
  );


  useEffect(() => {
    if (initialState.currentUser) {
      Helper.initBalanceAndMessages(initialState.currentUser, setNotifications, notifications, setBalance, balance);
    }
  }, [initialState.currentUser]);

  return (
    <>
      <div className="right-content-bar">
        <Space className={className}>






          {!isMobile &&
            <div className="desktop-menu">

              {initialState.currentUser &&
                <>


                  <span>
                    <StatusMessengerButton showButton />
                  </span>

                  <span onClick={() => history.push('/user/credits')}>
                    <BalanceIcon />
                  </span>

                  <span>
                    <NoticeIcon key={'desktop'} top />
                  </span>


                  <span>
                    <Tooltip title={intl.formatMessage({ id: 'rightcontent.tooltip.profile', defaultMessage: 'Profile' })}>
                      <Link to={'/user/profile'}><Button icon={<FontAwesomeIcon icon={AllDTIcons.faRectangleAd} />} type="primary" /></Link>
                    </Tooltip>
                  </span>


                  <span>
                    <Tooltip title={intl.formatMessage({ id: 'rightcontent.tooltip.banner', defaultMessage: 'Banner' })}>
                      <Link to={'/user/banner'}><Button icon={<FontAwesomeIcon icon={AllDTIcons.faBrowser} />} type="primary" /></Link>
                    </Tooltip>
                  </span>

                  <span>
                    <Tooltip title={intl.formatMessage({ id: 'rightcontent.tooltip.settings', defaultMessage: 'Kontoeinstellungen' })}>
                      <Link to={'/user/settings'}><Button icon={<FontAwesomeIcon icon={AllDTIcons.faGears} />} type="primary"></Button></Link>
                    </Tooltip>
                  </span>



                  <span>
                    <Tooltip title={intl.formatMessage({ id: 'rightcontent.tooltip.logout', defaultMessage: 'Logout' })}>
                      <Link to={'/user/logout'}><Button icon={<FontAwesomeIcon icon={AllDTIcons.faArrowUpLeftFromCircle} />} type="primary" /></Link>
                    </Tooltip>
                  </span>

                  <span>
                    <SelectLang icon={
                      <Button title="Sprache" type={'primary'}><FontAwesomeIcon icon={AllDTIcons.faGlobe} /></Button>
                    }
                      trigger={(Helper.isTouchEnable()) ? 'click' : 'hover'}
                    />
                  </span>


                </>
              }
              {!initialState.currentUser &&
                <>
                <span>
                    <StatusMessengerButton showButtonWithLabel />
                  </span>
                  <span>
                    <Tooltip title="Login">
                      <Link to={'/user/login'}>
                        <Button className={'btn-purple btn-login'} icon={<FontAwesomeIcon icon={AllDTIcons.faUser} />} type="primary">
                          {intl.formatMessage({ id: 'rightcontent.btn.login', defaultMessage: 'Anmelden / Konto erstellen' })}
                        </Button>
                      </Link>
                    </Tooltip>
                  </span>



                  <WishlistList showButton showList />


                  <span>
                    <SelectLang icon={
                      <Button title="Sprache" type={'primary'}><FontAwesomeIcon icon={AllDTIcons.faGlobe} /></Button>
                    }
                      trigger={(Helper.isTouchEnable()) ? 'click' : 'hover'}
                    />
                  </span>


                </>
              }



            </div>

          }


          


          {isMobile &&
            <div className="mobile-menu">


              <span>
                <StatusMessengerButton showButtonWithLabel />
              </span>

              {!initialState.currentUser &&
                <>
                  <span>
                    <Tooltip title="Login">
                      <Link to={'/user/login'}>
                        <Button className={'btn-purple'} icon={<FontAwesomeIcon icon={AllDTIcons.faUser} />} type="primary">
                        </Button>
                      </Link>
                    </Tooltip>
                  </span>



                </>
              }


              {initialState.currentUser &&
                <>


                  <span>
                    <Tooltip title={intl.formatMessage({ id: 'rightcontent.tooltip.logout', defaultMessage: 'Logout' })}>
                      <Link to={'/user/logout'}><Button icon={<FontAwesomeIcon icon={AllDTIcons.faArrowUpLeftFromCircle} />} type="primary" /></Link>
                    </Tooltip>
                  </span>



                </>
              }



              <span>
                <SelectLang icon={
                  <Button title="Sprache" type={'primary'}><FontAwesomeIcon icon={AllDTIcons.faGlobe} /></Button>
                }
                  trigger={(Helper.isTouchEnable()) ? 'click' : 'hover'}
                />
              </span>



            </div>
          }





        </Space>
      </div>

    </>
  );
};

export default GlobalHeaderRight;
