/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */


 import Helper from '@/helper/Helper';



export default function access(initialState) {



  const { currentUser } = initialState || {};

  //console.log(initialState);

  const permissions = Helper.getItem('permissions');
  const roles = Helper.getItem('roles');

  return {
    isUser: () => (roles && (roles.includes("creator") || roles.includes("club") || roles.includes("visitor"))),
    isCreator: () => (roles && (roles.includes("creator") || roles.includes("club"))),
    isClub: () => (roles && roles.includes("club")),
    isVisitor: () => (!permissions),
  };
}
