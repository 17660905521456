// @ts-ignore

/* eslint-disable */
import { request, useModel, useIntl, history } from 'umi';
import { Alert, message, Tabs } from 'antd';
import Helper from '@/helper/Helper';
import Logger from '@/helper/Logger';



/** GET /api/checkMaintance */
export async function checkMaintance(options) {
  return fetch('/api/checkMaintance?_format=json');
}






/** GET /api/currentUser */
export async function currentUser(options) {

  const currentUser = Helper.getCurrentUser();
  //console.log(currentUser);

  if(!currentUser) {
    return {};
  }

  const response = request('/api/rest/webuser?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  }).then(function(response) {
    return response;
  }).catch(function(error) {
    Helper.clearLocalStorage();
    return error;
  });;

  const tmpResponse = await response;

  Helper.setItem('userDetail', JSON.stringify(tmpResponse.user));
  Helper.setItem('roles', JSON.stringify(tmpResponse.roles));
  Helper.setItem('permissions', JSON.stringify(tmpResponse.permissions));
  return response;
}


/** GET /api/jwt/token */
export async function refreshToken(options) {

  return request('/api/jwt/token?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  });
}


/** POST /api/login */
export async function login(body, options) {
  const returnResponse = request('/api/rest/webuser/login/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'login', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'login', 'error', '');
    return error;
  });

  return returnResponse;
}



/** POST /api/rest/webuser/password */
export async function changePassword(body, options) {
  const returnResponse = request('/api/rest/webuser/password?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'changePassword', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'changePassword', 'error', '');
    return error;
  });

  return returnResponse;
}


/** POST /api/rest/webuser/update */
export async function updateUser(body, options) {
  const returnResponse = request('/api/rest/webuser/update?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'updateUser', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'updateUser', 'error', '');
    return error;
  });

  return returnResponse;
}


/** POST /api/lostPassword */
export async function lostPassword(body, options) {
  const returnResponse = request('/api/user/lost-password?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'lostPassword', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'lostPassword', 'error', '');
    return error;
  });

  return returnResponse;
}


/** POST /api/lostPassword */
export async function resetPassword(body, options) {
  const returnResponse = request('/api/user/lost-password-reset?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'resetPassword', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'resetPassword', 'error', '');
    return error;
  });
  return returnResponse;
}


/** POST /api/rest/registerUser */
export async function registerUser(body, options) {
  const returnResponse = request('/api/rest/registerUser?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'resetPassword', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'resetPassword', 'error', '');
    return error;
  });
  return returnResponse;
}



/** POST /api/logout */
export async function logout(body, options) {

  const returnResponse = request('/api/rest/webuser/logout?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    //console.log(response);
    Helper.clearLocalStorage();
    //window.location.reload(false);
    return response;
  })
  .catch(function(error) {
    Helper.clearLocalStorage();
    //window.location.reload(false);
    //console.log(error);
  });

  return returnResponse;
}




/** POST confirmUser */
export async function confirmUser(body, options) {
  const returnResponse = request('/api/rest/confirmUser?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    return response;
  }).catch(function(error) {
    return error;
  });
  return returnResponse;
}


/** POST confirmUser */
export async function activateUser(body, options) {
  const returnResponse = request('/api/rest/user/activation?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    return response;
  }).catch(function(error) {
    return error;
  });
  return returnResponse;
}




/** POST /api/rest/notices */
export async function getNotices(options) {
  return request('/api/notices', {
    method: 'GET',
    ...(options || {}),
  });
}

/**
 * Term api
 */
/** GET /api/currentUser */
export async function loadTerms(params, options) {

  const returnResponse = request('/api/rest/terms?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    return response;
  }).catch(function(error) {
    return error;
  });
  return returnResponse;
}







/**
 * Transaction api
 */
/** GET /api/currentUser */
export async function getTransactions(params, options) {

  const response = request('/api/rest/transactions/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}

/** POST rest/datatrans/payment */
export async function paymentTransaction(params, options) {
  const returnResponse = request('/api/rest/datatrans/payment', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    return response;
  }).catch(function(error) {
    return error;
  });
  return returnResponse;
}

/** POST rest/wallee_payment/payment */
export async function paymentTransactionWallee(params, options) {
  const returnResponse = request('/api/rest/wallee_payment/payment', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    return response;
  }).catch(function(error) {
    return error;
  });
  return returnResponse;
}

/** POST rest/wallee_payment/payment */
export async function paymentTransactionPushads(params, options) {
  const returnResponse = request('/api/rest/pushads_payment/payment', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    return response;
  }).catch(function(error) {
    return error;
  });
  return returnResponse;
}


/** POST verify */
export async function stripePaymentInit(params, options) {
  const returnResponse = request('/api/rest/stripe_payment/payment', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    return response;
  }).catch(function(error) {
    return error;
  });
  return returnResponse;
}


/** POST rest/datatrans/payment */
export async function postTransferPayment(params, options) {
  const returnResponse = request('/api/rest/transfer_payment/create', {
    skipErrorHandler: true,
      method: 'POST',
      data: params,
      hheaders: {
        'Content-Type': 'application/json',
      },
      ...(options || {}),
    }).then(function(response) {
      return response;
    }).catch(function(error) {
      return error;
    });
    return returnResponse;
}






export async function uploadFile(params, options) {
  Logger.logAction({params, options}, 'uploadFile', 'info', '');
    return request('/api/rest/uploads/create?_format=json', {
      skipErrorHandler: true,
      method: 'POST',
      data: params,
      hheaders: {
        'Content-Type': 'application/json',
      },
      ...(options || {}),
    }).then(function(response) {
      Logger.logAction(response, 'uploadFile', 'info', '');
      return response;
    }).catch(function(error) {
      Logger.logAction(error, 'uploadFile', 'error', '');
      return error;
    });
}

export async function uploadEncodeFile(params, options) {
  Logger.logAction({params, options}, 'uploadEncodeFile', 'info', '');
    return request('/api/rest/uploads/encode/create?_format=json', {
      skipErrorHandler: true,
      method: 'POST',
      data: params,
      hheaders: {
        'Content-Type': 'application/json',
      },
      ...(options || {}),
    }).then(function(response) {
      Logger.logAction(response, 'uploadEncodeFile', 'info', '');
      return response;
    }).catch(function(error) {
      Logger.logAction(error, 'uploadEncodeFile', 'error', '');
      return error;
    });
}










/**
 * profile api
 */

 /** POST /api/rest/profile/creator/create */
 export async function postProfile(body, options) {
   const returnResponse = request('/api/rest/profile/creator/create?_format=json', {
     skipErrorHandler: true,
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
     },
     data: body,
     ...(options || {}),
   }).then(function(response) {
     Logger.logAction(response, 'postProfile', 'info', '');
     return response;
   }).catch(function(error) {
     Logger.logAction(error, 'postProfile', 'error', '');
     return error;
   });
   return returnResponse;
 }

 export async function createProfileImage(body, options) {
  const returnResponse = request('/api/rest/profile_image/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'createProfileImage', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'createProfileImage', 'error', '');
    return error;
  });
  return returnResponse;
}

export async function getProfileImage(params, options) {
  const returnResponse = request('/api/rest/profile_image/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'getProfileImage', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'getProfileImage', 'error', '');
    return error;
  });
  return returnResponse;
}


 export async function createProfileVideo(body, options) {
   const returnResponse = request('/api/rest/profile_video/create?_format=json', {
     skipErrorHandler: true,
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
     },
     data: body,
     ...(options || {}),
   }).then(function(response) {
     Logger.logAction(response, 'createProfileVideo', 'info', '');
     return response;
   }).catch(function(error) {
     Logger.logAction(error, 'createProfileVideo', 'error', '');
     return error;
   });
   return returnResponse;
 }

/** GET /api/rest/profile/creator/get */
export async function getCreatorProfile(params, options) {

  const response = request('/api/rest/profile/creator/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}


/** GET /api/rest/profile/user/get */
export async function getProfile(params, options) {

  const returnResponse = request('/api/rest/profile/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    return response;
  }).catch(function(error) {
    return error;
  });
  return returnResponse;
}





/**
 * story api
 */
/** GET /api/rest/story/get */
export async function getStory(params, options) {

  const returnResponse = request('/api/rest/story/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    return response;
  }).catch(function(error) {
    return error;
  });
  return returnResponse;
}


export async function createStory(body, options) {
  const returnResponse = request('/api/rest/story/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'createStory', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'createStory', 'error', '');
    return error;
  });
  return returnResponse;
}





/**
 * status message api
 */
/** GET /api/rest/status_messenger/get */
export async function getStatusMessages(params, options) {

  const returnResponse = request('/api/rest/status_messenger/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    return response;
  }).catch(function(error) {
    return error;
  });
  return returnResponse;
}


export async function postStatusMessage(body, options) {
  const returnResponse = request('/api/rest/status_messenger/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'postStatusMessage', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'postStatusMessage', 'error', '');
    return error;
  });
  return returnResponse;
}




/**
 * support api
 */
/** GET /api/rest/support_ticket/get */
export async function getSupportTicket(params, options) {

  const response = request('/api/rest/support_ticket/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}


export async function createSupportTicket(body, options) {
  const returnResponse = request('/api/rest/support_ticket/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'createStory', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'createStory', 'error', '');
    return error;
  });
  return returnResponse;
}





/**
 * id verification api
 */
/** GET /api/rest/id_verification/get */
export async function getVerification(params, options) {

  const response = request('/api/rest/verification/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}

/** POST /api/rest/id_verification/create */
export async function createVerification(body, options) {
  const returnResponse = request('/api/rest/verification/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'createVerification', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'createVerification', 'error', '');
    return error;
  });
  return returnResponse;
}




/**
 * report api
 */
/** GET /api/rest/report/get */


/** POST /api/rest/report/create */
export async function createReport(body, options) {
  const returnResponse = request('/api/rest/report/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'createReport', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'createReport', 'error', '');
    return error;
  });
  return returnResponse;
}




/**
 * guest api
 */
/** GET /api/rest/guest/get */
export async function getGuestInfos(params, options) {

  const response = request('/api/rest/guest/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}



/**
 * webuser api
 */
/** GET /api/rest/webuser/notify_auth/get */
export async function getNotifyAuthToken(params, options) {

  const response = request('/api/rest/webuser/notify_auth/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}




/**
 * upvote api
 */
/** GET /api/rest/upvote/get */
export async function getUpvote(params, options) {

  const response = request('/api/rest/upvote/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}

/** POST /api/rest/upvote/create */
export async function createUpvote(body, options) {
  const returnResponse = request('/api/rest/upvote/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'createUpvote', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'createUpvote', 'error', '');
    return error;
  });
  return returnResponse;
}





/**
 * top_profile api
 */
/** GET /api/rest/top_profile/get */
export async function getTopProfile(params, options) {

  const returnResponse = request('/api/rest/top_profile/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    return response;
  }).catch(function(error) {
    return error;
  });
  return returnResponse;
}

/** POST /api/rest/top_profile/create */
export async function createTopProfile(body, options) {
  const returnResponse = request('/api/rest/top_profile/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'createTopad', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'createTopad', 'error', '');
    return error;
  });
  return returnResponse;
}



/**
 * notification api
 */
/** GET /api/rest/notification/get */
export async function getNotification(params, options) {

  const response = request('/api/rest/notification/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}



/**
 * auto translate api
 */
/** GET /api/rest/notification/get */
export async function autoTranslate(string) {
  return string;
}





/**
 * club api
 */
/** GET /api/rest/profile/user/get */
export async function getUserClubs(params, options) {

  const response = request('/api/rest/profile/user/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}


/** GET /api/rest/profile/user/get */
export async function getClubs(params, options) {

  const response = request('/api/rest/profile/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}



/**
 * banner api
 */
export async function createBanner(body, options) {
  const returnResponse = request('/api/rest/banner/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'createBanner', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'createBanner', 'error', '');
    return error;
  });
  return returnResponse;
}

export async function getUserBanner(params, options) {

  const response = request('/api/rest/banner/user/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}


/** GET /api/rest/profile/user/get */
export async function getBanner(params, options) {

  const returnResponse = request('/api/rest/banner/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    return response;
  }).catch(function(error) {
    return error;
  });
  return returnResponse;
}




/**
 * qencode api
 */
export async function startEncoding(body, options) {
  const returnResponse = request('/api/rest/qencode/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'startEncoding', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'startEncoding', 'error', '');
    return error;
  });
  return returnResponse;
}


export async function getEncodingStatus(body, options) {
  const returnResponse = request('/api/rest/qencode/status/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'getStatus', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'getStatus', 'error', '');
    return error;
  });
  return returnResponse;
}




/**
 * coconut api
 */
export async function postCoconutEncode(body, options) {
  const returnResponse = request('/api/rest/coconut/encode/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'startEncoding', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'startEncoding', 'error', '');
    return error;
  });
  return returnResponse;
}


export async function postCoconutStatus(body, options) {
  const returnResponse = request('/api/rest/coconut/status/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'getStatus', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'getStatus', 'error', '');
    return error;
  });
  return returnResponse;
}




 /*
 * Form api
 *
*/

export async function submitDrupalForm(params, options) {
    return request('/api/rest/webform_submit/create?_format=json', {
      skipErrorHandler: true,
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'application/json',
      },
      ...(options || {}),
    }).then(function(response) {
      Logger.logAction(response, 'submitDrupalForm', 'info', '');
      return response;
    }).catch(function(error) {
      Logger.logAction(error, 'submitDrupalForm', 'error', '');
      return error;
    });
}



/*
* Click api
*
*/

/** POST /api/rest/clicks/create */
export async function postClick(params, options) {
  const returnResponse = request('/api/rest/clicks/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    data: params,
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  }).then(function(response) {
    //Logger.logAction(response, 'postClick', 'info', '');
    return response;
  }).catch(function(error) {
    //Logger.logAction(error, 'postClick', 'error', '');
    return error;
  });
  return returnResponse;
}



/**
 * secret gallery api
 */
/** GET /api/rest/story/get */
export async function getSecretGallery(params, options) {

  const response = request('/api/rest/secret_gallery/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}


export async function postSecretGallery(params, options) {
  const returnResponse = request('/api/rest/secret_gallery/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    data: params,
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  }).then(function(response) {
    //Logger.logAction(response, 'postClick', 'info', '');
    return response;
  }).catch(function(error) {
    //Logger.logAction(error, 'postClick', 'error', '');
    return error;
  });
  return returnResponse;
}




/**
 * block api
 */
/** GET /api/block/{block_content} */
export async function getBlock(params, options) {

  const response = request('/api/rest/blocks/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}





/**
 * blog api
 */
/** GET /api/rest/blog/get */
export async function getBlog(params, options) {

  const response = request('/api/rest/blog/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}


/**
 * filter_page api
 */
/** GET /api/rest/filter_page/get */
export async function getFilterPage(params, options) {

  const response = request('/api/rest/filter_page/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}