import { useState } from 'react';
import { Drawer, Button } from 'antd';

import { Link } from "react-router-dom";

import { Menu } from 'antd';
import BaseView from './components/baseMobile';
import SupportView from './components/Support';
import NotificationView from './components/notification';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllDTIcons from '@fortawesome/pro-duotone-svg-icons';

import { useIntl } from 'umi';



// You need to import the CSS only once
import './assets/css/index.css';

const Mobile = (props) => {

  const intl = useIntl();

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  
  return (
    <>
      <Button type="text" onClick={showDrawer} icon={<FontAwesomeIcon icon={AllDTIcons.faGears} />}></Button>
      <Drawer
        className="mobile-settings-drawer"
        title={<>{intl.formatMessage({ id: 'user.settings.page.title', defaultMessage: 'Kontoeinstellungen' })} <Link to={'/user/logout'}><Button className="btn-logout" size={'large'} text icon={<FontAwesomeIcon icon={AllDTIcons.faArrowUpLeftFromCircle} />}></Button></Link></>}
        placement={'bottom'}
        closable={true}
        onClose={onClose}
        open={open}
        key={'bottom'}
      >
        <BaseView />
      </Drawer>
    </>
  );
};

export default Mobile;
