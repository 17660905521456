import { Comment, List, Tooltip, Form, Button, Input, Divider, message } from 'antd';
import React, {useState, useRef} from 'react';
import moment from 'moment';
import { ProForm, ProFormCascader, ProFormDatePicker, ProFormDateRangePicker, ProFormDigit, ProFormList, ProFormMoney, ProFormSelect, ProFormText, ProFormTreeSelect, ProFormTextArea } from '@ant-design/pro-components';
import { createSupportTicket, getSupportTicket } from '@/services/ant-design-pro/api';



const CommentView = (props) => {

  const formRef = useRef();

  const [ticket, setTicket] = useState(props.supportTicket);
  const [comments, setComments] = useState(props.supportTicket.comments);
  return (
    <>
      <h3>{ticket.title[0].value}</h3>
      <p>
        {ticket.body[0].value}
      </p>
      <List
        pagination={{
          pageSize: 5
        }}
        className="comment-list"
        header={`${comments.length} replies`}
        itemLayout="horizontal"
        dataSource={comments}
        renderItem={(item) => (
          <li>
            <Comment
              author={item.author}
              content={item.body}
              datetime={moment.unix(item.created).fromNow()}
            />
          </li>
        )}
      />


    <Divider />


    {ticket.field_closed[0].value === false &&
      <ProForm
        onFinish={async (values) => {
          let options = {};
          values['action'] = 'createTicketComment';
          values['entity_id'] = ticket.nid[0].value;
          let result = await createSupportTicket(values, options);

          console.log(result);

          if(result.success) {
            message.success('success');
            let params = {
              nid: ticket.nid[0].value,
              action: 'readAction'
            };
            let supportTicket = await getSupportTicket(params, {});
            console.log(supportTicket);
            setTicket(supportTicket.data);
            setComments(supportTicket.data.comments);
            formRef.current.resetFields();
            props.actionRef.current.reload();
          }
        }}

        formRef={formRef}
        formKey="create-support-ticket-form"
        autoFocusFirstInput
      >
      <ProForm.Group>
        <ProFormText width="md" name="subject" required  label="subject" tooltip="subject" placeholder="subject" rules={[{ required: true, message: 'subject' }]}/>
        <ProFormTextArea width="md" name="comment_body" required label="comment_body" placeholder="comment_body"/>
      </ProForm.Group>
      </ProForm>
    }



    </>
  )
};
export default CommentView;
