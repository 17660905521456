import log from 'loglevel';
import { Component } from 'react';

const logEnabled = true;
let logger;
if(logEnabled) {
  //logger = new LogglyTracker();
  //logger.push({ 'logglyKey': '2821879b-c26a-4366-9ff2-49f94ddc64f4' });
  log.enableAll();
}


class Logger extends Component {

  constructor(props) {
    super(props);
  }

  static logAction(message, tag = '', type = '', info = '') {

    if(!logEnabled) {
      return;
    }
    /*
    logger.push({
     tag: tag, // add a custom tag for sorting
     message: message,
     type: type,
     info: info
    });*/

    if(type == 'info') {
      //log.info(message);
    }

    if(type == 'warn') {
      //log.warn(message);
    }
  }
}

export default Logger;
