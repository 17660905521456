import { Button, Tooltip, Badge, Modal, Divider, Space, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllDTIcons from '@fortawesome/pro-duotone-svg-icons';

import isUrl from 'is-url';


import moment from 'dayjs';


import OnlyFansIcon from '@/assets/images/onlyfans-light.svg';


import { history, useIntl } from 'umi';
import Helper from '@/helper/Helper';



// You need to import the CSS only once
import './assets/css/index.css';

const IconBar = (props) => {

  const intl = useIntl();

  let profile = props.profile;

  const closeVideoModalAction = () => {
    Modal.destroyAll();
  }

  const viewProfileAction = () => {
    Modal.destroyAll();
    history.push(Helper.getProfileLink(profile));
  }

  const showVideo = () => {
    const modal = Modal.info();
    modal.update({
      destroyOnClose: true,
      closable: true,
      style: { top: '10px' },
      className: 'modal-video',
      title: 'Video',
      footer: false,
      content: <>

        <video
          controls
          autoPlay
          poster={Helper.renderImageFieldSrc(profile.field_video_reference)}
          playsInline
          src={Helper.renderVideoFieldSrc(profile.field_video_reference)}
        />

        <Space>
          <Button size={'large'} onClick={viewProfileAction} type="primary" shape="circle" icon={<FontAwesomeIcon icon={AllDTIcons.faEye} />}>
            {intl.formatMessage({ id: 'iconbar.btn.video.profile', defaultMessage: 'Profil ansehen' })}
          </Button>
          <Button size={'large'} onClick={closeVideoModalAction} type="primary" shape="circle" icon={<FontAwesomeIcon icon={AllDTIcons.faTimes} />}>
            {intl.formatMessage({ id: 'iconbar.btn.video.close', defaultMessage: 'schliesen' })}
          </Button>
        </Space>

      </>,
    });
  }

  return (
    <div className="icon-bar">





      {profile.secret_galleries > 0 &&
        <Tooltip trigger={Helper.isTouchEnable() ? 'click' : 'hover'} title={'Secret Gallery'}>
          <Button type="primary" className="secret-badge" shape="circle" icon={<FontAwesomeIcon icon={AllDTIcons.faLockKeyhole} />} />
        </Tooltip>
      }


   
      {!profile.expired && profile.status_message && profile.status_message.body && Boolean(profile.status_message.body.length) &&

        <Popover autoAdjustOverflow destroyTooltipOnHide trigger={Helper.isTouchEnable() ? 'click' : 'hover'} content={<div style={{maxWidth: '200px'}}>{Helper.renderBlankValue(profile.status_message.body)}</div>} title={moment(Helper.renderBlankValue(profile.status_message.created)).fromNow()}>

          <Button type="primary" className="status-badge btn-yellow" shape="circle" icon={<FontAwesomeIcon icon={AllDTIcons.faCommentAltText} />} />
          </Popover>
      }


      {Boolean(profile.field_video_reference.length) && profile.field_video_reference[0].entity && Boolean(profile.field_video_reference[0].entity.field_encoded[0].value) && !profile.expired && !profile.vacation &&
        <Button title="Video" onClick={showVideo} type="primary" className="video-badge" shape="circle" icon={<FontAwesomeIcon icon={AllDTIcons.faVideo} />} />
      }

      {false && Helper.renderBlankValue(profile.field_vip) == 'true' &&
        <Tooltip trigger={Helper.isTouchEnable() ? 'click' : 'hover'} title={'VIP'}>
          <Button type="info" className="star-badge" shape="circle" icon={<FontAwesomeIcon icon={AllDTIcons.faStar} />} />
        </Tooltip>
      }

      {false && Helper.renderBlankValue(profile.field_hot) == 'true' &&
        <Tooltip trigger={Helper.isTouchEnable() ? 'click' : 'hover'} title={'HOT'}>
          <Button type="danger" className="hot-badge" shape="circle" icon={<FontAwesomeIcon icon={AllDTIcons.faFire} />} />
        </Tooltip>
      }


      {profile.field_onlyfans.length > 0 && isUrl('https://' + Helper.renderBlankValue(profile.field_onlyfans)) &&
    
          <Button title="Onlyfans" target="_blank" rel={'noreferrer'} href={'https://' + Helper.renderBlankValue(profile.field_onlyfans)}  type="danger" className="onlyfans-badge" shape="circle" icon={<img src={OnlyFansIcon} alt="onlyfans" />} />

      }

      {false && profile.field_stories.length > 0 &&
        <Tooltip trigger={Helper.isTouchEnable() ? 'click' : 'hover'} title={'Stories'}>
          <Button className="story-badge" type="primary" shape="circle" icon={<Badge count={profile.field_stories.length}><FontAwesomeIcon icon={AllDTIcons.faRectangleVerticalHistory} /></Badge>} />
        </Tooltip>
      }

    </div>
  );
};

export default IconBar;
