import { Tooltip, Button } from 'antd';
import { useModel } from 'umi';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllDTIcons from '@fortawesome/pro-duotone-svg-icons';


const BalanceIcon = () => {



  const { balance, setBalance } = useModel('balance', (ret) => ({
    balance: ret.balance,
    setBalance: ret.setBalance,
  }));

  return (
    <>

      <Tooltip title={(balance) ? 'Credits: ' + balance : 'Credits: ' + 0}>
        <Button type="primary" className="btn-balance">
          <FontAwesomeIcon icon={AllDTIcons.faCircleDollar} />
        </Button>
      </Tooltip>
    </>
  );
};

export default BalanceIcon;
