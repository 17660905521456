import { forwardRef, useImperativeHandle } from 'react';

import { useModel } from 'umi';


const PageContext = forwardRef((props, ref) => {

  const { setPageContext } = useModel('pageContext', (ret) => ({
    setPageContext: ret.setPageContext,
  }));
  

  useImperativeHandle(ref, () => {
    return {
      setPageContext(context) {
        setPageContext(context);
      },
    };
  });

  return (
    <></>
  );
});


export default PageContext;
