
import { useState, useRef, useEffect } from 'react';

import { PageContainer } from '@ant-design/pro-layout';

import { Input, Upload, message, Row, Col, Form, Divider, List, Switch, Spin } from 'antd';
import { Link } from "react-router-dom";

import { updateUser } from '@/services/ant-design-pro/api';
import { useIntl } from 'umi';


import ProForm, {
    ProFormSelect,
    ProFormText,
} from '@ant-design/pro-form';

const WebPush = (props) => {


    const intl = useIntl();

    let user = props.user;

    const [pushEnabled, setPushEnabled] = useState((user.field_web_push_subscriptions.length > 0 || user.field_allow_push_notifications[0].value == 'true') ? true : false);

    const pushButton = useRef();


    const applicationServerKey = 'BMBlr6YznhYMX3NgcWIDRxZXs0sh7tCv7_YCsWcww0ZCv9WGg-tRCXfMEHTiBPCksSqeve1twlbmVAZFv7GSuj0';
    let isPushEnabled = false;




    const isSupported = async () => {

        const registration = await navigator.serviceWorker.ready;

        if ('pushManager' in registration) {
            return true;
        }


        if ('Notification' in window &&
            'serviceWorker' in navigator &&
            'PushManager' in window) {
            return true;
        }


        return false;
    }


    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);



    const changePushButtonState = (state) => {
        switch (state) {
            case 'enabled':
                //setPushEnabled(true);
                //pushButton.current.disabled = false;
                //pushButton.current.textContent = 'Disable Push notifications';
                isPushEnabled = true;
                break;
            case 'disabled':
                //setPushEnabled(false);
                //pushButton.current.disabled = false;
                //pushButton.current.textContent = 'Enable Push notifications';
                isPushEnabled = false;
                break;
            case 'computing':
                //pushButton.current.disabled = true;
                //pushButton.current.textContent = 'Loading...';
                break;
            case 'incompatible':
                // setPushEnabled(false);
                //pushButton.current.disabled = true;
                //pushButton.current.textContent = 'Push notifications are not compatible with this browser';
                break;
            default:
                //setPushEnabled(false);
                console.error('Unhandled push button state', state);
                break;
        }
    }

    const urlBase64ToUint8Array = (base64String) => {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    const checkNotificationPermission = () => {
        return new Promise((resolve, reject) => {
            if (Notification.permission === 'denied') {
                return reject(new Error('Push messages are blocked.'));
            }

            if (Notification.permission === 'granted') {
                return resolve();
            }

            if (Notification.permission === 'default') {
                return Notification.requestPermission().then(result => {
                    if (result !== 'granted') {
                        reject(new Error('Bad permission result'));
                    } else {
                        resolve();
                    }
                });
            }

            return reject(new Error('Unknown permission'));
        });
    }

    const push_subscribe = async () => {

        props.formRef.current.setFieldValue('field_allow_push_notifications', true);

        if (!isSupported() || !isMobile) {
            props.formRef.current.setFieldValue('field_web_push_subscriptions', false);
            return;
        }

        changePushButtonState('computing');

        return checkNotificationPermission()
            .then(() => navigator.serviceWorker.ready)
            .then(serviceWorkerRegistration =>
                serviceWorkerRegistration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
                })
            )
            .then(subscription => {
                // Subscription was successful
                // create subscription on your server

                return push_sendSubscriptionToServer(subscription, 'POST');
            })
            .then(subscription => subscription && changePushButtonState('enabled')) // update your UI
            .catch(e => {
                if (Notification.permission === 'denied') {
                    // The user denied the notification permission which
                    // means we failed to subscrribe and the user will need
                    // to manually change the notification permission to
                    // subscribe to push messages
                    console.warn('Notifications are denied by the user.');
                    changePushButtonState('incompatible');
                } else {
                    // A problem occurred with the subscription; common reasons
                    // include network errors or the user skipped the permission
                    console.error('Impossible to subscribe to push notifications', e);
                    changePushButtonState('disabled');
                }
            });
    }

    const push_updateSubscription = () => {
        navigator.serviceWorker.ready
            .then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription())
            .then(subscription => {
                changePushButtonState('disabled');

                if (!subscription) {
                    // We aren't subscribed to push, so set UI to allow the user to enable push
                    return;
                }

                // Keep your server in sync with the latest endpoint
                return push_sendSubscriptionToServer(subscription, 'PUT');
            })
            .then(subscription => subscription && changePushButtonState('enabled')) // Set your UI to show they have subscribed for push messages
            .catch(e => {
                console.error('Error when updating the subscription', e);
            });
    }

    const push_unsubscribe = async (e) => {

        props.formRef.current.setFieldValue('field_allow_push_notifications', false);
        props.formRef.current.setFieldValue('field_web_push_subscriptions', false);

        /*
        changePushButtonState('computing');

        if (!isSupported()) {
            props.formRef.current.setFieldValue('field_web_push_subscriptions', false);
            return;
        }


        // To unsubscribe from push messaging, you need to get the subscription object
        navigator.serviceWorker.ready
            .then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription())
            .then(subscription => {
                // Check that we have a subscription to unsubscribe
                if (!subscription) {
                    // No subscription object, so set the state
                    // to allow the user to subscribe to push
                    changePushButtonState('disabled');
                    return;
                }

                // We have a subscription, unsubscribe
                // Remove push subscription from server
                return push_sendSubscriptionToServer(subscription, 'DELETE');
            })
            .then(subscription => subscription.unsubscribe())
            .then(() => changePushButtonState('disabled'))
            .catch(e => {
                // We failed to unsubscribe, this can lead to
                // an unusual state, so  it may be best to remove
                // the users data from your data store and
                // inform the user that you have done so
                console.error('Error when unsubscribing the user', e);
                changePushButtonState('disabled');
            });*/
    }

    const push_sendSubscriptionToServer = async (subscription, method) => {

        console.log(method);

        const key = subscription.getKey('p256dh');
        const token = subscription.getKey('auth');
        const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0];

        let subscriptionObject = JSON.stringify({
            endpoint: subscription.endpoint,
            publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
            authToken: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
            contentEncoding,
        });


        props.formRef.current.setFieldValue('field_web_push_subscriptions', (method == 'DELETE') ? false : subscriptionObject);
    }


    const onPushButtonClick = () => {
        setPushEnabled(!pushEnabled);
        if (pushEnabled) {
            push_unsubscribe();
        } else {
            push_subscribe();
        }
    }

    const onSendPushButtonClick = () => {
        navigator.serviceWorker.ready
            .then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription())
            .then(subscription => {
                if (!subscription) {
                    alert('Please enable push notifications');
                    return;
                }

                const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0];
                const jsonSubscription = subscription.toJSON();
                fetch('https://api.dev.escord.ch/webPush/send_push_notification.php', {
                    mode: 'no-cors',
                    method: 'POST',
                    body: JSON.stringify(Object.assign(jsonSubscription, { contentEncoding })),
                });
            })
    }


    return (
        <>

            <ProForm.Item name={'field_allow_push_notifications'} label={''} valuePropName="checked" className={'lable-hidden'}>
            <Switch onChange={onPushButtonClick} checkedChildren={intl.formatMessage({ id: 'common.yes', defaultMessage: 'Ja' })} unCheckedChildren={intl.formatMessage({ id: 'common.no', defaultMessage: 'Nein' })}  />
            </ProForm.Item>

        
                   {/**<button ref={pushButton} id="push-subscription-button" onClick={() => onPushButtonClick()}>Push notifications !</button> */}
             

        </>
    );
};

export default WebPush;
