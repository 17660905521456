export default  {
  "logout.success": "Cierre de sesión exitoso",
  "common.banner.title": "Publicidad en escort.ch",
  "common.yes": "Sí",
  "common.no": "No",
  "form.required": "Campo obligatorio",
  "form.textPlaceholder": "Por favor escribe",
  "block.support.title": "¿Tienes preguntas?",
  "modal.sure": "¿Está seguro?",
  "footer.link.profile": "Precios de perfil",
  "footer.link.banner": "Precios de pancartas",
  "footer.link.support": "Apoyo",
  "footer.link.faq": "Preguntas más frecuentes",
  "footer.link.login": "Acceso",
  "footer.opening.row1": "Lun-Vie 09:00 a 21:00",
  "footer.opening.row2": "Sáb\/Dom 10:00 am a 3:00 pm",
  "footer.opening.text": "WhatsApp y correos electrónicos se procesarán nuevamente a las 10:00 p.m.",
  "footer.legal.compliance": " Aviso de cumplimiento",
  "footer.legal.refund": " Politica de reembolso",
  "footer.legal.gtc": "GTC",
  "footer.legal.privacy": "Privacidad",
  "footer.legal.imprint": "Imprimir",
  "iconbar.btn.video.profile": " Ver perfil",
  "iconbar.btn.video.close": "Cerca",
  "noticeicon.title": "Mensajes",
  "noticeicon.btn.unread": "marcar todo como leido",
  "regionfiltertags.title": "Contactos sexuales en los siguientes lugares",
  "rightcontent.tooltip.profile": "Perfiles",
  "rightcontent.tooltip.banner": "Bandera",
  "rightcontent.tooltip.settings": "Ajustes",
  "rightcontent.tooltip.logout": "Cerrar sesión",
  "rightcontent.btn.login": "Crear cuenta \/ iniciar sesión",
  "premium.modal.title": "Perfiles premium en escort.ch",
  "verifyicon.tooltip": "Verificado",
  "wishlist.tooltip.remove": "Quitar de favoritos",
  "wishlist.tooltip.add": "Agregar a los favoritos",
  "wishlist.drawer.title": "Favoritos",
  "faq.page.title": "Preguntas frecuentes",
  "faq.support.title": "¿Tu pregunta no fue incluida?",
  "home.meta.title": "Aventuras eróticas en tu zona - escort.ch",
  "home.meta.description": "★★★ Aventuras eróticas en tu zona. ♥ ¡Encuentra tu escolta adecuada! escort.ch ▷ El nuevo portal de sexo y erotismo en Suiza.",
  "home.page.title": "Aventuras eróticas en tu zona",
  "home.list.title": "Resultados coincidentes",
  'home.list.title.ready': 'Buscar, descubrir y disfrutar', // template
  'home.filter.banner.divider': 'Los filtros son presentados por',
  "home.filter.tag.field_expired": " Vuelva pronto",
  "home.filter.tag.field_verified": "Verificado",
  "home.filter.tag.field_secret_gallery": "galería secreta",
  "home.filter.tag.field_hot": "Caliente",
  "home.filter.tag.field_video": "Video",
  "home.filter.tag.field_stories": "Historia",
  "home.filter.tag.field_trans": "Trans",
  "home.filter.tag.field_female": "Femenino",
  "home.filter.location.error": "Debe permitir el acceso a la ubicación en su navegador para usar esta función",
  "home.filter.btn.show": "Filtrar",
  "home.filter.drawer.title": "Filtrar",
  "home.filter.keyword.divider": "Buscar",
  "home.filter.keyword.placeholder": "Nombre u otro término de búsqueda...",
  "home.filter.popular_tags.divider": "Etiquetas Populares",
  "home.filter.more.divider": "Más filtros",
  "home.filter.field_regions.header": "Regiones",
  "home.filter.field_regions.option.region": "Región \/ cantón",
  "home.filter.field_regions.option.near": "Perímetro",
  "home.filter.field_rubrics.header": "Rúbricas",
  "home.filter.field_offers.header": "Servicios",
  "home.filter.field_age.header": "Edad",
  "home.filter.field_height.header": "Altura",
  "home.filter.field_weight.header": "Peso",
  "home.filter.field_eye_color.header": "Color de los ojos",
  "home.filter.field_hair_color.header": "Color de pelo",
  "home.filter.field_hair_length.header": "Largo del pelo",
  "home.filter.field_language.header": "Idiomas",
  "home.filter.field_person_types.header": "Grupo étnico",
  "home.filter.field_cup_size.header": "tamaño de la copa",
  "home.filter.field_physique.header": "Físico",
  "home.filter.field_smoking.header": "De fumar",
  "home.filter.field_shoe_size.header": "Tamaño del zapato",
  "home.filter.field_intimate_area.header": "zona intima",
  "home.filter.field_body_features.header": "Características del cuerpo",
  "home.filter.geolocation.error": "Debe permitir el acceso a la ubicación en su navegador para usar esta función",
  "home.filter.geolocation.radius.label": "Perímetro",
  "profile.list.expired": "está ausente",
  "login.page.title": "Login Crear cuenta",
  "login.register.title": "Crea tu cuenta en escort.ch",
  "login.register.text": "Regístrese en escort.ch. La plataforma de acompañantes moderna en Suiza.",
  "login.register.benefit01": "Registro completamente gratis",
  "login.register.benefit02": "Discreto, seguro y anónimo gracias al cifrado SSL",
  "login.register.benefit03": "Acceso al Administrador de perfiles",
  "login.register.benefit04": "Escort.ch es una empresa suiza con altos estándares de calidad.",
  "login.register.benefit05": "Interfaz fácil de usar y diseño moderno",
  "login.register.button.label": "Regístrate ahora gratis",
  "login.form.card.title": "Login",
  "login.card.benefit.title": "Las ventajas de escort.ch",
  "login.card.benefit.text": "En escort.ch se beneficia de las siguientes ventajas. El registro es completamente gratuito. Nuestro sitio web se caracteriza por una interfaz fácil de usar y un diseño moderno. También damos gran importancia al servicio al cliente y la privacidad para garantizar un entorno seguro y positivo para todos nuestros miembros.",
  "login.card.benefit.icon01": "300 créditos concedidos",
  "login.card.benefit.icon02": "Discreto y seguro",
  "login.card.benefit.icon03": "Moderno e innovador",
  "login.card.benefit.icon04": "Varios perfiles posibles",
  "login.message.success": "Inicio de sesión correcto.",
  "login.form.email.label": "Dirección de correo electrónico",
  "login.form.password.label": "Contraseña",
  "login.button.passwordForget": "Has olvidado tu contraseña",
  "login.message.passwordSuccess": "Se han enviado más instrucciones a su dirección de correo electrónico.",
  "login.passwordForget.success": "Su nueva contraseña ha sido guardada. Por favor Iniciar sesión.",
  "login.validate.email": "Sin dirección de correo electrónico válida",
  "login.activationCode": "Código de activación",
  "login.newPassword": "Nueva contraseña",
  "login.newPasswordConfirm": "Confirmar nueva contraseña",
  "login.newPassword.error": "Las contraseñas no coinciden.",
  "login.message.passwordForget": "Si olvidó su contraseña, puede restablecerla. Introduzca su dirección de correo electrónico a continuación. Le enviaremos un código de activación.",
  "register.form.gtc": "Al registrarse, acepta nuestros términos y condiciones generales.",
  "register.form.privacy": "Al registrarte, aceptas nuestra Política de Privacidad.",
  "register.form.newsletter": "Suscríbete al boletín",
  "register.form.confirm.title": "Le hemos enviado el código de confirmación por correo electrónico. Por favor ingrese esto para completar el registro.",
  "register.form.btn.back": "Atrás",
  "payment.page.cancel.title": "Pago abortado",
  "payment.page.cancel.subtitle": "Vuelve a intentarlo o ponte en contacto con el servicio de asistencia.",
  "payment.page.cancel.button.credits": "Compra creditos",
  "payment.page.cancel.button.support": "Apoyo",
  "payment.page.confirm.title": "Pago exitoso",
  "payment.page.confirm.subtitle": "Diviértete con tus créditos.",
  "payment.page.confirm.button.profile": "Al administrador de perfiles",
  "payment.page.error.title": "Pago fallido",
  "payment.page.error.subtitle": "Vuelve a intentarlo o ponte en contacto con el servicio de asistencia.",
  "price.profile.page.title": "Precios de perfil",
  "price.profile.card.title": "La ventaja principal",
  "price.profile.btn.create": "Crea un perfil ahora",

  "profile.navigation.prev": "Perfil anterior", 
  "profile.navigation.next": "Perfil siguiente", 

  "price.banner.page.title": "Precios de pancartas",
  "price.banner.card.title": "Información importante",
  "price.banner.btn.creat": "Crea pancartas ahora",
  "support.form.succes": "Tu solicitud ha sido enviada.",
  "support.form.name": "Su nombre",
  "support.form.email": "Correo electrónico",
  "support.form.phone": "Teléfono",
  "support.form.message": "Tu mensaje",
  "profile.report.btn": "Reportar perfil",
  "profile.share.btn": "Compartir perfil",
  "profile.report.success": "Gracias por tu mensaje.",
  "profile.report.field_reason": "Motivo de la denuncia",
  "profile.report.body": "Mensaje",
  "profile.card.about.title": "Acerca de mí",
  "profile.text.more": "Leer más",
  "profile.text.less": "Leer menos",
  "profile.translate.btn": "Traducir",
  "profile.card.data.title": "Detalles",
  "profile.card.data.field_hair_color": "Cabello",
  "profile.card.data.field_body_features": "Características",
  "profile.card.data.field_physique": "Físico",
  "profile.card.data.field_height": "Altura",
  "profile.card.data.field_weight": "Peso",
  "profile.card.data.field_cup_size": "tamaño de la copa",
  "profile.card.data.field_smoking": "De fumar",
  "profile.card.data.field_intimate_area": "zona intima",
  "profile.card.data.field_shoe_size": "Tamaño del zapato",
  "profile.field_website": "Visita el sitio web",
  "profile.message.sms": ", te encontré en escort.ch",
  "profile.secretgallery.message.hello": " Hola ",
  "profile.secretgallery.modal.title": "Se requiere un código pin para acceder a las imágenes en la Galería secreta.",
  "profile.secretgallery.title": "Utilice las opciones de contacto que se enumeran a continuación para solicitar este código PIN.",
  "profile.secretgallery.message.sms": " Me gustaría el pin de tu galería secreta en escort.ch.",
  'profile.secretgallery.message.error': 'El PIN introducido no es correcto.', // template


  'user.profile.updateRegionRubric.modal.success': 'Sus regiones y rúbricas han sido actualizadas.',
  'user.profile.updateRegionRubric.modal.alert.region':'Actualice ahora sus regiones.',
  'user.profile.updateRegionRubric.modal.alert.rubric':'Actualice ya sus rúbricas.',




  "user.banner.page.title": "Administrador de anuncios",
  "user.banner.btn.create": "Crear pancarta",
  "user.banner.btn.edit": "Editar pancarta",
  "user.banner.form.success": "Pancarta guardada.",
  "user.banner.field_banner_size": "Tipo",
  "user.banner.field_banner_clicks": "Clics",
  "user.banner.delete.success": "Se ha eliminado el banner",
  "user.banner.renew.success": "El cartel ha sido renovado",
  "user.banner.column.totalClicks": "Clics totales",
  "user.banner.column.field_banner_clicks": "Haga clic en Cuota",
  "user.banner.btn.renew": "Renovar",
  "user.banner.btn.delete": "Borrar",
  "user.banner.filter.field_all": "Todo",
  "user.banner.filter.field_active": "Activo",
  "user.banner.filter.field_expired": "Venció",
  "user.banner.filter.field_review": "Revisar",
  "user.banner.filter.field_decline": "Rechazado",
  "user.banner.filter.field_deleted": "Eliminado",



  "banner.visit.website": "Visitar el sitio web",
  
  "user.credits.page.title": " Saldo de la cuenta corriente:",
  "user.credits.form.card.title": "Compra creditos",
  "user.credits.form.btn.payonline": "Paga en linea",
  "user.credits.swissbill.additionalInformation": " Número de factura:",
  "user.credits.swissbill.btn": "Pagar por transferencia bancaria",
  "user.credits.swissbill.btn.print": "Imprimir",
  "user.credits.swissbill.data.headline": "Detalles de la transferencia",
  "user.credits.swissbill.data.kontonummer": "Número de cuenta:",
  "user.credits.swissbill.data.additionalInformation": "Información adicional:",
  "user.credits.swissbill.data.refnr": "Árbitro. Nº:",
  "user.credits.swissbill.data.amount": "Cantidad",
  "user.credits.swissbill.data.text": "Tan pronto como hayamos recibido el pago, abonaremos el importe en su cuenta.",
  "user.credits.swissbill.data.kontoinhaber": "Propietario de la cuenta:",
  "user.credits.swissbill.data.bankkonto": "Cuenta bancaria:",
  "user.credits.swissbill.data.bank": "Banco",
  "user.credits.swissbill.data.subject": "Transferir asunto:",
  "user.credits.table.headerTitle": "Actas",
  "user.credits.table.id": "ID de transacción",
  "user.credits.table.title": "Tipo",
  "user.credits.table.created": "Fecha",
  'user.credits.form.gtc': 'Confirmo que he leído las condiciones y las acepto. También confirmo que soy mayor de 18 años.', // template
  'user.credits.form.gtc.link': 'Enlace a nuestro GTC', // template

  'user.credits.form.privacy': 'Confirmo que he leído y acepto la política de privacidad.', // template
  'user.credits.form.privacy.link': 'Enlace a nuestra política de privacidad', // template

  'user.credits.address.modal.title': 'Dirección de Envio', // template
  'user.credits.address.modal.field_firstname': 'Nome di battesimo', // template
  'user.credits.address.modal.field_lastname': 'Cognome', // template
  'user.credits.address.modal.field_street': 'Calle', // template
  'user.credits.address.modal.field_zip': 'Cremallera', // template
  'user.credits.address.modal.field_city': 'Ciudad', // template

  "user.settings.page.title": "Ajustes",
  "user.settings.field_web_push_subscriptions.title": "Notificaciones push",
  "user.settings.field_web_push_subscriptions.description": "¿Le gustaría ser informado directamente a través de notificaciones push?",
  "user.settings.field_allow_notifications.title": "Mensajes",
  "user.settings.field_allow_notifications.description": "¿Quieres estar informado de todos los cambios?",
  "user.settings.field_newsletter_subscription.title": "¿Le gustaría recibir un boletín de noticias?",
  "user.settings.password.success": "Tu contraseña ha sido cambiada.",
  "user.settings.user.success": "Tus datos han sido modificados.",
  "user.settings.user.divider": "Usuario",
  "user.settings.field_phone.tooltip": "Para números internacionales, incluya el código de país (+xx).",
  "user.settings.field_user_notification_type.label": "Notificar por",
  "user.settings.password.divider": "Cambiar la contraseña",
  "user.settings.current_password": "Contraseña actual",
  "user.settings.user.tabs.user.title": "Información personal",

  "user.profile.form.success": "Tu perfil ha sido guardado.",
  "user.profile.page.title": "Administrador de perfiles",


  "user.profile.vacation.modal.title": "Informa a tus clientes de tu ausencia",
  "user.profile.vacation.modal.text": "Mantén informados a tus visitantes indicándoles cuánto tiempo estarás fuera. Su anuncio inactivo permanecerá visible en nuestro portal durante 14 días más, pero las imágenes y los datos de contacto estarán ocultos.",
  "user.profile.vacation.modal.success": "Tus vacaciones han sido guardadas.",
  "user.profile.vacation.modal.field_vacations.label": "Vacaciones actuales:",
  "user.profile.vacation.modal.btn.delete": "Eliminar vacaciones",
  "user.profile.upvote.modal.title": "Ejecute upvotes automáticamente para 5 veces más clics",
  "user.profile.upvote.modal.success": "Se ha guardado su programación de votos a favor.",
  "user.profile.upvote.modal.text": "Configure hasta cinco veces al día cuando su anuncio se promocionará a la primera posición (5 créditos por voto a favor).",
  "user.profile.upvote.modal.field_upvote_scheduler": "Agregar tiempo",
  "user.profile.upvote.modal.hour": "Hora",
  "user.profile.upvote.modal.hour.rule.01": "Solo números (00-23).",
  "user.profile.upvote.modal.hour.rule.02": "Mínimo 2 caracteres",
  "user.profile.upvote.modal.minute.rule.01": "Solo números (00-59).",
  "user.profile.upvote.modal.minute.rule.02": "Mínimo 2 caracteres",
  "user.profile.upvote.modal.minute": "Minuto",
  "user.profile.premium.modal.title": "Actualización premium por 30 créditos.",
  "user.profile.premium.modal.success": "Su actualización premium ha sido completada.",
  "user.profile.premium.modal.field_valid_range": "Fecha",
  "user.profile.premium.modal.empty_slots": "No tenemos actualizaciones premium gratuitas disponibles para esta fecha.",
  "user.profile.support.btn.close": "marcar como hecho",
  "user.profile.support.subject": "Sujeto",
  "user.profile.support.comment_body": "Mensaje",
  "user.profile.story.modal.add.image": "Crear historia de imagen",
  "user.profile.story.modal.add.video": "Crear video historia",
  "user.profile.story.modal.form.success": "Tu historia ha sido creada.",
  "user.profile.story.modal.video.spinner": "Actualmente estamos preparando tu video. Por favor sea paciente.",
  "user.profile.story.modal.list.divider": "Mis historias",
  "user.profile.story.modal.list.delete": "Borrar",
  "user.profile.story.modal.list.renew": "Renovar",
  "user.profile.story.modal.list.field_approved": "Aprobado",
  "user.profile.story.modal.list.field_review": "Revisar",
  "user.profile.story.modal.list.field_expired": "Venció",
  "user.profile.secreetgallery.modal.field_review": "Revisar",
  "user.profile.secreetgallery.modal.field_approved": "Aprobado",
  "user.profile.secreetgallery.modal.text": "Cargue imágenes únicas y configure un código PIN individual para otorgar acceso a su Galería secreta solo a los visitantes seleccionados de su elección.",
  "user.profile.secreetgallery.modal.success": "Tu SecretGallery ha sido guardada",
  "user.profile.secreetgallery.modal.btn.delete": "Borrar",
  "user.profile.renew.modal.title": "Renovar perfil",
  "user.profile.renew.modal.success": "Tu perfil ha sido renovado",
  "user.profile.renew.modal.field_start_type": "Mostrar perfil",
  "user.profile.renew.modal.field_start_type.now": "Ahora",
  "user.profile.renew.modal.field_start_type.date": "en la fecha",
  "user.profile.renew.modal.field_valid_range": "Fecha y hora",
  "user.profile.list.upvote.add": "¿Reservar voto positivo por 5 créditos?",
  "user.profile.list.upvote.success": "Su voto a favor ha sido establecido.",
  "user.profile.list.clone.success": "Tu perfil ha sido duplicado",
  "user.profile.list.field_all": "Todo",
  "user.profile.list.field_active": "Activo",
  "user.profile.list.field_planned": "Planificado",
  "user.profile.list.field_expired": "Venció",
  "user.profile.list.field_review": "Revisar",
  "user.profile.list.field_decline": "Rechazado",
  "user.profile.list.field_deleted": "Eliminado",
  "user.profile.list.field_draft": "Borrador",
  "user.profile.list.menu.show": "Espectáculo",
  "user.profile.list.menu.edit": "Editar",
  "user.profile.list.menu.clone": "Clon",
  "user.profile.list.menu.delete": "Borrar",
  "user.profile.list.menu.renew": "Renovar",
  "user.profile.list.menu.autorenew.enable": "Habilitar renovación automática",
  "user.profile.list.menu.autorenew.disable": "Deshabilitar la renovación automática",
  "user.profile.list.menu.upvote.add": "Libro",
  "user.profile.list.menu.upvote.schedule": "Cronograma",
  "user.profile.list.menu.vacation": "Ausencia",
  "user.profile.list.menu.secret_gallery": "galería secreta",
  "user.profile.list.menu.regionRubric": "Regiones y rúbricas",
  "user.profile.list.status.field_review": "Revisar",
  "user.profile.list.status.field_approved": "Aprobado",
  "user.profile.list.status.field_auto_renew": "Auto renovación",
  "user.profile.list.status.field_decline": "Rechazado",
  "user.profile.list.status.field_expired": "Venció",
  "user.profile.list.status.field_top_profile": "Actualización prémium",
  "user.profile.list.status.field_deleted": "Eliminado",
  "user.profile.list.status.field_review_support": "ticket de soporte",
  "user.profile.list.status.field_vip": "VIP",
  "user.profile.edit": "Editar perfil",
  "user.profile.create": "Crear perfil",
  "user.profile.drawer.title": "Administrador de perfiles",
  "user.profile.edit.field_age_verification_images.divider": "Verificación de edad",
  'user.profile.edit.image_verification_images.divider': 'Verificación de imágenes', // template
  "user.profile.edit.field_age_verification_images.strong": "Se requieren tres imágenes para la verificación:",
  "user.profile.edit.field_age_verification_images.text01.title": "Documentos oficiales",
  "user.profile.edit.field_age_verification_images.text01.text": "Foto bien iluminada de su documento oficial (licencia de conducir, pasaporte o cédula de identidad)",
  "user.profile.edit.field_age_verification_images.text02.title": "Tu foto con DNI",
  "user.profile.edit.field_age_verification_images.text02.text": "Una foto suya con el pasaporte o el documento nacional de identidad cerca de la cara (el mismo documento utilizado para la foto anterior): tanto la cara como los datos del documento deben ser visibles.",
  "user.profile.edit.field_age_verification_images.text03.title": "Imagen de confirmación",
  "user.profile.edit.field_age_verification_images.text03.text": "Una foto contigo y un papel con la fecha de hoy y las palabras \"escort.ch\".",
  "user.profile.edit.field_cover.divider": "Imagen de portada",
  "user.profile.edit.field_cover.alert": "Seleccione la imagen que mejor represente su perfil.",
  'user.profile.edit.field_cover.private': 'Mostrar como foto secreta (aparecerá borrosa para los visitantes)', // template
  "user.profile.edit.field_gallery.divider": "Galería",
  "user.profile.edit.field_gallery.alert": "Puedes subir hasta 9 fotos. Para más privacidad, utiliza la galería privada.",
  'user.profile.edit.field_gallery.private': 'Galería privada', // template
  'user.profile.edit.field_gallery.public': 'Galería épublic', // template
  "user.profile.edit.field_video.divider": "Video",
  "user.profile.edit.field_video.alert": "Los videos brindan a los clientes una mejor perspectiva de sus servicios. ¡Muéstrate con la mejor luz!",
  "user.profile.edit.field_video.encoding.alert": "Actualmente estamos preparando tu video. Vuelve mas tarde.",
  "user.profile.edit.field_video.btn.delete": "Eliminar vídeo",
  "user.profile.edit.field_video.btn.help": "Consejos técnicos",
  "user.profile.edit.field_video.help.text01": "Asegúrate de tener buena luz para el video.",
  "user.profile.edit.field_video.help.text02": "El video no debe tener más de 100 MB.",
  "user.profile.edit.field_video.help.text03": "Todos los formatos comunes son compatibles",
  "user.profile.edit.field_video.help.text04": "La duración máxima del video es de 60 segundos.",
  "user.profile.form.address.placeholder": "Ingrese la calle y el número para buscar y complete la dirección automáticamente",
  "user.profile.form.address.button.delete": "Borrar dirección", 
  "user.profile.form.costs.spinner": "Calculando costos...",
  "user.profile.form.step.category_location": "Regiones y rúbricas",
  "user.profile.form.step.field_profile_type.divider": "Duración",
  "user.profile.form.field_regions.divider": "Regiones",
  "user.profile.form.field_regions.alert": "Primera región gratis, región adicional +5 créditos.",
  "user.profile.form.field_rubrics.alert": "Primera rúbrica gratis, rúbrica adicional +5 créditos.",
  "user.profile.form.field_rubrics.divider": "Rúbricas",
  "user.profile.form.step.media.title": "Fotos y Videos",
  "user.profile.form.step.base.title": "Base",
  "user.profile.form.step.base.divider": "Información básica",
  "user.profile.form.step.base.alert": "Información básica de tu perfil.",
  "user.profile.form.field_auto_renew": "¿Quieres que tu perfil se renueve automáticamente?",
  "user.profile.form.field_name": "Nombre para mostrar",
  "user.profile.form.title": "Título",
  "user.profile.form.field_text": "Texto",
  "user.profile.form.location.divider": "Tu ubicación",
  "user.profile.form.location.alert": "Su dirección se utilizará más tarde para la búsqueda de área.",
  "user.profile.form.location.input.label": "DIRECCIÓN",
  "user.profile.form.location.field_hide_address": "Ocultar Dirección",
  "user.profile.form.location.field_zip": "Cremallera",
  "user.profile.form.location.field_city": "Ciudad",
  "user.profile.form.location.field_country": "País",
  "user.profile.form.contact.divider": "Contacto",
  "user.profile.form.contact.alert": "como contactarte",
  "user.profile.form.contact.field_phone": "Teléfono",
  "user.profile.form.contact.field_contact_type": "Contacto vía",
  "user.profile.form.contact.field_website": "Sitio web",
  "user.profile.form.contact.field_instagram": "Instagram",
  "user.profile.form.contact.field_onlyfans": "solofans",
  "user.profile.form.contact.field_telegram": "Telegrama",
  "user.profile.form.steps.details.title": "Detalles",
  "user.profile.form.field_language.divider": "Idiomas",
  "user.profile.form.field_language.alert": "Elige los idiomas que hablas.",
  "user.profile.form.field_offers.divider": "Servicios",
  "user.profile.form.field_offers.alert": "Selecciona los servicios que ofreces.",
  "user.profile.form.field_offers.btn.selectall": "Seleccionar todo",
  "user.profile.form.field_offers.btn.selectnone": "Deseleccionar todo",
  "user.profile.form.field_body_features.divider": "Características del cuerpo",
  "user.profile.form.field_body_features.alert": "Si tiene otras características corporales, selecciónelas aquí.",
  "user.profile.form.field_gender.divider": "Información acerca de ti",
  "user.profile.form.field_gender.alert": "Usamos esta información para nuestros filtros.",
  "user.profile.form.field_age": "Edad",
  "user.profile.form.field_gender": "Género",
  "user.profile.form.field_cup_size": "tamaño de la copa",
  "user.profile.form.field_eye_color": "Color de los ojos",
  "user.profile.form.field_hair_color": "Color de pelo",
  "user.profile.form.field_hair_length": "Largo del pelo",
  "user.profile.form.field_height": "Altura",
  "user.profile.form.field_person_types": "Grupo étnico",
  "user.profile.form.field_physique": "Físico",
  "user.profile.form.field_shoe_size": "Mostrar tamaño",
  "user.profile.form.field_intimate_area": "zona intima",
  "user.profile.form.field_smoking": "De fumar",
  "user.profile.form.field_weight": "Peso",
  "user.profile.form.step.age_verification": "Verificación de edad",
  "user.profile.edit.field_video.thumbnail.spinnner": "Actualmente estamos preparando tu video. Puedes continuar editando tu perfil.",




  'status.message.alert.created': 'Su mensaje de estado está siendo comprobado.', // template
  'status.message.alert.deleted': 'Tu mensaje de estado ha sido borrado', // template
  'status.message.title': 'Mensajes de estado', // template
  'status.message.autorefresh': 'AutoRefresh', // template
  'status.message.label.profile': 'Perfil', // template
  'status.message.label.body': 'Su mensaje', // template
  'status.message.button.loadmore': 'Ver más', // template
  'status.message.banner.title': 'Los mensajes de estado se presentan', // template
  'profile.statusmessenger.header': 'Último mensaje de estado',


  'blog.category.label': 'Categorías de blogs', // template
  'modal.updater': 'Nueva actualización disponible.', // template
  'user.profile.renew.modal.additionalCosts': 'incl. Regiones y rúbricas', 
  'filterpagetags.title': 'Enlaces de interé', // template
  'filter.button.more': 'Mostrar más perfiles', // template 


  'blog.meta.title': 'Nuestro blog de aventuras eróticas - escort.ch', // template
  'blog.meta.description': '★★★ Nuestro blog para aventuras eróticas en tu zona. ♥ ¡Encuentra tu escort perfecta! escort.ch ▷ El nuevo portal de sexo y erotismo en Suiza.', // template

  'faq.meta.title': 'Respuestas a sus preguntas: FAQ exhaustivas', // template
  'faq.meta.description': '★★★ FAQ para aventuras eróticas en tu zona. ♥ ¡Encuentra a tu escort perfecta! escort.ch ▷ El nuevo portal de sexo y erotismo en Suiza.', // template


  'login.meta.title': 'Vamos Conéctate y sumérgete', // template
  'login.meta.description': '★★★ Tu inicio de sesión para aventuras eróticas en tu zona.♥ ¡Encuentra a tu escort perfecta! escort.ch ▷ El nuevo portal de sexo y erotismo en Suiza.', // template


  'support.meta.title': 'Centro de asistencia para más preguntas', // template
  'support.meta.description': '★★★ Nuestro apoyo para aventuras eróticas en tu zona. ♥ ¡Encuentra a tu escort perfecta! escort.ch ▷ El nuevo portal de sexo y erotismo en Suiza.', // template

  'pwainstaller.title': 'Instalar la aplicación escort.ch', // template
  'pwainstaller.description': 'La PWA (Aplicación Web Progresiva) de escort.ch ofrece numerosas ventajas que mejoran significativamente la experiencia en escort.ch. Uno de los principales beneficios es la mejor accesibilidad. Los usuarios pueden acceder a la aplicación fácilmente a través de su navegador, sin necesidad de descargar una app separada. Esto ahorra espacio de almacenamiento en el dispositivo y facilita el acceso.', // template


 };
