import { Tooltip } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllDTIcons from '@fortawesome/pro-duotone-svg-icons';

import { useIntl } from 'umi';

// You need to import the CSS only once
import './assets/css/index.css';

const VerifyIcon = () => {

  const intl = useIntl();

  return (
    <span className="verify-icon">
      <Tooltip title={intl.formatMessage({ id: 'verifyicon.tooltip', defaultMessage: 'Verifiziert' })}>
        <FontAwesomeIcon icon={AllDTIcons.faBadgeCheck} />
      </Tooltip>
    </span>
  );
};

export default VerifyIcon;
