import { List, Switch } from 'antd';
import React, { Fragment } from 'react';

const NotificationView = () => {
  const getData = () => {
    const Action = <Switch checkedChildren="Ja" unCheckedChildren="Nein" defaultChecked />;
    return [
      {
        title: 'Notify on expire',
        description: 'Notify on expire',
        actions: [Action],
      },
      {
        title: 'Notify on approve',
        description: 'Notify on approve',
        actions: [Action],
      },
      {
        title: 'Notify on decline',
        description: 'Notify on decline',
        actions: [Action],
      },
    ];
  };



const requestPermission = () => {
  if (!('Notification' in window)) {
    alert('Notification API not supported!');
    return;
  }

  Notification.requestPermission(function (result) {
    console.log(result);
    //$status.innerText = result;

    let wakeLockObj = null;
    if ("keepAwake" in screen) {
        screen.keepAwake = !screen.keepAwake;
       console.log(screen)
     } else if ("wakeLock" in navigator) {

       if (wakeLockObj) {
         wakeLockObj.release();
         wakeLockObj = null;
          console.log('released')
       } else {

         navigator.wakeLock.request('screen')
           .then((wakeLock) => {
             wakeLockObj = wakeLock;

             wakeLockObj.addEventListener('release', () => {
               wakeLockObj = null;
             })

           })
           .catch((err) => {
             //printStatus('failed to acquire: ' + err.message);
           })
       }
     }

  });
}

const nonPersistentNotification= () => {
  if (!('Notification' in window)) {
    alert('Notification API not supported!');
    return;
  }

  try {
    var notification = new Notification("Hi there - non-persistent!");
  } catch (err) {
    alert('Notification API error: ' + err);
  }
}

const persistentNotification= () => {
  if (!('Notification' in window) || !('ServiceWorkerRegistration' in window)) {
    alert('Persistent Notification API not supported!');
    return;
  }

  try {
    navigator.serviceWorker.getRegistration()
      .then((reg) => reg.showNotification("Hi there - persistent!"))
      .catch((err) => alert('Service Worker registration error: ' + err));
  } catch (err) {
    alert('Notification API error: ' + err);
  }
}



  const data = getData();
  return (
    <Fragment>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item actions={item.actions}>
            <List.Item.Meta title={item.title} description={item.description} />
          </List.Item>
        )}
      />


      <p>Current permission status is <b id="status">unavailable</b>.</p>

  <p><button onClick={requestPermission}>Request permission</button></p>
  <p><button onClick={nonPersistentNotification}>Non-persistent notification</button></p>
  <p><button onClick={persistentNotification}>Persistent notification</button></p>

    </Fragment>
  );
};

export default NotificationView;
