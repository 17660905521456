import request, { extend } from 'umi-request';
import { useIntl, history, IntlProvider, useModel } from 'umi';
import { Alert, message, Tabs } from 'antd';
import { logout } from '@/services/ant-design-pro/api';
import Helper from '@/helper/Helper'

// Same as the last one
request.interceptors.request.use(
  (url, options) => {




    let currentLang = localStorage.getItem('umi_locale');
    if(currentLang) {
      currentLang = currentLang.split('-');
      currentLang = currentLang[0];
    } else {
      currentLang = 'de';
    }
    
    let currentUrl = url.split('/');

    if(currentLang != 'de') {
      currentUrl[0] = currentUrl[1];
      currentUrl[1] = currentLang;
    } else {
      currentUrl.shift();
    }

    let newUrl = currentUrl.join('/');
    newUrl = '/' + newUrl;

    const accessToken = Helper.getAccessToken();

    const isUserLogin = url.includes("/user/login");
    const isPasswordLost = url.includes("/user/lost-password");
    const isRegister = url.includes("/api/rest/esc/register/create");

    let authHeader = {};

    if(accessToken) {
      if(!isUserLogin && !isPasswordLost && !isRegister) {
        authHeader = { Authorization: 'Bearer ' + accessToken };
      }
    }
    



    
    
    if(options['data']) {
      options['data']['timezone'] =  Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    if(options['params']) {
      options['params']['timezone'] =  Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
   

    //console.log(options);

    

    return {
      url: newUrl,
      options: { ...options, interceptors: true, headers: authHeader }
    };
  },
  { global: true }
);




request.interceptors.response.use(async (response) => {

  // checkMaintance
  //let maintenanceMode = await checkMaintance();
  //let maintenanceMode = await checkMaintance();


  if(response.status === 403) {
    history.push('/user/logout');
    return null;
  }

  if(response.status === 503) {
    history.push('/503');
    //return null;
  }

  const data = await response.clone().json();


  if(!data.success && data.message) {
    message.error(Helper.translateString(data.message));
  }

  return response;
})
