import { useState } from 'react';

export default () => {
  const [socket, setSocket] = useState(false);

  return { 
    socket, 
    setSocket
   };
};
