
import { Button, Badge, Tooltip, List, } from 'antd';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllDTIcons from '@fortawesome/pro-duotone-svg-icons';
import * as AllLIcons from '@fortawesome/pro-light-svg-icons';


import { useModel, useIntl } from 'umi';



// You need to import the CSS only once
import '@/components/StatusMessenger/assets/css/index.css';

const StatusMessangerButton = (props) => {


  const intl = useIntl();


  const { isOpen, setIsOpen } = useModel('statusMessage', (ret) => ({
    isOpen: ret.isOpen,
    setIsOpen: ret.setIsOpen
  }));


  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };




  return (
    <div className="status-messenger-container">


      {props.showTextLink &&
        <Button size={'large'} text onClick={() => toggleDrawer()}>
          <Badge dot>
            <FontAwesomeIcon icon={AllDTIcons.faCommentAltText} />
          </Badge>
        </Button>
      }

      {props.showButton &&
        <span>
          <Tooltip title={intl.formatMessage({ id: 'status.message.title', defaultMessage: 'Status Nachrichten' })}>
            <Button type="primary" className="btn-yellow featured-button-pulse-small-yellow" onClick={() => toggleDrawer()}><FontAwesomeIcon icon={AllLIcons.faCommentAltText} /></Button>
          </Tooltip>
        </span>
      }

      {props.showFloatingButton &&
        <span style={{ position: 'fixed', bottom: '1rem', right: '-0.25rem', zIndex: '10' }}>
          <Button size={'large'} type="primary" className="btn-yellow featured-button-pulse-small-yellow" onClick={() => toggleDrawer()}>
            <FontAwesomeIcon className="mr-2" icon={AllLIcons.faCommentAltText} /> {intl.formatMessage({ id: 'status.message.title', defaultMessage: 'Status Nachrichten' })}
          </Button>
        </span>
      }

      {props.showButtonWithLabel &&
        <span>
          <Button type="primary" className="btn-yellow featured-button-pulse-small-yellow btn-login" onClick={() => toggleDrawer()}>
            <FontAwesomeIcon icon={AllLIcons.faCommentAltText} />
            {intl.formatMessage({ id: 'status.message.title.small', defaultMessage: 'Status' })}
          </Button>
        </span>
      }

    </div>
  );
};

export default StatusMessangerButton;