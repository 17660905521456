import { Component } from 'react';
import countriesJson from 'country-region-data/data.json';
import Logger from '@/helper/Logger';
import Storage from '@/helper/Storage';
import { injectIntl, history } from 'umi';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween)




import { EncryptStorage } from 'encrypt-storage';
import { getNotification, loadTerms } from '@/services/ant-design-pro/api';

import { message } from 'antd';
import de from '@/locales/de-DE';
import en from '@/locales/en-US';
import fr from '@/locales/fr-FR';
import it from '@/locales/it-IT';
import es from '@/locales/es-ES';


class Helper extends Component {
  constructor(props) {
    super(props);
  }

  static setItem(key, value) {
    Storage.setItem(key, value);
  }

  static getItem(key) {
    return Storage.getItem(key);
  }

  static removeItem(key) {
    return Storage.removeItem(key);
  }


  static setSessionStorageItem(key, value) {
    sessionStorage.setItem(key, value);
  }

  static getSessionStorageItem(key) {
    return sessionStorage.getItem(key);
  }

  static removeSessionStorageItem(key) {
    return sessionStorage.removeItem(key);
  }


  static setCookie(c_name, value, exdays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
    document.cookie = c_name + "=" + c_value;
  }

  static getCookie(c_name) {
    var i, x, y, ARRcookies = document.cookie.split(";");
    for (i = 0; i < ARRcookies.length; i++) {
        x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
        y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
        x = x.replace(/^\s+|\s+$/g, "");
        if (x == c_name) {
            return unescape(y);
        }
    }
  }




  static clearLocalStorage() {
    let currentLang = localStorage.getItem('umi_locale');
    localStorage.clear();
    //EncryptStorage.clear();
    localStorage.setItem('umi_locale', currentLang);
  }

  static getCountries() {

    let countriesJsonArray;

    const regionNames = new Intl.DisplayNames(
      [Helper.getCurrentLangShort()], { type: 'region' }
    );

    countriesJsonArray = countriesJson.map(item => {
      const obj = Object.assign({}, item);
      obj['countryName'] = regionNames.of(item.countryShortCode);
      return obj;
    });


    const countriesJsonNew = countriesJsonArray.map(({
      countryName: label,
      countryShortCode: value,
      ...rest
    }) => ({
      label,
      value,
      ...rest
    }));

    Logger.logAction(countriesJsonNew, 'getCountries', 'info', '');

    return countriesJsonNew;

  }

  static getCountryByValue(value) {
    const country = Helper.getCountries().find((obj) => {
      return obj.value === value;
    });
    return (country) ? country['label'] : '';
  }

  static getCurrentLangShort() {
    let currentLang = localStorage.getItem('umi_locale');
    if (!currentLang) {
      currentLang = 'de-DE';
    }
    currentLang = currentLang.split('-');
    return currentLang[0];
  }

  static getCurrentLang() {
    let currentLang = localStorage.getItem('umi_locale');
    if (!currentLang) {
      currentLang = 'de-DE';
    }
    return currentLang;
  }

  static translateString(str) {

    const localeInfo = {
      'de-DE': {
        messages: {
          ...de
        },
        locale: 'de-DE',
      },
      'en-US': {
        messages: {
          ...en
        },
        locale: 'en-US',
      },
      'it-IT': {
        messages: {
          ...it
        },
        locale: 'it-IT',
      },
      'fr-FR': {
        messages: {
          ...fr
        },
        locale: 'fr-FR',
      },
      'es-ES': {
        messages: {
          ...es
        },
        locale: 'es-ES',
      }
    };

    let translatedMessage = str;


    let dateRegexG = /[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}/g;
    let dateFound = translatedMessage.match(dateRegexG);
    if (dateFound) {
      translatedMessage = translatedMessage.replace(dateFound[0], '{date}');
    }

    let messages = {
      'The recovery password is not valid.': 'Der Aktivierungscode ist ungültig.',
      'This User was not found or invalid': 'Der Benutzer ist ungültig.',
      'Sorry, unrecognized username or password.': 'Der Benutzername oder das Passwort sind nicht korrekt.',
      'The user has not been activated or is blocked.': 'Der Benutzer wurde nicht aktiviert oder ist gesperrt.'
    };

    if (messages.hasOwnProperty(translatedMessage)) {
      translatedMessage = messages[translatedMessage];

      if (Helper.getCurrentLang() == 'de-DE') {
        return translatedMessage;
      }
    }


    if (Helper.getCurrentLang() == 'de-DE') {
      return str;
    }

    let translatedMessageId = window.btoa(unescape(encodeURIComponent(translatedMessage)));
    //////console.log(translatedMessageId);
    //////console.log(str);

    translatedMessage = (localeInfo[Helper.getCurrentLang()] && localeInfo[Helper.getCurrentLang()].messages[translatedMessageId]) ? localeInfo[Helper.getCurrentLang()].messages[translatedMessageId] : translatedMessage;

    return translatedMessage;
  }

  static getLogoutToken() {
    const user = Storage.getItem('user');
    if (user) {
      return user.logout_token;
    }
  }




  static getCurrentUser() {
    const user = Storage.getItem('user');
    if (user) {
      return user.current_user;
    } else {
      return false;
    }
  }


  static getUserDetail() {
    const userDetail = Storage.getItem('userDetail');
    if (userDetail) {
      return userDetail;
    }
  }


  static getUser() {
    const user = Storage.getItem('user');
    if (user) {
      return user;
    }
  }


  static getAccessToken() {
    const user = Storage.getItem('user');
    if (user) {
      return user.access_token;
    }
  }


  static prepareTermSelectOptions(object) {
    let newObject = object.map(({
      name: label,
      ...rest
    }) => ({
      label,
      ...rest
    }));

    newObject = newObject.map(({
      id: value,
      ...rest
    }) => ({
      value,
      ...rest
    }));

    Object.keys(newObject).forEach(function (el) {
      newObject[el].value = parseInt(newObject[el].value)
    })

    return newObject;
  }


  static prepareEnumSelectOptions(object) {
    let newObject = object.map(({
      name: text,
      ...rest
    }) => ({
      text,
      ...rest
    }));

    newObject = newObject.map(({
      id: value,
      ...rest
    }) => ({
      value,
      ...rest
    }));

    Object.keys(newObject).forEach(function (el) {
      newObject[el].value = parseInt(newObject[el].value)
    })

    return newObject;
  }



  static beforeFileUpload = (file) => {
    const isVideoOrImage = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'video/mp4' || file.type === 'video/quicktime' || file.type === 'image/webp' || file.type === 'image/gif';
    if (!isVideoOrImage) {
      message.error(Helper.translateString('You can only upload Video or Image file!'));
    }
    const isLt2M = file.size / 1024 / 1024 < 100;
    if (!isLt2M) {
      message.error(Helper.translateString('File must smaller than 100MB!'));
    }
    return isVideoOrImage && isLt2M;
  };

  static beforeImageUpload = (file) => {


    //////console.log(file);
    const isImageFile = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/webp' || file.type === 'image/gif';
    if (!isImageFile) {
      message.error(Helper.translateString('You can only upload JPG/PNG file!'));
      return false;
    }


    let fileSize = Helper.humanFileSize(file.size);
    ////console.log(fileSize);

    
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error(Helper.translateString('Image must smaller than 20MB!'));
      return false;
    }
    return isImageFile && isLt2M;
  };


  static humanFileSize(size) {
    let i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }

  



  static beforeVideoUpload = (file) => {

      ////console.log('beforeVideoUpload');

    const isVideoFile = file.type === 'video/mp4' || file.type === 'video/quicktime' || file.type === 'video/avi' || file.type === 'video/webm';
    if (!isVideoFile) {
      message.error(Helper.translateString('You can only upload MP4 or QT file!'));
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 100;
    if (!isLt2M) {
      message.error(Helper.translateString('Video must be smaller than 100MB!'));
      return false;
    }

   return isVideoFile && isLt2M;



    /*
    const videoDurationLimit = 60;

    return Helper.getVideoDuration(file).then((duration) => {
      ////console.log(duration);
      if(duration > videoDurationLimit) {
        message.error(Helper.translateString('Video must be shorter than 60s!'));
      }
      return (duration < videoDurationLimit);
    })*/

  };


  static getVideoDuration = (file) => {
    const video = document.createElement('video')
    video.setAttribute("id", "video-uload-meta-data");
    video.preload = 'metadata'
    video.src = window.URL.createObjectURL(file)
    
      return new Promise((resolve) => {
      video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src)
          resolve(video.duration)
          video.remove()
        }
      })
    
      
  }

  static getBase64File = (file, callback) => {
    //////console.log(file);
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  static getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }


  static convertToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      cb(null, e.target.result)
    };
    reader.onerror = function (e) {
      cb(e);
    };
    reader.readAsDataURL(file);
  }


  static resizeImage = async (base64Str) => {

    let img = document.createElement("img");
                img.src = base64Str;


                await Helper.waitTime(250);
 
                let canvas = document.createElement("canvas");
                let ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
 
                let MAX_WIDTH = 2000;
                let MAX_HEIGHT = 2000;
                let width = img.width;
                let height = img.height;
 
                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                let ctx1 = canvas.getContext("2d");
                ctx1.drawImage(img, 0, 0, width, height);

               await Helper.waitTime(250);
 
                let dataUrl = canvas.toDataURL();
                ////console.log(dataUrl);
                return dataUrl;

  }




  static urlSafeString = (string) => {


    cleanString = string;
    cleanString = cleanString.toLowerCase();

    let cleanString = cleanString.replace(/[^a-zA-Z0-9\s]/g, '');
    cleanString = cleanString.replace(/!\s+!/g, '-');
    cleanString = cleanString.replace(/\s/g, '-')
    

    return cleanString;

    return encodeURIComponent(string);


    /*
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text*/
  }


  static initBalanceAndMessages = async (user, setNotifications, notifications, setBalance, balance) => {
    let unread = await getNotification({ action: 'countUnreadAction' }, {});

    if (unread && user) {
      setNotifications(unread.unread);
      setBalance((user) ? Helper.renderBlankValue(user.field_credit_balance) : 0);
    }

  }


  static getProfileState = (profile) => {



    if (!profile) {
      return 'new';
    }

    if (Helper.renderBlankValue(profile.field_deleted) == 'true') {
      return 'deleted';
    }

    if (Helper.renderBlankValue(profile.field_decline) == 'true') {
      return 'decline';
    }

    if (Helper.renderBlankValue(profile.field_review) == 'true') {
      return 'review';
    }

    if (dayjs().isBetween(Helper.renderStartDateValue(profile.field_valid_range), Helper.renderEndDateValue(profile.field_valid_range))) {
      return 'active';
    }

    if (dayjs(Helper.renderStartDateValue(profile.field_valid_range)).isAfter()) {
      return 'planned';
    }

    if (!Helper.renderStartDateValue(profile.field_valid_range)) {
      return 'new';
    }

    if (dayjs(Helper.renderEndDateValue(profile.field_valid_range)).isBefore()) {
      //////console.log(Helper.renderEndDateValue(profile.field_valid_range));
      return 'expired';
    }
  }



  static waitTime = (time = 100) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  };


  static renderTextField(field) {

    let fieldValue = false;

    if (!field) {
      return fieldValue;
    }

    fieldValue = field.map((item, i) => {
      return (
        <span className="value">
          {item.value}
        </span>
      );
    })

    return fieldValue;
  }

  static renderBlankValue(field) {


    let fieldValue = false;

    if (!field || field.length === 0) {
      return fieldValue;
    }

    fieldValue = field.map((item, i) => {

      if (item.value) {
        return item.value.toString()
      }

      if (item.url) {
        return item.url.toString()
      }

      if (item.target_id) {
        return item.target_id.toString()
      }

      if (item.preloader) {
        return item.preloader.toString()
      }

    })

    return fieldValue[0];
  }


  static renderPlaceholderImageSrc(field) {
    let fieldValue = false;

    if (!field) {
      return fieldValue;
    }

    fieldValue = field.map((item, i) => {
      if (item.entity && item.entity.field_image) {
        return item.entity.field_image.map((image, j) => {
          if (image.preloader) {
            return image.preloader;
          }
        })
      }
    })

    return fieldValue;
  }

  static renderImageFieldSrc(field) {
    let fieldValue = false;

    if (!field) {
      return fieldValue;
    }

    fieldValue = field.map((item, i) => {
      if (item.entity && item.entity.field_image) {
        return item.entity.field_image.map((image, j) => {
          if (image.url) {
            return image.url;
          }
        })
      }
    })

    return fieldValue;
  }


  static renderVideoFieldSrc(field) {
    let fieldValue = false;

    if (!field) {
      return fieldValue;
    }

    fieldValue = field.map((item, i) => {
      if (item.entity && item.entity.field_video) {
        return item.entity.field_video.map((image, j) => {
          if (image.url) {
            return image.url;
          }
        })
      }
    })

    return fieldValue;
  }


  static renderReferencedTaxonomyName(field) {

    let fieldValue = false;

    if (!field) {
      return fieldValue;
    }

    fieldValue = field.map((item, i) => {
      if (item.entity) {
        return item.entity.name.map((name, j) => {
          return name.value + ' ';
        })
      }
    })

    return fieldValue;
  }


  static renderReferencedTaxonomyDescription(field) {

    let fieldValue = false;

    if (!field) {
      return fieldValue;
    }

    fieldValue = field.map((item, i) => {
      if (item.entity) {
        return item.entity.description.map((description, j) => {
          return description.value;
        })
      }
    })

    return fieldValue;
  }


  static renderEndDateValue(field) {

    let fieldValue = false;

    if (!field) {
      return fieldValue;
    }

    fieldValue = field.map((item, i) => {

      if (item.end_value) {
        return item.end_value;
      }

    })

    //////console.log(fieldValue);

    return fieldValue[0];
  }

  static renderStartDateValue(field) {

    let fieldValue = false;

    if (!field) {
      return fieldValue;
    }

    fieldValue = field.map((item, i) => {

      if (item.value) {
        return item.value;
      }

    })

    //////console.log(fieldValue);

    return fieldValue[0];
  }



  static async checkboxSelectAll(form, field, vid) {

    let currentCheckedOptions = form.current.getFieldValue(field);
    let result = await loadTerms({ vid: vid }, {});

    let optionIds = result.data.map((item, i) => {
      return parseInt(item.id);
    });

    ////console.log(form, optionIds, currentCheckedOptions);
    form.current.setFieldValue(field, optionIds);
  }

  static async checkboxSelectNone(form, field) {
    form.current.setFieldValue(field, []);
  }

  static shuffleArray(array) {
    let shuffledArray = array.sort(function () {
      return Math.random() - 0.5;
    });


    return shuffledArray;
  }


  static generateEsr(data) {


    ////console.log(data);
    if (!data) {
      return;
    }

    function pad(value, length) {
      return (value.toString().length < length) ? pad("0" + value, length) : value;
    }

    function rev(value) {
      return value.split("").reverse().join("");
    }

    function split5(value) {
      return value.match(/.{1,5}/g).join(" ");
    }

    function modulo10(number) {
      number = String(number)
      var matrix = [[0, 9, 4, 6, 8, 2, 7, 1, 3, 5],
      [9, 4, 6, 8, 2, 7, 1, 3, 5, 0],
      [4, 6, 8, 2, 7, 1, 3, 5, 0, 9],
      [6, 8, 2, 7, 1, 3, 5, 0, 9, 4],
      [8, 2, 7, 1, 3, 5, 0, 9, 4, 6],
      [2, 7, 1, 3, 5, 0, 9, 4, 6, 8],
      [7, 1, 3, 5, 0, 9, 4, 6, 8, 2],
      [1, 3, 5, 0, 9, 4, 6, 8, 2, 7],
      [3, 5, 0, 9, 4, 6, 8, 2, 7, 1],
      [5, 0, 9, 4, 6, 8, 2, 7, 1, 3]];

      var carry = 0;
      for (var i = 0, len = number.length; i < len; i++) {
        carry = matrix[carry][number[i]];
      }
      return ((10 - carry) % 10);
    }

    function formatReferenzNummer(number) {
      number = referenzNummer(number);
      //hier ist noch ein 0 am schluss zu viel

      return rev(split5(rev(number)));
    }

    function referenzNummer(number) {
      number = pad(number, 26);
      number = number + modulo10(number);
      return number;
    }

    function codierzeileKontoNummer(number) {
      let parts = number.split(/-/);
      return parts[0] + pad(parts[1], 6) + parts[2];
    }



    let typ = 'esr';

    let referenznummern = data.nid[0].value;
    let betrag = data.field_price[0].value;
    let kontonummer = '15-801446-9';
    let outputReferenznummern = [];
    let outputCoderzeile = [];


    outputReferenznummern.push(formatReferenzNummer(referenznummern));
    let betragCodierzeile = "01" + pad(Math.round(parseFloat(betrag) * 100), 10);

    outputCoderzeile.push(betragCodierzeile + modulo10(betragCodierzeile) + ">" + referenzNummer(referenznummern) + "+ " + codierzeileKontoNummer(kontonummer) + ">");
    ////console.log(outputReferenznummern);
    return outputReferenznummern;

  }


  static normalizePhone(phone) {
    if (phone !== undefined) {
      return phone.replace('+', '');
    }
    //return phone.replace('+', '');
  }


  static isTouchEnable() {
    return ('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0);
  }

  static isProduction() {
    if(window.location.href.indexOf("dev") > -1 || window.location.href.indexOf("stage") > -1) {
      return false;
    }
    return true;
  }


  static getProfileLink(profile) {

    let profileLink = '/profile/' + Helper.urlSafeString(Helper.renderBlankValue(profile.title)) + '-' + Helper.renderBlankValue(profile.nid);
    profileLink = profileLink.replace("--", '-');

    return profileLink;
    //return '/profile/' + Helper.urlSafeString(Helper.renderBlankValue(profile.title)) + '?id=' + Helper.renderBlankValue(profile.nid);
  }


  static getFilterPageLink(entity) {

    let link = '/filter/' + Helper.urlSafeString(Helper.renderBlankValue(entity.title)) + '-' + Helper.renderBlankValue(entity.nid);
    link = link.replace("--", '-');

    return link;
    //return '/profile/' + Helper.urlSafeString(Helper.renderBlankValue(profile.title)) + '?id=' + Helper.renderBlankValue(profile.nid);
  }


  static getBlogPostLink(post) {
    return '/blog/post/' + Helper.urlSafeString(Helper.renderBlankValue(post.title)) + '-' + Helper.renderBlankValue(post.nid);
    //return '/profile/' + Helper.urlSafeString(Helper.renderBlankValue(profile.title)) + '?id=' + Helper.renderBlankValue(profile.nid);
  }

  static getRouteEntityId() {
    const urlParts = window.location.href.split('/');
    const urlAliasParts = urlParts[urlParts.length - 1].split('-');
    return urlAliasParts[urlAliasParts.length - 1];
  }


  static getUrlParts() {
    const urlParts = window.location.href.split('/');
    return urlParts;
  }

  static scrollToTop() {
    const rootElement = document.getElementById('scroll-anchor');
    rootElement.scrollIntoView({ behavior: "instant", block: "end", inline: "nearest" });
  }

  static scrollToTopSmooth() {
    const rootElement = document.getElementById('scroll-anchor');
    rootElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }

}

export default injectIntl(Helper);
