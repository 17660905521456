import { useState, useEffect } from 'react';
import { Slider, message } from 'antd';
import {
  ProFormText
} from '@ant-design/pro-components';
import { history, useModel, useIntl } from 'umi';


const Geolocation = (props) => {

  const intl = useIntl();

  const { filterState, setFilterState } = useModel('filter', (ret) => ({
    filterState: ret.filterState,
    setFilterState: ret.setFilterState,
  }));



  const onRadiusChange = (value) => {

    

    getCurrentLocation(value);
    //setRadius(value);
    props.queryFormRef.current.setFieldValue('field_radius', value);
    props.queryFormRef.current.setFieldValue('field_regions', false);
  };

  const getCurrentLocation = (radius) => {

    //console.log(props.queryFormRef);

    navigator.geolocation.getCurrentPosition(function (position) {



      if(props.queryFormRef.current) {
        setFilterState({
          ...filterState,
          field_radius:  radius,
          field_lat:  position.coords.latitude,
          field_lng:  position.coords.longitude,
          field_regions:  [],
          time: new Date().getTime()
        })

        
        props.queryFormRef.current.setFieldValue('field_regions', false);
      }
     

      //console.log("Latitude is :", position.coords.latitude);
      //console.log("Longitude is :", position.coords.longitude);

    },
      function (error) {
        if (error.code == error.PERMISSION_DENIED) {
          message.error(intl.formatMessage({ id: 'home.filter.geolocation.error', defaultMessage: 'Du musst deinem Browser den Standort-Zugriff erlauben um diese Funktion nutzen zu können' }));
        }
      });

  }

  return (
    <>


      <h4>{intl.formatMessage({ id: 'home.filter.geolocation.radius.label', defaultMessage: 'Umkreis' })}{' ('+filterState.field_radius+' km)'}</h4>
      <Slider min={10} max={50} defaultValue={filterState.field_radius} onAfterChange={onRadiusChange} />

      <div style={{ display: 'none' }}>
        <ProFormText
          width="md"
          name="field_radius"
          label={intl.formatMessage({ id: 'home.filter.geolocation.radius.label', defaultMessage: 'Umkreis' })}
          defaultValue={filterState.field_radius}
        />

        <ProFormText
          width="md"
          name="field_lng"
          label="field_lng"
        />

        <ProFormText
          width="md"
          name="field_lat"
          label="field_lat"
        />
      </div>

    </>
  );
};

export default Geolocation;
