import { Button, Modal, message } from 'antd';
import { useState, useRef } from 'react';
import { ProForm, ProFormCascader, ProFormDatePicker, ProFormDateRangePicker, ProFormDigit, ProFormList, ProFormMoney, ProFormSelect, ProFormText, ProFormTreeSelect, ProFormTextArea } from '@ant-design/pro-components';
import moment from 'dayjs';
import { createSupportTicket } from '@/services/ant-design-pro/api';


const waitTime = (time = 100) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};


const AddTicket = (props) => {

  const formRef = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button type="primary" onClick={showModal}>
        {'Support Ticket eröffnen'}
      </Button>
      <Modal style={{ top: 10 }} footer={false} title={'Support Ticket eröffnen'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

          <ProForm
            onFinish={async (values) => {
              let options = {};
              values['action'] = 'createTicket';
              let result = await createSupportTicket(values, options);

              if(result.success) {
                message.success('success');
                console.log(props);
                console.log(formRef);
                props.actionRef.current.reload();
                formRef.current.resetFields();
                handleCancel();
              }
            }}

            formRef={formRef}
            formKey="create-support-ticket-form"
            autoFocusFirstInput
          >
          <ProForm.Group>
            <ProFormText width="md" name="title" required  label="title" tooltip="title" placeholder="name" rules={[{ required: true, message: 'name' }]}/>
            <ProFormTextArea width="md" name="body" required label="body" placeholder="body"/>
          </ProForm.Group>
        </ProForm>


      </Modal>
    </>
  );
};
export default AddTicket;
