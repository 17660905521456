import { forwardRef, useImperativeHandle, useState } from 'react';

import { Modal } from 'antd';
import { useIntl } from 'umi';
import {  ExclamationCircleOutlined } from '@ant-design/icons';


// You need to import the CSS only once
import './assets/css/index.css';

const Updater = forwardRef((props, ref) => {


  const intl = useIntl();

  const [isModalOpen, setIsModalOpen] = useState(false);


  useImperativeHandle(ref, () => {
    return {
      onUpdate() {
        onUpdate();
      },
    };
  });

  const onUpdate = () => {

    if(isModalOpen) {
      return;
    }

    if(!isModalOpen) {
      setIsModalOpen(!isModalOpen);
    }

    Modal.confirm({
      title: intl.formatMessage({ id: 'modal.updater', defaultMessage: 'Neues Update verfügbar.' }),
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk: () => {
        window.location.replace('/?t='+new Date().getTime());
      },
      onCancel() {
        window.location.replace('/?t='+new Date().getTime());
      },
      
    });
  }


  return (
    <>
    
   

    </>
  );
});

export default Updater;
