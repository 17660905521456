import { createChatBotMessage } from 'react-chatbot-kit';

import Intro from './widgets/Intro';
import Region from './widgets/Region';
import Rubric from './widgets/Rubric';
import Result from './widgets/Result';


const botName = 'Cumcierge';


const initialMessages = createChatBotMessage(
  "Hallo! Ich bin der virtuelle Assistent von escort.ch. Wie kann ich dir helfen?",
  {
    widget: 'intro',
  }
);

const config = {
  initialMessages: [initialMessages],
  botName: botName,
  widgets: [
    {
      widgetName: 'intro',
      widgetFunc: (props) => <Intro {...props} />,
    },
    {
      widgetName: 'region',
      widgetFunc: (props) => <Region {...props} />,
    },
    {
      widgetName: 'rubric',
      widgetFunc: (props) => <Rubric {...props} />,
    },
    {
      widgetName: 'result',
      widgetFunc: (props) => <Result {...props} />,
    },
  ]
};



export default config;