import React from 'react';

import RightContent from '@/components/RightContent';
import Footer from '@/components/Footer';
import PageContext from '@/components/PageContext';
import Updater from '@/components/Updater';
import FilterContext from '@/components/FilterContext';
import { currentUser as queryCurrentUser } from './services/ant-design-pro/api';
import { ConfigProvider, notification, message } from 'antd';
import { Link } from "react-router-dom";


import { history, useModel } from 'umi';


import moment from 'moment-timezone';
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsTz from 'dayjs/plugin/timezone';

import de from 'dayjs/locale/de';
import en from 'dayjs/locale/en';
import es from 'dayjs/locale/es';
import it from 'dayjs/locale/it';
import fr from 'dayjs/locale/fr';


import Helper from '@/helper/Helper';

import AccessDenied from '@/pages/403';
import ReactGA from "react-ga4";

import '../config/interceptors.js';


import './assets/css/swiper-element-bundle.css';
import 'antd-css-utilities/utility.min.css'
import './assets/css/app.css';
import './assets/css/effects.css';
import './assets/css/responsive.css';
import './assets/css/loader.css';
import "video-react/dist/video-react.css"; // import css
import 'react-lazy-load-image-component/src/effects/blur.css';




if ('scrollRestoration' in window.history) {
  // Back off, browser, I got this...
  //window.history.scrollRestoration = 'manual';
}


let gaMeasurementID = Helper.isProduction() ? GA_MEASUREMENT_ID_PROD : GA_MEASUREMENT_ID_DEV;
ReactGA.initialize(gaMeasurementID);

// Track PWA user
if (window.matchMedia('(display-mode: standalone)').matches) {
  ReactGA.event({
    category: "pwa",
    action: "pwa_start",
  });
}

// Set CI style settings
ConfigProvider.config({
  theme: {
    primaryColor: '#34302e',
    linkColor: '#34302e',
  }
});

// Default notification settinge
notification.config({
  placement: 'topRight',
  duration: 3,
  maxCount: 3
});



message.config({
  duration: 3,
  maxCount: 3
});

// Set default date settings
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTz);

//moment.tz.setDefault("Europe/Zurich");
//dayjs.tz.setDefault("Europe/Zurich");

// Set default language settings
if (!localStorage.getItem('umi_locale')) {
  localStorage.setItem('umi_locale', 'de-DE');
  dayjs.locale('de');
  moment.locale('de');
} else {
  dayjs.locale(Helper.getCurrentLangShort());
  moment.locale(Helper.getCurrentLangShort());
}


// Track language
ReactGA.event({
  category: "language",
  action: "lang_" + Helper.getCurrentLangShort(),
});



export const initialStateConfig = {
  loading: true,
};

const pageContextRef = React.createRef();
const updaterRef = React.createRef();
const filterContextRef = React.createRef();



const _updateReady = (worker) => {
  if (updaterRef.current) {
    //updaterRef.current.onUpdate();
  }
}


const _trackInstalling = (worker) => {
  worker.addEventListener('statechange', function () {
    if (worker.state == 'installed') {
      if (updaterRef.current) {
        updaterRef.current.onUpdate();
      }
    }
  });
}


const registerSW = () => {
  if (navigator.serviceWorker) {

    navigator.serviceWorker.register("/service-worker.js").then(registration => {
      registration.update();

      registration.addEventListener('updatefound', function () {

        //_trackInstalling(registration.installing);
      });

      //registration.update();

      console.log("Service Worker Registered");


    });

    //navigator.serviceWorker.register("<%= context.config.publicPath +'service-worker.js'%>");
  }
}




export async function getInitialState() {

  const fetchUser = async () => {
    try {
      const msg = await queryCurrentUser();
      //console.log(msg);
      return msg;
    } catch (error) {
      //console.log(error);
      //history.push(loginPath);
    }
    return {};
  };

  const result = await fetchUser();
  //console.log(result);
  let currentUser, user;
  if (result.user) {
    currentUser = result.user;
  }

  user = Helper.getItem('user');

  return {
    fetchUser,
    user: user,
    currentUser: currentUser,
    unreadMessages: 0,
    settings: {},
  };
}

const consoleError = console.error.bind(console);
// eslint-disable-next-line
console.error = (message, ...args) => {
  if (
    typeof message === 'string' &&
    message.startsWith('[React Intl] Missing message:')
  ) {
    return;
  }
  consoleError(message, ...args);
};


const onMenuHeaderClick = async () => {
  filterContextRef.current.setFilterState();
  filterContextRef.current.setEscortListState();



  await Helper.waitTime(250);
  history.push('/?t=' + new Date().getTime());

  Helper.scrollToTop();


}

registerSW();


export const layout = ({ initialState }) => {


  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: true,
    onMenuHeaderClick: () => onMenuHeaderClick(),
    breadcrumbRender: false,
    footerRender: () => <><Footer /> <Updater ref={updaterRef} />  <PageContext ref={pageContextRef} key={new Date().getTime()} /> <FilterContext ref={filterContextRef} key={new Date().getTime()} /></>,
    onPageChange: (location) => {



      document.body.classList.remove('profile');

      let pathArray = window.location.pathname.split('/');

      //console.log(pathArray);

      pageContextRef.current.setPageContext(pathArray[1]);

      if (pathArray[1] === 'profile') {
        document.body.classList.add('profile');
      }

      //console.log(pageContextRef);
      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });


      /*
      const rootElement = document.getElementById('scroll-anchor');
      rootElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      */

      Helper.scrollToTop();

      //console.log('onPageChange');

      /*window.scrollTo({
        top: -100,
        left: 0,
        behavior: "smooth",
      })*/
    },

    menuHeaderRender: (logo, title) => {
      return (
        <>
          <Link to={'/'} className="logo">
            <img width="154" height="22" src={logo.props.src} alt="escort.ch" />
          </Link>
        </>
      )
    },
    // 403 Access denied
    unAccessible: <AccessDenied />,
    ...initialState?.settings,
  };
};
