import React, { useState, useEffect, useRef } from 'react';

import { Button, Modal, Comment, Tooltip, Avatar, Drawer, message, List, Spin, Divider, notification, Space, Checkbox } from 'antd';
import moment from 'dayjs';

import { getStatusMessages, getCreatorProfile, postStatusMessage, getProfile } from '@/services/ant-design-pro/api';


import Banner from '@/components/Banner';


import { ProForm, ProFormTextArea, ProFormSelect } from '@ant-design/pro-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllDTIcons from '@fortawesome/pro-duotone-svg-icons';


import { useModel, history, useIntl } from 'umi';


import InfiniteScroll from 'react-infinite-scroll-component';


import Helper from '@/helper/Helper';

import { ExclamationCircleOutlined } from '@ant-design/icons';



// You need to import the CSS only once
import '@/components/StatusMessenger/assets/css/index.css';

const StatusMessengerList = (props) => {


  let form = useRef();
  const intl = useIntl();


  const { socket } = useModel('socket', (ret) => ({
    socket: ret.socket,
  }));

  const { autoRefresh, setAutoRefresh, isOpen, setIsOpen } = useModel('statusMessage', (ret) => ({
    autoRefresh: ret.autoRefresh,
    setAutoRefresh: ret.setAutoRefresh,
    isOpen: ret.isOpen,
    setIsOpen: ret.setIsOpen
  }));

  const { initialState } = useModel('@@initialState');



  const [statusMessages, setStatusMessages] = useState([]);
  const [statusMessagesTotal, setStatusMessagesTotal] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState('');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);





  const toggleDrawer = () => {
    if (!isOpen) {
      reloadList();
    }
    setIsOpen(!isOpen);
  };

  const toggleAutoRefresh = (e) => {
    //console.log(e);
    setAutoRefresh(e.target.checked);
  }



  const handleProfileChange = (value) => {
    setSelectedProfile(value);
  };

  const handleSubmit = async (values) => {
    try {
      const postData = {
        action: 'createAction',
        field_profile: selectedProfile,
        body: values.body,
      };

      const response = await postStatusMessage(postData, postData);

      if (response.success) {
        

        if(response.showAlert) {
          message.success(intl.formatMessage({ id: 'status.message.alert.created', defaultMessage: 'Deine Status Nachricht wird geprüft.' }));
        } else {
          const profileWarmer = getProfile({ id: selectedProfile, action: 'readAction' }, {});
        }
        
     
      }
    } catch (error) {
      //console.log(error);
      //console.error('Error posting message:', error);
      //message.error('An error occurred while posting the message');
    }

    form.current.resetFields();
  };

  const handleDeleteMessage = async (nid) => {

    Modal.confirm({
      title: intl.formatMessage({ id: 'modal.sure', defaultMessage: 'Bist du sicher?' }),
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk: async () => {
        const postData = {
          action: 'deleteAction',
          nid: nid,
        };

        const response = await postStatusMessage(postData, postData);

        if (response.success) {
          message.success(intl.formatMessage({ id: 'status.message.alert.deleted', defaultMessage: 'Deine Status Nachricht wurde gelöscht' }));
          reloadList();
        }
      }
    });


  }

  const reloadList = async () => {
    setStatusMessages([]);
    setLoading(true);

    let params = {
      pageSize: 20,
      current: 0,
      action: 'listAction',
    };

    let result = await getStatusMessages(params, params);

    if (result.success) {
      setStatusMessages(result.data);
      setStatusMessagesTotal(result.statusMessagesIdsTotal);
      setPage(0);
    }
    setLoading(false);
  }

  const onStatusMessageListen = (e) => {
    if (e.callback === 'status_message') {

      if (autoRefresh) {
        reloadList();
      }


      var playPromise = new Audio('/audio/sound_notification.c06c391f.mp3').play();
      if (playPromise !== undefined) {
        playPromise.then(function () {
          // Automatic playback started!
        }).catch(function (error) {
          //console.log(error);
          // Automatic playback failed.
          // Show a UI element to let the user manually start playback.
        });
      }





      notification[e.data.type]({
        className: 'notication-with-avatar',
        icon: <Avatar size={'large'} src={e.data.additional.profile.field_cover[0].entity.field_image[0].url} />,
        message: e.data.subject,
        description: e.data.body,
        onClick: () => {
          setIsOpen(true);
        }
      });
    }
  }

  const viewProfileAction = (profile) => {
    history.push(Helper.getProfileLink(profile));
    setIsOpen(false);
  }

  const loadMoreStatusMessages = async () => {

    let currentPage = page;


    if (loading) {
      return;
    }

    setLoading(true);

    let params = {
      pageSize: 20,
      current: currentPage + 1,
      action: 'listAction',
    };

    let result = await getStatusMessages(params, params);

    if (result.success) {
      setStatusMessages([...statusMessages, ...result.data]);
      setStatusMessagesTotal(result.statusMessagesIdsTotal);
      setPage(page + 1);
    }

    setLoading(false);
  };



  useEffect(() => {
    // load statusMessages
    reloadList();
  }, [isOpen, initialState.user]);


  useEffect(() => {
    if (socket && socket._callbacks.$message) {
      socket._callbacks.$message.forEach((func, index) => {
        if (func.name === 'onStatusMessageListen') {
          socket._callbacks.$message.splice(index, 1);
        }
      });
      socket.on('message', onStatusMessageListen);
    }

  }, [socket, autoRefresh]);



  return (
    <div className="status-messenger-container">






      <Drawer
        className="mobile-drawer-fullheight"
        extra={
          <Space>
            <Checkbox className="status-message-auto-refresh-checkbox" onChange={(e) => { toggleAutoRefresh(e) }} checked={autoRefresh}>
              <span>{intl.formatMessage({ id: 'status.message.autorefresh', defaultMessage: 'AutoRefresh' })}</span>
            </Checkbox>
          </Space>
        }
        destroyOnClose
        width={500}
        title={intl.formatMessage({ id: 'status.message.title', defaultMessage: 'Status Nachrichten' })}
        placement={props.placement}
        onClose={toggleDrawer}
        open={isOpen}
      >





        {initialState.user &&
          <>

            <ProForm
              formRef={form}
              onFinish={handleSubmit}

            >
              <ProFormSelect
                name="selectedProfile"
                label={intl.formatMessage({ id: 'status.message.label.profile', defaultMessage: 'Profile' })}
                request={async () => {
                  let paramsArray = {
                    current: 0,
                    pageSize: 100,
                    action: 'listAction',
                    field_status: 'field_active',
                    uid: initialState.user.current_user.uid
                  }
                  const result = await getCreatorProfile(paramsArray, paramsArray);

                  return result.data.map((profile) => ({
                    label: profile.field_name[0].value,
                    value: profile.nid[0].value,
                  }));
                }}
                rules={[{ required: true, message: intl.formatMessage({ id: 'form.required', defaultMessage: 'Pflichtfeld' }), }]}
                onChange={handleProfileChange}
              />
              <ProFormTextArea
                fieldProps={{
                  maxLength: 150,
                  showCount: true
                }}
                name="body"
                label={intl.formatMessage({ id: 'status.message.label.body', defaultMessage: 'Deine Nachricht' })}
                rules={[{ required: true, message: intl.formatMessage({ id: 'form.required', defaultMessage: 'Pflichtfeld' }), }]}
              />
            </ProForm>

            <Divider /></>
        }




        <InfiniteScroll
          dataLength={statusMessages.length} //This is important field to render the next data
          scrollThreshold={1}
          next={loadMoreStatusMessages}
          hasMore={statusMessages.length < 50}
          endMessage={''}
          scrollableTarget="scrollableDiv"
        >

          {statusMessages.map(function (item, index) {
            let bannerIndex = index + 1;
            return (
              <div className={'status-message-comment'} key={'status-message-item-' + index} onClick={() => (initialState.user) ? null : viewProfileAction(item.field_profile)}>
                <Comment
                  actions={
                    (item.uid && initialState.user && initialState.user.current_user.uid == item.uid[0].target_id) ? [
                      <Button type="danger" size={'small'} icon={<FontAwesomeIcon className="mr-3" icon={AllDTIcons.faTrash} />} onClick={(e) => { handleDeleteMessage(item.nid[0].value) }}>
                        {intl.formatMessage({ id: 'user.banner.btn.delete', defaultMessage: 'löschen' })}
                      </Button>,
                    ] : null
                  }

                  author={item.field_profile.field_name[0].value}
                  avatar={<Avatar src={item.field_profile.field_cover[0].entity.field_image[0].url} />}
                  content={
                    <p>
                      {item.body[0].value.substring(0, 150)}
                    </p>
                  }
                  datetime={
                    <Tooltip title={item.body[0].created}>
                      <span>{moment(Helper.renderBlankValue(item.created)).fromNow()}</span>
                    </Tooltip>
                  }
                />

                {bannerIndex % 20 === 0 &&
                  <>
                  <Divider orientation="left">{intl.formatMessage({ id: 'status.message.banner.title', defaultMessage: 'Statusnachrichten werden präsentiert von' })}</Divider>
                      <Banner size={'large'} slidesPerView={2} />
                  </>
                }



                <Divider />
              </div>
            )
          })}
        </InfiniteScroll>

        {loading &&
          <div style={{ textAlign: 'center', padding: '1rem' }}><Spin /></div>
        }

        {statusMessages.length > 0 && statusMessages.length < 50 && statusMessagesTotal > statusMessages.length &&
          <Button block type="primary" onClick={loadMoreStatusMessages}>{intl.formatMessage({ id: 'status.message.button.loadmore', defaultMessage: 'Weitere anzeigen' })}</Button>
        }

      </Drawer>

    </div>
  );
};

export default StatusMessengerList;