import { useState, useEffect } from 'react';
import { Comment, Tooltip, Typography } from 'antd';
import moment from 'dayjs';
import { getNotification } from '@/services/ant-design-pro/api';
import { useModel } from 'umi';
import Helper from '@/helper/Helper';


const { Text } = Typography;

const NotificationListItem = (props) => {

  const [ellipsis, setEllipsis] = useState(true);
  const [className, setClassName] = useState();

  const { notifications, setNotifications } = useModel('notifications', (ret) => ({
    notifications: ret.notifications,
    setNotifications: ret.setNotifications,
  }));


  const readNotification = async () => {
    //&setEllipsis(!ellipsis)
    let result = await getNotification({ action: 'readAction', uuid: Helper.renderBlankValue(props.node.uuid) }, {});
    //console.log(result);
    setNotifications(result.unread);

    setClassName('');
  }


  const onMouseEnter = () => {
    //setEllipsis(false)
  }


  const onMouseLeave = () => {
    //setEllipsis(true)
  }


  useEffect(() => {
    // Update the document title using the browser API
    setClassName(Helper.renderBlankValue(props.node.field_unread) == 'true' ? 'unread' : '');
  }, [props.node]);


  return (
    <>
      <Comment
        className={className}
        onClick={() => readNotification()}
        author={<> {Helper.renderReferencedTaxonomyName(props.node.field_notification_message)}</>}
        content={
          <>
            <Text
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              style={ellipsis ? { width: 300 } : undefined}
              ellipsis={ellipsis ? { tooltip: Helper.renderReferencedTaxonomyDescription(props.node.field_notification_message) } : false}
            >
              {Helper.renderReferencedTaxonomyDescription(props.node.field_notification_message)}
            </Text>
          </>
        }
        datetime={
          <Tooltip title={moment(Helper.renderBlankValue(props.node.created)).format('DD.MM.YYYY')}>
            <span>{moment(Helper.renderBlankValue(props.node.created)).fromNow()}</span>
          </Tooltip>
        }
      />
    </>
  );
};

export default NotificationListItem;
