import { useState, useEffect } from 'react';

import Helper from '@/helper/Helper';

export default () => {


  useEffect(() => {
    //console.log(Helper.getItem('storiesViewed'));
    //let storiesViewedStorage = Helper.getItem('storiesViewed');
    let storiesViewedStorage = Helper.getCookie('storiesViewed');


    try {
      //console.log(storiesViewedStorage);
      if (storiesViewedStorage && storiesViewedStorage.length) {
        storiesViewedStorage = JSON.parse(storiesViewedStorage);

        if (storiesViewedStorage.length > 250) {
          storiesViewedStorage.splice(50);
        }
        setStoriesViewed(storiesViewedStorage);
      }
    } catch (e) {
      Helper.setCookie('storiesViewed', [])
      return console.error(e); // error in the above string (in this case, yes)!
    }



  }, []);


  const [storiesViewed, setStoriesViewed] = useState([]);
  const [storyMute, setStoryMute] = useState(false);
  const [storyCurrent, setStoryCurrent] = useState(true);



  const setStoriesViewedCookie = (state) => {


    state = JSON.stringify(state);
    Helper.setCookie("storiesViewed", state, 3);
  }


  return { storiesViewed, setStoriesViewed, storyMute, setStoryMute, storyCurrent, setStoryCurrent, setStoriesViewedCookie };
};
