import { Card, Typography } from 'antd';
import Helper from '@/helper/Helper';
import { history, useModel, useIntl } from 'umi';

import IconBar from '@/components/IconBar';
import VerifyIcon from '@/components/VerifyIcon';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllDTIcons from '@fortawesome/pro-duotone-svg-icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import WishlistAdd from '@/components/Wishlist/components/Add';

import { Link } from "react-router-dom";

import moment from 'dayjs';

import styles from '../../style.less';


const ListItem = (props) => {

  const intl = useIntl();

  let profile = props.profile;

  profile.expired = (Helper.getProfileState(profile) === 'expired') ? true : false;
  //profile.expired = true;


  const viewProfileAction = () => {
    history.push(Helper.getProfileLink(profile));
  }



  const showAddressModal = {
    field_hide_address: Helper.renderBlankValue(profile.field_hide_address),
    field_city: Helper.renderTextField(profile.field_city),
  }


  return (
    <>
      <Card
        hoverable={false}
        bodyStyle={{ padding: "0" }}
        className={styles.card}
      >
        <Card.Meta
          description={
            <>




              <WishlistAdd profile={profile} />

              <div className="image-bar">

                <Link to={Helper.getProfileLink(profile)}>
                  {(profile.expired || profile.vacation) &&
                    <div className="expired-overlay">
                      <div>
                        <div>{Helper.renderBlankValue(profile.field_name)} {intl.formatMessage({ id: 'profile.list.expired', defaultMessage: 'ist abwesend' })}</div>

                      </div>
                    </div>
                  }


                  <div className={(profile.field_cover_private && profile.field_cover_private.length > 0 && profile.field_cover_private[0].value) ? 'cover-private' : ''}>
                    {(profile.field_cover_private && profile.field_cover_private.length > 0 && profile.field_cover_private[0].value) &&
                      <div className="overlay-lock">
                        <FontAwesomeIcon icon={AllDTIcons.faLockKeyhole} />
                      </div>
                    }
                    <LazyLoadImage
                      useIntersectionObserver={false}
                      visibleByDefault={true}
                      alt={Helper.renderBlankValue(profile.title)}
                      height={'500px'}
                      width={'400px'}
                      effect="blur"
                      threshold={100}
                      placeholderSrc={Helper.renderPlaceholderImageSrc(profile.field_cover)}
                      src={Helper.renderImageFieldSrc(profile.field_cover)}
                    />
                  </div>

                </Link>
              </div>



              <div className="bottom-bar">
                <IconBar profile={profile} />
                <div className="name">
                  <Link to={Helper.getProfileLink(profile)}>

                    {Helper.renderBlankValue(profile.field_name)}

                    {Helper.renderBlankValue(profile.field_verified) == 'true' &&
                      <VerifyIcon />
                    }
                  </Link>

                </div>
                <div className="location" onClick={() => viewProfileAction()}>
                  <FontAwesomeIcon icon={AllDTIcons.faLocationDot} />




                  {!showAddressModal.field_hide_address && showAddressModal.field_city &&
                    <span key={'address-item-region-1'}>
                      {Helper.renderTextField(profile.field_city)}
                    </span>
                  }


                  {(!profile.field_city.length || showAddressModal.field_hide_address) &&
                    <span key={'address-item-region-2'}>
                      {profile.field_regions.map((region, j) => {
                        return (
                          <>
                            <span>{Helper.renderTextField(region.entity.name)}</span>
                            {j < profile.field_regions.length - 1 &&
                              <span key={'address-item-region-seperator' + j}>, </span>
                            }
                          </>
                        )
                      })}
                    </span>
                  }


                </div>


                <div className="time" onClick={() => viewProfileAction()}>
                  <FontAwesomeIcon icon={AllDTIcons.faClock} />
                  {moment(Helper.renderBlankValue(profile.created)).fromNow()}
                </div>


              </div>

            </>
          }
        />
      </Card>
    </>
  );
};

export default ListItem;
