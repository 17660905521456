import { useState } from 'react';

export default () => {


  const defaultEscortListState = {
    bannerIndex: 0,
    dataSource: false,
    current: 1,
    pageSize: 20,
    filter: [],
    total: 0,
    loading: false,
    totalIds: [],
    homeListRef: [],
    storyListRef: [],
    quickFilterRef: [],
    topAdRef: [],
    updated: new Date().getTime()
  }


  const [escortListState, setEscortListState] = useState(defaultEscortListState);


  const [escortPrevId, setEscortPrevId] = useState(false);
  const [escortNextId, setEscortNextId] = useState(false);

  return { escortListState, setEscortListState, escortPrevId, setEscortPrevId, escortNextId, setEscortNextId, defaultEscortListState };
};
