import { useState, useRef } from 'react';
import { Input, Upload, message, Row, Col, Form, Divider, List, Switch, Spin, Tabs } from 'antd';
import ProForm, {
  ProFormSelect,
  ProFormText,
} from '@ant-design/pro-form';
import { useRequest, useIntl } from 'umi';
import { currentUser, changePassword, updateUser, loadTerms } from '@/services/ant-design-pro/api';

import Helper from '@/helper/Helper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllDTIcons from '@fortawesome/pro-duotone-svg-icons';


import WebPush from './WebPush'

import {
  LockOutlined,
}
  from '@ant-design/icons';

const BaseView = (props) => {




  const intl = useIntl();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(false);
  const [notificationType, setNotificationType] = useState(false);


  const baseViewRef = useRef();
  const formRef = useRef();

  useRequest(async () => {
    setLoading(true);
    let result = await currentUser();
    setUser(result.user);
    //console.log(result);
    setLoading(false);

    setNotificationType( (result.user.field_user_notification_type[0]) ? result.user.field_user_notification_type[0].target_id : false);


    //console.log(baseViewRef);

    baseViewRef.current.scroll({ top: 0, behavior: "smooth" });
    return result;
  });


  const getData = () => {

    return [
      /*
      {
        name: 'field_web_push_subscriptions',
        title: intl.formatMessage({ id: 'user.settings.field_web_push_subscriptions.title', defaultMessage: 'Push-Mitteilungen' }),
        description: intl.formatMessage({ id: 'user.settings.field_web_push_subscriptions.description', defaultMessage: 'Möchtest du künftig direkt per Push-Mitteilungen informiert werden?' }),
        actions: [
          <WebPush formRef={formRef} user={user} />
        ],
      },
      /*
      {
        name: 'field_allow_push_notifications',
        title: 'Push Nachrichten',
        description: 'Werden angezeigt während du dich auf der Website befindest',
        actions: [
          <ProForm.Item name={'field_allow_push_notifications'} label={''} valuePropName="checked" className={'lable-hidden'}>
            <Switch checkedChildren="Ja" unCheckedChildren="Nein" />
          </ProForm.Item>
        ],
      },
      */
      {
        name: 'field_allow_notifications',
        title: intl.formatMessage({ id: 'user.settings.field_allow_notifications.title', defaultMessage: 'Mitteilungen' }),
        description: intl.formatMessage({ id: 'user.settings.field_allow_notifications.description', defaultMessage: 'Möchtest du über alle Änderungen informiert werden.' }),
        actions: [
          <ProForm.Item name={'field_allow_notifications'} label={''} valuePropName="checked" className={'lable-hidden'}>
            <Switch checkedChildren={intl.formatMessage({ id: 'common.yes', defaultMessage: 'Ja' })} unCheckedChildren={intl.formatMessage({ id: 'common.no', defaultMessage: 'Nein' })}  />
          </ProForm.Item>
        ],
      },

      {
        name: 'field_newsletter_subscription',
        title: intl.formatMessage({ id: 'user.settings.field_newsletter_subscription.title', defaultMessage: 'Newsletter' }),
        description: intl.formatMessage({ id: 'user.settings.field_newsletter_subscription.description', defaultMessage: 'Möchtest du einen Newsletter erhalten.' }),
        actions: [
          <ProForm.Item name={'field_newsletter_subscription'} label={''} valuePropName="checked" className={'lable-hidden'}>
            <Switch checkedChildren={intl.formatMessage({ id: 'common.yes', defaultMessage: 'Ja' })} unCheckedChildren={intl.formatMessage({ id: 'common.no', defaultMessage: 'Nein' })}  />
          </ProForm.Item>
        ],
      },

    ];
  };

  const data = getData();


  const handlePasswordChange = async (values) => {
    let response = await changePassword(values);

    if (response.success) {
      message.success(intl.formatMessage({ id: 'user.settings.password.success', defaultMessage: 'Dein Passwort wurde geändert.' }));
    }

  };


  const handleUserChange = async (values) => {
    let response = await updateUser(values);

    if (response.success) {
      //window.location.reload();
      message.success(intl.formatMessage({ id: 'user.settings.user.success', defaultMessage: 'Deine Daten wurden geändert.' }));
    }

  };

  return (
    <div className="user-settings" ref={baseViewRef}>

      {loading ? <Spin /> : (
        <>


          <Tabs defaultActiveKey="1" items={
            [
              {
                key: 'user',
                label: intl.formatMessage({ id: 'user.settings.user.tabs.user.title', defaultMessage: 'Persönliche Daten' }),
                children: <Col md={12} xs={24}>

                  <Divider orientation="left"><FontAwesomeIcon className="mr-3" icon={AllDTIcons.faUser} />{intl.formatMessage({ id: 'user.settings.user.tabs.user.title', defaultMessage: 'Persönliche Daten' })}</Divider>


                  <ProForm
                    formRef={formRef}
                    layout="vertical"
                    onFinish={handleUserChange}
                    submitter={{
                      resetButtonProps: {
                        style: {
                          display: 'none',
                        },
                      },
                      submitButtonProps: {
                        children: 'submit',
                        style: {
                          display: 'block',
                        },
                      },
                    }}
                    initialValues={{
                      name: user.name[0].value,
                      mail: user.mail[0].value,
                      field_phone: (user.field_phone[0]) ? user.field_phone[0].value : '',
                      field_allow_notifications: (user.field_allow_notifications[0]) ? user.field_allow_notifications[0].value : false,
                      field_allow_push_notifications: (user.field_allow_push_notifications[0]) ? user.field_allow_push_notifications[0].value : false,
                      field_user_notification_type: (user.field_user_notification_type[0]) ? user.field_user_notification_type[0].target_id : false,
                      field_web_push_subscriptions: (user.field_web_push_subscriptions[0]) ? user.field_web_push_subscriptions[0].value : false,
                      field_newsletter_subscription: (user.field_newsletter_subscription[0]) ? user.field_newsletter_subscription[0].value : false,
                      preferred_langcode: (user.preferred_langcode[0]) ? user.preferred_langcode[0].value : false,
                    }}
                    hideRequiredMark
                  >
                    <ProFormText
                      name="mail"
                      label={intl.formatMessage({ id: 'login.form.email.label', defaultMessage: 'E-Mail' })}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'form.required', defaultMessage: 'Pflichtfeld' }),
                        },
                      ]}
                      disabled
                    />


                    <div style={{ display: 'none' }}>
                      <ProFormText
                        name="field_web_push_subscriptions"

                      />

                    </div>

                    <ProFormText
                      name="field_phone"
                      label={intl.formatMessage({ id: 'support.form.phone', defaultMessage: 'Telefon' })}
                      tooltip={intl.formatMessage({ id: 'user.settings.field_phone.tooltip', defaultMessage: 'Für Internationale Nummern, bitte mit Ländervorwahl angeben (+xx).' })}
                      rules={[
                        {
                          required: notificationType === 302,
                         message: intl.formatMessage({ id: 'form.required', defaultMessage: 'Pflichtfeld' }),
                        },
                      ]}
                    />

                    <ProFormSelect
                      label={intl.formatMessage({ id: 'user.settings.field_user_notification_type.label', defaultMessage: 'Benachrichtigen per' })}
                      name="field_user_notification_type"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      onChange={(value) => {
                        setNotificationType(value);
                      }}
                      request={async (values) => {
                        let result = await loadTerms({ vid: 'user_notification_types' }, {});
                        //console.log(result);
                        result.data.forEach((item, i) => {
                          item.id = item.id;
                          result[i] = item;
                        });

                        return Helper.prepareTermSelectOptions(result.data);
                      }}
                      block
                    />


<ProFormSelect
                  label={intl.formatMessage({ id: 'user.settings.preferred_langcode.label', defaultMessage: 'Sprache' })}
                  name="preferred_langcode"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
       
                  options={[
                    {
                      value: "de",
                      label: "Deutsch",
                    },
                    {
                      value: "en",
                      label: "English",
                    },
                    {
                      value: "fr",
                      label: "Français",
                    },
                    {
                      value: "es",
                      label: "Español",
                    },
                    {
                      value: "it",
                      label: "Italiano",
                    },

                  ]}
                  block
                />



                    <List
                      itemLayout="horizontal"
                      dataSource={data}
                      renderItem={(item) => (
                        <List.Item actions={item.actions}>
                          <List.Item.Meta title={item.title} description={item.description} />
                        </List.Item>
                      )}
                    />




                  </ProForm>
                </Col>,
              },
              {
                key: 'password',
                label: intl.formatMessage({ id: 'login.form.password.label', defaultMessage: 'Passwort' }),
                children: <Col md={12} xs={24}>


                  <Divider orientation="left">
                    <FontAwesomeIcon className="mr-3" icon={AllDTIcons.faLock} />{intl.formatMessage({ id: 'user.settings.password.divider', defaultMessage: 'Passwort ändern' })}
                  </Divider>

                  <ProForm
                    layout="vertical"
                    onFinish={handlePasswordChange}
                    submitter={{
                      resetButtonProps: {
                        style: {
                          display: 'none',
                        },
                      },
                      submitButtonProps: {
                        children: 'submit',
                      },
                    }}
                    hideRequiredMark
                  >
                    <ProFormText
                      name="current_password"
                      label={intl.formatMessage({ id: 'user.settings.current_password', defaultMessage: 'Aktuelles Passwort' })}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'form.required', defaultMessage: 'Pflichtfeld' }),
                        },
                      ]}
                      fieldProps={{
                        prefix: <FontAwesomeIcon className="mr-2" icon={AllDTIcons.faLock} />,
                        autoComplete: 'new-password'
                      }}
                    />


                    <Form.Item
                      style={{ width: '100%' }}
                      width="md"
                      name="new_password"
                      label={intl.formatMessage({ id: 'login.newPassword', defaultMessage: 'Neues Passswort' })}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'form.required', defaultMessage: 'Pflichtfeld' })
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password autoComplete={'new-password'} width="md" placeholder={intl.formatMessage({ id: 'form.textPlaceholder', defaultMessage: 'Bitte eingeben' })} prefix={<FontAwesomeIcon className="mr-2" icon={AllDTIcons.faLock} />} />
                    </Form.Item>


                    <Form.Item
                      style={{ width: '100%' }}
                      width="md"
                      size='large'
                      autoComplete="new-password"
                      name="confirm"
                      label={intl.formatMessage({ id: 'login.newPasswordConfirm', defaultMessage: 'Neues Passwort bestätigen' })}
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'form.required', defaultMessage: 'Pflichtfeld' })
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('new_password') === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(new Error(intl.formatMessage({ id: 'login.newPassword.error', defaultMessage: 'Die Passwörter stimmen nicht überein.' })));
                          },
                        }),
                      ]}
                    >
                      <Input.Password width="md" placeholder={intl.formatMessage({ id: 'form.textPlaceholder', defaultMessage: 'Bitte eingeben' })} autoComplete="new-password" prefix={<FontAwesomeIcon className="mr-2" icon={AllDTIcons.faLock} />} />
                    </Form.Item>

                  </ProForm>
                </Col>,
              }
            ]
          } />




        </>
      )}
    </div>
  );
};

export default BaseView;
