import NoticeIcon from './NoticeIcon';

const NoticeIconView = (props) => {

  return (
    <NoticeIcon
      placement={props.placement}
      loading={false}
      className="notification-tab"
    >
      <NoticeIcon.Tab
        tabKey="notification"
        title=""
        emptyText="emptyText"
        showViewMore
        className="notification-tab"
      />
    </NoticeIcon>
  );
};

export default NoticeIconView;
