import { useEffect } from 'react';

import { notification } from 'antd';
import { useModel } from 'umi';
import usePageVisibility from '@/hooks/usePageVisibility';
import socketIOClient from "socket.io-client";

import { getNotification, getNotifyAuthToken } from '@/services/ant-design-pro/api';

import Helper from '@/helper/Helper';






function SocketConnector() {



  let socketHost = Helper.isProduction() ? WEBSOCKET_URL_PROD : WEBSOCKET_URL_DEV;


  const { socket, setSocket } = useModel('socket', (ret) => ({
    socket: ret.socket,
    setSocket: ret.setSocket,
  }));

  const { initialState } = useModel('@@initialState');


  const { balance, setBalance } = useModel('balance', (ret) => ({
    balance: ret.balance,
    setBalance: ret.setBalance,
  }));

  const { notifications, setNotifications } = useModel('notifications', (ret) => ({
    notifications: ret.notifications,
    setNotifications: ret.setNotifications,
  }));

  const isVisible = usePageVisibility();


  const onSocketConnectMessage = (e) => {
    //console.log(e);

    if (e.callback === 'unread') {
      setNotifications(0);
      setBalance(e.data.balance);

      if ('setAppBadge' in navigator) {
        navigator.setAppBadge(parseInt(0));
      }

    }
    if (e.callback === 'nodejsNotify') {
      openNotification(e.data.subject, e.data.body, e.data.type);
      if (e.data.unread && e.data.balance) {

        setNotifications(e.data.unread);
        setBalance(e.data.balance);


        if ('setAppBadge' in navigator) {
          navigator.setAppBadge(parseInt(e.data.unread));
        }

      }
    }
  }

  const openNotification = async (title, body, type) => {

    if (type === 'default') {
      notification.open({
        message: title,
        description: body,
      });
    } else {

      if (notification[type]) {
        notification[type]({
          message: title,
          description: body,
        });
      }

    }
  };



  const loadNotifications = async () => {
    let params = {};
    params.action = 'listAction';

    let result = await getNotification(params, {});

    if (result && result.success) {
      setNotifications(result.unread);
      if ('setAppBadge' in navigator) {
        navigator.setAppBadge(parseInt(result.unread));
      }
    }
  }

  const initComponent = async () => {

    let params = {};
    params.action = 'getNotifyAuthTokenAction';
    let result = await getNotifyAuthToken(params, {});
    let authToken = result.notify_auth_token;

    const socketInit = socketIOClient(socketHost, {
      transports: ["websocket"],
      path: "/socket.io/",
      reconnection: true,
      reconnectionAttempts: 1000,
      reconnectionDelay: 100,
      autoConnect: true,
      upgrade: true,
      rememberUpgrade: true
    });


    socketInit.connect(true);

    let authMessage = {
      authToken: authToken,
      channels: ''
    };

    socketInit.emit('authenticate', authMessage);
    if (initialState.user) {
      socketInit.emit('subscribe', 'nodejs_user_' + initialState.user.current_user.uid);
    }
    socketInit.emit('subscribe', 'nodejs_notify');
    socketInit.on('message', onSocketConnectMessage);

    socketInit.on('disconnect', function (reason) {
      //setSocket(false);
    })

    socketInit.on('connect', function (e) {
      setSocket(socketInit);
      if (initialState.user) {
        loadNotifications();
      }
    })

  }


  const handleVisibility = async () => {
    if(!isVisible) {
      if(socket) {
        socket.disconnect();
      }
    }

    if(isVisible) {
      if(socket) {
        socket.disconnect();
        setSocket(false);
        initComponent();
      }
    }
  }

  useEffect(() => {
    initComponent();
  }, [initialState.user]);


  useEffect(() => {
    handleVisibility();
  }, [isVisible]);

  return (
    <></>
  );
}

export default SocketConnector;
