import { Badge, Spin, Tabs, Tooltip, Button } from 'antd';
import useMergedState from 'rc-util/es/hooks/useMergedState';
import React from 'react';
import classNames from 'classnames';
import NoticeList from './NoticeList';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.less';
import { useModel, useIntl } from 'umi';

import './assets/css/index.css';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllDTIcons from '@fortawesome/pro-duotone-svg-icons';


const { TabPane } = Tabs;

const NoticeIcon = (props) => {

  const intl = useIntl();

  const { notifications } = useModel('notifications', (ret) => ({
    notifications: ret.notifications,
  }));


  const getNotificationBox = () => {
    const {
      children,
      loading,
    } = props;

    if (!children) {
      return null;
    }

    const panes = [];
    React.Children.forEach(children, (child) => {
      if (!child) {
        return;
      }

      const { list, title, count, tabKey, showClear, showViewMore } = child.props;
      const len = list && list.length ? list.length : 0;
      const msgCount = count || count === 0 ? count : len;
      const tabTitle = msgCount > 0 ? `${title} (${msgCount})` : title;
      panes.push(
        <TabPane tab={tabTitle} key={tabKey}>
          <NoticeList
            key={'notice-list-desktop'}
            tabKey={tabKey}
            title={title}
          />
        </TabPane>,
      );
    });
    return (
      <>
        <Spin spinning={loading} delay={300}>
          <Tabs className={styles.tabs + ' notify-tabs'} >
            {panes}
          </Tabs>
        </Spin>
      </>
    );
  };

  const { className } = props;
  const [visible, setVisible] = useMergedState(false, {
    value: props.popupVisible,
    onChange: props.onPopupVisibleChange,
    visibleKey: new Date()
  });
  const noticeButtonClass = classNames(className, styles.noticeButton);
  const notificationBox = getNotificationBox();
  const NoticeBellIcon = <FontAwesomeIcon icon={AllDTIcons.faBell} />;


  //console.log(notificationState);

  const trigger = (
    <Tooltip title={intl.formatMessage({ id: 'noticeicon.title', defaultMessage: 'Nachrichten' })}>
      <Button type={'primary'}
        className={classNames(noticeButtonClass, {
          opened: visible,
        })}
      >
        <Badge
          count={notifications}
          style={{
            boxShadow: 'none',
          }}
          className="notice-badge"
        >
          {NoticeBellIcon}
        </Badge>

      </Button>

    </Tooltip>
  );

  if (!notificationBox) {
    return trigger;
  }

  return (
    <HeaderDropdown
      placement={props.placement}
      autoAdjustOverflow
      overlay={(visible) ? notificationBox : ''}
      overlayClassName={styles.popover}
      trigger={['click']}

      visible={visible}
      onVisibleChange={setVisible}
    >
      {trigger}
    </HeaderDropdown>
  );
};


NoticeIcon.Tab = NoticeList;
export default NoticeIcon;
