import { Button, Drawer, Badge, Empty, Tooltip } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllDTIcons from '@fortawesome/pro-duotone-svg-icons';

import { useModel, useIntl } from 'umi';
import ListItem from '@/pages/Home/components/ListView/components/ListItem'

import Banner from '@/components/Banner';

// You need to import the CSS only once
import './assets/css/index.css';




const List = (props) => {

  const intl = useIntl();

  const { wishlistItems, setWishlistItems, showWishlist, setShowWishlist, setWishlistItemsStorage } = useModel('wishlist', (ret) => ({
    wishlistItems: ret.wishlistItems,
    setWishlistItems: ret.setWishlistItems,
    showWishlist: ret.showWishlist,
    setShowWishlist: ret.setShowWishlist,
    setWishlistItemsStorage: ret.setWishlistItemsStorage
  }));

  const toggleDrawer = () => {
    setShowWishlist(!showWishlist);
  };

  const clearList = () => {
    setWishlistItems([]);
    setWishlistItemsStorage([]);
  };




  return (
    <>

      {props.showButton &&
        <span>
          
          <Tooltip title={'Favoriten'}><Button title={'Favoriten'} className="btn-show-wishlist" type="primary" onClick={() => toggleDrawer()}><Badge count={wishlistItems.length} size={'small'}><span><FontAwesomeIcon icon={AllDTIcons.faHeart} /></span> </Badge></Button></Tooltip>
          </span>
      }

      {props.showTextLink &&
        <div onClick={toggleDrawer}><Badge count={wishlistItems.length} size={'small'}><FontAwesomeIcon icon={AllDTIcons.faHeart} /> </Badge> {intl.formatMessage({ id: 'wishlist.drawer.title', defaultMessage: 'Favoriten' })}</div>
      }

      

      {props.showList &&
        <Drawer
          className="drawer-wishlist"
          height={520}
          destroyOnClose
          title={intl.formatMessage({ id: 'wishlist.drawer.title', defaultMessage: 'Favoriten' })}
          placement="bottom"
          onClose={toggleDrawer}
          open={showWishlist}
          extra={<>
            {wishlistItems.length > 0 &&
              <Button className="btn-clear" type="text" onClick={clearList}><FontAwesomeIcon className="mr-3" icon={AllDTIcons.faTrash} /></Button>
            }
          </>}
        >
          <div className="profile-list-view wishlist-list-view">

            {wishlistItems.length === 0 &&
              <Empty />
            }

            <swiper-container
              class="swiper-wishlist"
              navigation="false"
              pagination-clickable={true}
              pagination={true}
              slides-per-view={"auto"}
              centered-slides={false}
              space-between={0}
              loop={'false'}
              free-mode={'true'}
              
            >

              {wishlistItems.map((item, i) => {
                //console.log(item);
                return (
                  <swiper-slide>


                    <ListItem profile={item} />
                    
                  </swiper-slide >
                )
              })}

            </swiper-container>
          </div>
        </Drawer>
      }
    </>
  );
};

export default List;
