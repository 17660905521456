export default  {
  "logout.success": "Déconnexion réussie",
  "common.banner.title": "Publicité sur escort.ch",
  "common.yes": "Oui",
  "common.no": "Non",
  "form.required": "Champ obligatoire",
  "form.textPlaceholder": "Entrez s'il vous plait",
  "block.support.title": "Avez-vous des questions?",
  "modal.sure": "Es-tu sûr?",
  "footer.link.profile": "Tarifs profil",
  "footer.link.banner": "Tarifs des bannières",
  "footer.link.support": "Soutien",
  "footer.link.faq": "FAQ",
  "footer.link.login": "Connexion",
  "footer.opening.row1": "Lun-Ven 09:00 à 21:00",
  "footer.opening.row2": "Sam\/Dim 10h00 à 15h00",
  "footer.opening.text": "WhatsApp et les e-mails seront à nouveau traités à 22h00",
  "footer.legal.compliance": " Avis de conformité",
  "footer.legal.refund": " Politique de remboursement",
  "footer.legal.gtc": "CGV",
  "footer.legal.privacy": "Confidentialité",
  "footer.legal.imprint": "Imprimer",
  "iconbar.btn.video.profile": " Voir le profil",
  "iconbar.btn.video.close": "Fermer",
  "noticeicon.title": "messages",
  "noticeicon.btn.unread": "tout marquer comme lu",
  "regionfiltertags.title": "Contacts sexuels dans les endroits suivants",
  "rightcontent.tooltip.profile": "Profils",
  "rightcontent.tooltip.banner": "Bannière",
  "rightcontent.tooltip.settings": "Paramètres",
  "rightcontent.tooltip.logout": "Se déconnecter",
  "rightcontent.btn.login": "Créer un compte \/ se connecter",
  "premium.modal.title": "Profils premium sur escort.ch",
  "verifyicon.tooltip": "Vérifié",
  "wishlist.tooltip.remove": "Retirer des favoris",
  "wishlist.tooltip.add": "Ajouter aux Favoris",
  "wishlist.drawer.title": "Favoris",
  "faq.page.title": "Questions fréquemment posées",
  "faq.support.title": "Votre question n'a pas été incluse?",
  "home.meta.title": "Aventures érotiques dans votre région - escort.ch",
  "home.meta.description": "★★★ Aventures érotiques dans votre région. ♥ Trouvez votre escorte appropriée ! escort.ch ▷ Le nouveau portail sexe & érotique en Suisse.",
  "home.page.title": "Aventures érotiques dans votre région",
  "home.list.title": "Résultats correspondants",
  'home.list.title.ready': 'Chercher, découvrir & s amuser', // template
  'home.filter.banner.divider': 'Les filtres sont présentés par',
  "home.filter.tag.field_expired": " De retour bientôt",
  "home.filter.tag.field_verified": "Vérifié",
  "home.filter.tag.field_secret_gallery": "Galerie secrète",
  "home.filter.tag.field_hot": "Chaud",
  "home.filter.tag.field_video": "Vidéo",
  "home.filter.tag.field_stories": "Histoire",
  "home.filter.tag.field_trans": "Trans",
  "home.filter.tag.field_female": "Femme",
  "home.filter.location.error": "Vous devez autoriser l'accès à la localisation dans votre navigateur pour utiliser cette fonctionnalité",
  "home.filter.btn.show": "Filtre",
  "home.filter.drawer.title": "Filtre",
  "home.filter.keyword.divider": "Recherche",
  "home.filter.keyword.placeholder": "Nom ou autre terme de recherche...",
  "home.filter.popular_tags.divider": "Tags populaires",
  "home.filter.more.divider": "Plus de filtres",
  "home.filter.field_regions.header": "Régions",
  "home.filter.field_regions.option.region": "Région \/ canton",
  "home.filter.field_regions.option.near": "Périmètre",
  "home.filter.field_rubrics.header": "Rubriques",
  "home.filter.field_offers.header": "Prestations de service",
  "home.filter.field_age.header": "Âge",
  "home.filter.field_height.header": "Hauteur",
  "home.filter.field_weight.header": "Lester",
  "home.filter.field_eye_color.header": "Couleur des yeux",
  "home.filter.field_hair_color.header": "Couleur de cheveux",
  "home.filter.field_hair_length.header": "Longueur des cheveux",
  "home.filter.field_language.header": "Langues",
  "home.filter.field_person_types.header": "Groupe ethnique",
  "home.filter.field_cup_size.header": "taille de bonnet",
  "home.filter.field_physique.header": "Physique",
  "home.filter.field_smoking.header": "Fumeur",
  "home.filter.field_shoe_size.header": "Pointure",
  "home.filter.field_intimate_area.header": "Espace intime",
  "home.filter.field_body_features.header": "Caractéristiques du corps",
  "home.filter.geolocation.error": "Vous devez autoriser l'accès à la localisation dans votre navigateur pour utiliser cette fonctionnalité",
  "home.filter.geolocation.radius.label": "Périmètre",
  "profile.list.expired": "est absent",
  "login.page.title": "Se connecter \/ créer un compte",
  "login.register.title": "Créez votre compte sur escort.ch",
  "login.register.text": "Inscrivez-vous sur escort.ch. La plateforme d'escorte moderne en Suisse.",
  "login.register.benefit01": "Inscription entièrement gratuite",
  "login.register.benefit02": "Discret, sécurisé et anonyme grâce au cryptage SSL",
  "login.register.benefit03": "Accès au gestionnaire de profils",
  "login.register.benefit04": "Escort.ch est une entreprise suisse avec des normes de qualité élevées",
  "login.register.benefit05": "Interface conviviale et design moderne",
  "login.register.button.label": "Inscrivez-vous maintenant gratuitement",
  "login.form.card.title": "Login",
  "login.card.benefit.title": "Les avantages d'escorte.ch",
  "login.card.benefit.text": "Chez escort.ch, vous bénéficiez des avantages suivants. L'inscription est entièrement gratuite. Notre site Web se caractérise par une interface conviviale et un design moderne. Nous accordons également une grande importance au service client et à la confidentialité afin d'assurer un environnement sûr et positif pour tous nos membres.",
  "login.card.benefit.icon01": "300 crédits offerts",
  "login.card.benefit.icon02": "Discret & sûr",
  "login.card.benefit.icon03": "Moderne & innovant",
  "login.card.benefit.icon04": "Plusieurs profils possibles",
  "login.message.success": "Connexion réussie.",
  "login.form.email.label": "Adresse e-mail",
  "login.form.password.label": "Mot de passe",
  "login.button.passwordForget": "Mot de passe oublié",
  "login.message.passwordSuccess": "Des instructions supplémentaires ont été envoyées à votre adresse e-mail.",
  "login.passwordForget.success": "Votre nouveau mot de passe a été enregistré. Veuillez vous connecter.",
  "login.validate.email": "Aucune adresse e-mail valide",
  "login.activationCode": "Code d'activation",
  "login.newPassword": "nouveau mot de passe",
  "login.newPasswordConfirm": "Confirmer le nouveau mot de passe",
  "login.newPassword.error": "Le mot de passe ne correspond pas.",
  "login.message.passwordForget": "Si vous avez oublié votre mot de passe, vous pouvez le réinitialiser. Entrez votre adresse e-mail ci-dessous. Nous vous enverrons un code d'activation.",
  "register.form.gtc": "En vous inscrivant, vous acceptez nos conditions générales de vente.",
  "register.form.privacy": "En vous inscrivant, vous acceptez notre Politique de confidentialité.",
  "register.form.newsletter": "S'inscrire à la Newsletter",
  "register.form.confirm.title": "Nous vous avons envoyé le code de confirmation par e-mail. Veuillez le saisir pour terminer l'inscription.",
  "register.form.btn.back": "Dos",
  "payment.page.cancel.title": "Paiement annulé",
  "payment.page.cancel.subtitle": "Veuillez réessayer ou contacter l'assistance",
  "payment.page.cancel.button.credits": "Acheter des crédits",
  "payment.page.cancel.button.support": "Soutien",
  "payment.page.confirm.title": "Paiement réussi",
  "payment.page.confirm.subtitle": "Amusez-vous avec vos crédits.",
  "payment.page.confirm.button.profile": "Vers le gestionnaire de profil",
  "payment.page.error.title": "Paiement échoué",
  "payment.page.error.subtitle": "Veuillez réessayer ou contacter l'assistance",
  "price.profile.page.title": "Tarifs profil",
  "price.profile.card.title": "L'avantage principal",
  "price.profile.btn.create": "Créer un profil maintenant",

  "profile.navigation.prev": "Profil précédent", 
  "profile.navigation.next": "Profil suivant", 

  "price.banner.page.title": "Tarifs des bannières",
  "price.banner.card.title": "Une information important",
  "price.banner.btn.creat": "Créez des bannières maintenant",
  "support.form.succes": "Votre demande à été envoyé.",
  "support.form.name": "Votre nom",
  "support.form.email": "E-mail",
  "support.form.phone": "Téléphone",
  "support.form.message": "Votre message",
  "profile.report.btn": "Signaler le profil",
  "profile.share.btn": "Partager le profil",
  "profile.report.success": "Merci pour votre message.",
  "profile.report.field_reason": "Raison du signalement",
  "profile.report.body": "Message",
  "profile.card.about.title": "Sur moi",
  "profile.text.more": "En savoir plus",
  "profile.text.less": "Lire moins",
  "profile.translate.btn": "Traduire",
  "profile.card.data.title": "Détails",
  "profile.card.data.field_hair_color": "Cheveux",
  "profile.card.data.field_body_features": "Caractéristiques",
  "profile.card.data.field_physique": "Physique",
  "profile.card.data.field_height": "Hauteur",
  "profile.card.data.field_weight": "Lester",
  "profile.card.data.field_cup_size": "taille de bonnet",
  "profile.card.data.field_smoking": "Fumeur",
  "profile.card.data.field_intimate_area": "Espace intime",
  "profile.card.data.field_shoe_size": "Pointure",
  "profile.field_website": "Visitez le site Web",
  "profile.message.sms": ", je t'ai trouvé sur escort.ch",
  "profile.secretgallery.message.hello": " Bonjour ",
  "profile.secretgallery.modal.title": "Un code PIN est nécessaire pour accéder aux images de la galerie secrète.",
  "profile.secretgallery.title": "Veuillez utiliser les options de contact répertoriées ci-dessous pour demander ce code PIN.",
  "profile.secretgallery.message.sms": " je voudrais le pin pour votre galerie secrète sur escort.ch.",
  'profile.secretgallery.message.error': 'Le code PIN saisi n était pas correct.', // template

  'user.profile.updateRegionRubric.modal.success': 'Tes régions et rubriques ont été mises à jour.',
  'user.profile.updateRegionRubric.modal.alert.region':'Mets à jour tes régions maintenant.',
  'user.profile.updateRegionRubric.modal.alert.rubric':'Actualise maintenant tes rubriques.',

  "user.banner.page.title": "Gestionnaire de bannières",
  "user.banner.btn.create": "Créer une bannière",
  "user.banner.btn.edit": "Modifier la bannière",
  "user.banner.form.success": "Bannière enregistrée.",
  "user.banner.field_banner_size": "Taper",
  "user.banner.field_banner_clicks": "Clics",
  "user.banner.delete.success": "La bannière a été supprimée",
  "user.banner.renew.success": "La bannière a été renouvelée",
  "user.banner.column.totalClicks": "Nombre total de clics",
  "user.banner.column.field_banner_clicks": "Cliquez sur Quota",
  "user.banner.btn.renew": "Renouveler",
  "user.banner.btn.delete": "Supprimer",
  "user.banner.filter.field_all": "Tous",
  "user.banner.filter.field_active": "Actif",
  "user.banner.filter.field_expired": "Expiré",
  "user.banner.filter.field_review": "Examen",
  "user.banner.filter.field_decline": "Diminué",
  "user.banner.filter.field_deleted": "Supprimé",


  "banner.visit.website": "Visiter le site web",
  
  "user.credits.page.title": " Solde du compte courant:",
  "user.credits.form.card.title": "Acheter des crédits",
  "user.credits.form.btn.payonline": "Payer en ligne",
  "user.credits.swissbill.additionalInformation": " Numéro de facture:",
  "user.credits.swissbill.btn": "Payer par virement bancaire",
  "user.credits.swissbill.btn.print": "Imprimer",
  "user.credits.swissbill.data.headline": "Détails du transfert",
  "user.credits.swissbill.data.kontonummer": "N ° de compte.:",
  "user.credits.swissbill.data.additionalInformation": "Information additionnelle:",
  "user.credits.swissbill.data.refnr": "Réf. Nr. :",
  "user.credits.swissbill.data.amount": "Montant",
  "user.credits.swissbill.data.text": "Dès que nous aurons reçu le paiement, nous créditerons le montant sur votre compte.",
  "user.credits.swissbill.data.kontoinhaber": "Propriétaire du compte:",
  "user.credits.swissbill.data.bankkonto": "Compte bancaire:",
  "user.credits.swissbill.data.bank": "Banque",
  "user.credits.swissbill.data.subject": "Objet du transfert :",
  "user.credits.table.headerTitle": "Transactions",
  "user.credits.table.id": "Identifiant de transaction",
  "user.credits.table.title": "Taper",
  "user.credits.table.created": "Date",
  'user.credits.form.gtc': 'Je confirme avoir lu les conditions générales et les accepter. Je confirme également que jai plus de 18 ans.', // template
  'user.credits.form.gtc.link': 'Lien vers nos CGV', // template

  'user.credits.form.privacy': 'Je confirme avoir lu la politique de confidentialité et je l accepte.', // template
  'user.credits.form.privacy.link': 'Lien vers notre politique de confidentialité', // template

  'user.credits.address.modal.title': 'Adresse de facturation', // template
  'user.credits.address.modal.field_firstname': 'Prénom', // template
  'user.credits.address.modal.field_lastname': 'Nom de famille', // template
  'user.credits.address.modal.field_street': 'Rue', // template
  'user.credits.address.modal.field_zip': 'Code postal', // template
  'user.credits.address.modal.field_city': 'Ville', // template
  

  "user.settings.page.title": "Paramètres",
  "user.settings.field_web_push_subscriptions.title": "Notifications push",
  "user.settings.field_web_push_subscriptions.description": "Vous souhaitez être informé directement via les notifications push ?",
  "user.settings.field_allow_notifications.title": "messages",
  "user.settings.field_allow_notifications.description": "Vous souhaitez être informé de tous les changements ?",
  "user.settings.field_newsletter_subscription.title": "Souhaitez-vous recevoir une newsletter ?",
  "user.settings.password.success": "Votre mot de passe a été changé.",
  "user.settings.user.success": "Vos données ont été modifiées.",
  "user.settings.user.divider": "Utilisateur",
  "user.settings.field_phone.tooltip": "Pour les numéros internationaux, veuillez inclure le code du pays (+xx).",
  "user.settings.field_user_notification_type.label": "Notifier par",
  "user.settings.password.divider": "Changer le mot de passe",
  "user.settings.current_password": "Mot de passe actuel",
  "user.settings.user.tabs.user.title": "Données personnelles",


  "user.profile.form.success": "Votre profil a été sauvegardé.",


  "user.profile.page.title": "Gestionnaire de profil",
  "user.profile.vacation.modal.title": "Informez vos clients de votre absence",
  "user.profile.vacation.modal.text": "Tenez vos visiteurs informés en indiquant combien de temps vous serez absent. Votre annonce inactive restera visible sur notre portail pendant 14 jours supplémentaires, mais les images et les coordonnées seront masquées.",
  "user.profile.vacation.modal.success": "Vos vacances ont été enregistrées.",
  "user.profile.vacation.modal.field_vacations.label": "Vacances en cours :",
  "user.profile.vacation.modal.btn.delete": "Supprimer les vacances",
  "user.profile.upvote.modal.title": "Exécutez automatiquement des votes positifs pour 5 fois plus de clics",
  "user.profile.upvote.modal.success": "Votre programme de vote positif a été enregistré.",
  "user.profile.upvote.modal.text": "Configurez jusqu'à cinq fois par jour lorsque votre annonce sera promue à la première position (5 crédits par vote positif).",
  "user.profile.upvote.modal.field_upvote_scheduler": "Ajouter du temps",
  "user.profile.upvote.modal.hour": "Heure",
  "user.profile.upvote.modal.hour.rule.01": "Chiffres uniquement (00-23).",
  "user.profile.upvote.modal.hour.rule.02": "Au moins 2 caractères",
  "user.profile.upvote.modal.minute.rule.01": "Chiffres uniquement (00-59).",
  "user.profile.upvote.modal.minute.rule.02": "Au moins 2 caractères",
  "user.profile.upvote.modal.minute": "Minute",
  "user.profile.premium.modal.title": "Mise à niveau Premium pour 30 crédits.",
  "user.profile.premium.modal.success": "Votre mise à niveau premium est terminée.",
  "user.profile.premium.modal.field_valid_range": "Date",
  "user.profile.premium.modal.empty_slots": "Nous n'avons pas de mises à niveau premium gratuites disponibles pour cette date.",
  "user.profile.support.btn.close": "marque comme Terminé",
  "user.profile.support.subject": "Sujet",
  "user.profile.support.comment_body": "Message",
  "user.profile.story.modal.add.image": "Créer une histoire en images",
  "user.profile.story.modal.add.video": "Créer une histoire vidéo",
  "user.profile.story.modal.form.success": "Votre histoire a été créée.",
  "user.profile.story.modal.video.spinner": "Nous préparons actuellement votre vidéo. Veuillez patienter.",
  "user.profile.story.modal.list.divider": "Mes histoires",
  "user.profile.story.modal.list.delete": "Supprimer",
  "user.profile.story.modal.list.renew": "Renouveler",
  "user.profile.story.modal.list.field_approved": "Approuvé",
  "user.profile.story.modal.list.field_review": "Examen",
  "user.profile.story.modal.list.field_expired": "Expiré",
  "user.profile.secreetgallery.modal.field_review": "Examen",
  "user.profile.secreetgallery.modal.field_approved": "Approuvé",
  "user.profile.secreetgallery.modal.text": "Téléchargez des images uniques et définissez un code PIN individuel pour accorder l'accès à votre galerie secrète uniquement aux visiteurs sélectionnés de votre choix.",
  "user.profile.secreetgallery.modal.success": "Votre SecretGallery a été sauvegardé",
  "user.profile.secreetgallery.modal.btn.delete": "Supprimer",
  "user.profile.renew.modal.title": "Renouveler le profil",
  "user.profile.renew.modal.success": "Votre profil a été renouvelé",
  "user.profile.renew.modal.field_start_type": "Montre le profile",
  "user.profile.renew.modal.field_start_type.now": "Maintenant",
  "user.profile.renew.modal.field_start_type.date": "À ce jour",
  "user.profile.renew.modal.field_valid_range": "Date\/heure",
  "user.profile.list.upvote.add": "Réserver un vote positif pour 5 crédits ?",
  "user.profile.list.upvote.success": "Votre vote positif a été défini.",
  "user.profile.list.clone.success": "Votre profil a été dupliqué",
  "user.profile.list.field_all": "Tous",
  "user.profile.list.field_active": "Actif",
  "user.profile.list.field_planned": "Prévu",
  "user.profile.list.field_expired": "Expiré",
  "user.profile.list.field_review": "Examen",
  "user.profile.list.field_decline": "Diminué",
  "user.profile.list.field_deleted": "Supprimé",
  "user.profile.list.field_draft": "Projet",
  "user.profile.list.menu.show": "Montrer",
  "user.profile.list.menu.edit": "Modifier",
  "user.profile.list.menu.clone": "Cloner",
  "user.profile.list.menu.delete": "Supprimer",
  "user.profile.list.menu.renew": "Renouveler",
  "user.profile.list.menu.autorenew.enable": "Activer le renouvellement automatique",
  "user.profile.list.menu.autorenew.disable": "Désactiver le renouvellement automatique",
  "user.profile.list.menu.upvote.add": "Livre",
  "user.profile.list.menu.upvote.schedule": "Calendrier",
  "user.profile.list.menu.vacation": "Absence",
  "user.profile.list.menu.secret_gallery": "Galerie secrète",
  "user.profile.list.menu.regionRubric": "Régions & rubriques",
  "user.profile.list.status.field_review": "Examen",
  "user.profile.list.status.field_approved": "Approuvé",
  "user.profile.list.status.field_auto_renew": "Renouvellement automatique",
  "user.profile.list.status.field_decline": "Diminué",
  "user.profile.list.status.field_expired": "Expiré",
  "user.profile.list.status.field_top_profile": "Mise à niveau premium",
  "user.profile.list.status.field_deleted": "Supprimé",
  "user.profile.list.status.field_review_support": "Ticket d'assistance",
  "user.profile.list.status.field_vip": "VIP",
  "user.profile.edit": "Editer le profil",
  "user.profile.create": "Créer un profil",
  "user.profile.drawer.title": "Gestionnaire de profil",
  "user.profile.edit.field_age_verification_images.divider": "Verification de l'AGE",
  'user.profile.edit.image_verification_images.divider': 'Vérification des images', // template
  "user.profile.edit.field_age_verification_images.strong": "Trois images sont nécessaires pour la vérification :",
  "user.profile.edit.field_age_verification_images.text01.title": "Documents officiels",
  "user.profile.edit.field_age_verification_images.text01.text": "Photo bien éclairée de votre document officiel (permis de conduire, passeport ou carte d'identité)",
  "user.profile.edit.field_age_verification_images.text02.title": "Votre photo avec carte d'identité",
  "user.profile.edit.field_age_verification_images.text02.text": "Photo de vous tenant un passeport\/carte d'identité nationale près de votre visage (même document utilisé pour la photo précédente) - votre visage et les données sur le document doivent être visibles.",
  "user.profile.edit.field_age_verification_images.text03.title": "Photo de confirmation",
  "user.profile.edit.field_age_verification_images.text03.text": "Une photo avec vous et une feuille de papier contenant la date du jour et la mention \"escort.ch\".",
  "user.profile.edit.field_cover.divider": "Image de couverture",
  "user.profile.edit.field_cover.alert": "Veuillez sélectionner une image qui présente le mieux votre profil.",
  'user.profile.edit.field_cover.private': 'Afficher en tant que photo secrète (apparaît flou pour les visiteurs)', // template
  "user.profile.edit.field_gallery.divider": "Galerie",
  "user.profile.edit.field_gallery.alert": "Tu peux télécharger jusqu'à 9 photos. Pour plus d'intimité, utilise la galerie privée.",
  'user.profile.edit.field_gallery.private': 'Galerie privée', // template
  'user.profile.edit.field_gallery.public': 'Galerie publique', // template
  "user.profile.edit.field_video.divider": "Vidéo",
  "user.profile.edit.field_video.alert": "Les vidéos donnent aux clients un meilleur aperçu de vos services. Montrez-vous sous votre meilleur jour !",
  "user.profile.edit.field_video.encoding.alert": "Nous préparons actuellement votre vidéo. Revenez plus tard.",
  "user.profile.edit.field_video.btn.delete": "Supprimer la vidéo",
  "user.profile.edit.field_video.btn.help": "Conseils techniques",
  "user.profile.edit.field_video.help.text01": "Assurez-vous d'avoir une bonne lumière pour la vidéo",
  "user.profile.edit.field_video.help.text02": "La vidéo ne doit pas dépasser 100 Mo",
  "user.profile.edit.field_video.help.text03": "Tous les formats courants sont pris en charge",
  "user.profile.edit.field_video.help.text04": "La durée maximale de la vidéo est de 60 secondes",
  "user.profile.form.address.placeholder": "Entrez la rue et le numéro pour rechercher et remplir l'adresse automatiquement",
  "user.profile.form.address.button.delete": "Supprimer l adresse", 
  "user.profile.form.costs.spinner": "Calcul des coûts...",
  "user.profile.form.step.category_location": "Régions et rubriques",
  "user.profile.form.step.field_profile_type.divider": "Durée",
  "user.profile.form.field_regions.divider": "Régions",
  "user.profile.form.field_regions.alert": "Première région gratuite, région supplémentaire +5 crédits.",
  "user.profile.form.field_rubrics.alert": "Première rubrique gratuite, rubrique complémentaire +5 crédits.",
  "user.profile.form.field_rubrics.divider": "Rubriques",
  "user.profile.form.step.media.title": "Photos & Vidéos",
  "user.profile.form.step.base.title": "Base",
  "user.profile.form.step.base.divider": "Informations de base",
  "user.profile.form.step.base.alert": "Informations de base de votre profil.",
  "user.profile.form.field_auto_renew": "Souhaitez-vous que votre profil soit automatiquement renouvelé ?",
  "user.profile.form.field_name": "Afficher un nom",
  "user.profile.form.title": "Titre",
  "user.profile.form.field_text": "Texte",
  "user.profile.form.location.divider": "Votre emplacement",
  "user.profile.form.location.alert": "Votre adresse sera ensuite utilisée pour la recherche de zone.",
  "user.profile.form.location.input.label": "Adresse",
  "user.profile.form.location.field_hide_address": "Masquer l'adresse",
  "user.profile.form.location.field_zip": "Zipper",
  "user.profile.form.location.field_city": "Ville",
  "user.profile.form.location.field_country": "Pays",
  "user.profile.form.contact.divider": "Contact",
  "user.profile.form.contact.alert": "Comment vous contacter",
  "user.profile.form.contact.field_phone": "Téléphone",
  "user.profile.form.contact.field_contact_type": "Contactez par",
  "user.profile.form.contact.field_website": "Site Internet",
  "user.profile.form.contact.field_instagram": "Instagram",
  "user.profile.form.contact.field_onlyfans": "Seuls les fans",
  "user.profile.form.contact.field_telegram": "Télégramme",
  "user.profile.form.steps.details.title": "Détails",
  "user.profile.form.field_language.divider": "Langues",
  "user.profile.form.field_language.alert": "Choisissez les langues que vous parlez.",
  "user.profile.form.field_offers.divider": "Prestations de service",
  "user.profile.form.field_offers.alert": "Sélectionnez les services que vous proposez.",
  "user.profile.form.field_offers.btn.selectall": "Tout sélectionner",
  "user.profile.form.field_offers.btn.selectnone": "Tout déselectionner",
  "user.profile.form.field_body_features.divider": "Caractéristiques du corps",
  "user.profile.form.field_body_features.alert": "Si vous avez d'autres caractéristiques corporelles, veuillez les sélectionner ici.",
  "user.profile.form.field_gender.divider": "Informations vous concernant",
  "user.profile.form.field_gender.alert": "Nous utilisons ces informations pour nos filtres.",
  "user.profile.form.field_age": "Âge",
  "user.profile.form.field_gender": "Genre",
  "user.profile.form.field_cup_size": "taille de bonnet",
  "user.profile.form.field_eye_color": "Couleur des yeux",
  "user.profile.form.field_hair_color": "Couleur de cheveux",
  "user.profile.form.field_hair_length": "Longueur des cheveux",
  "user.profile.form.field_height": "Hauteur",
  "user.profile.form.field_person_types": "Groupe ethnique",
  "user.profile.form.field_physique": "Physique",
  "user.profile.form.field_shoe_size": "Afficher la taille",
  "user.profile.form.field_intimate_area": "Espace intime",
  "user.profile.form.field_smoking": "Fumeur",
  "user.profile.form.field_weight": "Lester",
  "user.profile.form.step.age_verification": "Verification de l'AGE",
  "user.profile.edit.field_video.thumbnail.spinnner": "Nous préparons actuellement votre vidéo. Vous pouvez continuer à modifier votre profil.",



  'status.message.alert.created': 'Ton message de statut est en cours de vérification.', // template
  'status.message.alert.deleted': 'Ton message de statut a été supprimé', // template
  'status.message.title': 'Messages d état', // template
  'status.message.autorefresh': 'AutoRefresh', // template
  'status.message.label.profile': 'Profil', // template
  'status.message.label.body': 'Ton message', // template
  'status.message.button.loadmore': 'Voir plus', // template
  'status.message.banner.title': 'Les messages de statut sont présentés par', // template
  'profile.statusmessenger.header': 'Dernier message d état',


  'blog.category.label': 'Catégories de blogs', // template
  'modal.updater': 'Nouvelle mise à jour disponible.', // template
  'user.profile.renew.modal.additionalCosts': 'y compris les régions et les rubriques',
  'filterpagetags.title': 'Liens intéressants', // template 
  'filter.button.more': 'Afficher plus de profils', // template 

  'blog.meta.title': 'Notre blog pour des aventures érotiques - escort.ch', // template
  'blog.meta.description': '★★★★ Notre blog pour des aventures érotiques dans votre région. ♥ Trouvez votre escorte parfaite ! escort.ch ▷ Le nouveau portail sexuel et érotique en Suisse.', // template

  'faq.meta.title': 'Réponse à vos questions : FAQf complète', // template
  'faq.meta.description': '★★ FAQ pour des aventures érotiques dans ta région. ♥ Trouve l escorte qui te convient ! escort.ch ▷ Le nouveau portail de sexe et d érotisme en Suisse.', // template


  'login.meta.title': 'Cest parti ! Se connecter & se plongerf', // template
  'login.meta.description': '★★★ Ton login pour des aventures érotiques dans ta région.♥ Trouve l escorte qui te convient ! escort.ch ▷ Le nouveau portail de sexe et d érotisme en Suisse.', // template

  'support.meta.title': 'Centre de support pour d autres questions', // template
  'support.meta.description': '★★★ Notre support pour des aventures érotiques dans ta région. ♥ Trouve l escorte qui te convient ! escort.ch ▷ Le nouveau portail de sexe et d érotisme en Suisse.', // template

  'pwainstaller.title': 'Installer l application escort.ch', // template
  'pwainstaller.description': 'La PWA (Progressive Web App) de escort.ch offre de nombreux avantages qui améliorent considérablement l expérience sur escort.ch. L un des principaux avantages est l amélioration de l accessibilité. Les utilisateurs peuvent accéder à l application facilement via leur navigateur, sans avoir à télécharger une application séparée. Cela économise de l espace de stockage sur l appareil et facilite l accès.', // template



 };
