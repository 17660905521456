export default {
  'logout.success': 'Logout success', // Du hast dich erfolgreich abgemeldet.

  'common.banner.title': 'Advertising on escort.ch', // template
  'common.yes': 'Yes', // template
  'common.no': 'No', // template
  
  'form.required': 'Mandatory field', // template
  'form.textPlaceholder': 'Please enter', // template
  'block.support.title': 'Do you have questions?', // template
  'modal.sure': 'Are you sure?', // template
  


  'footer.link.profile': 'Profile prices', // profile prices
  'footer.link.banner': 'Banner prices', // template
  'footer.link.support': 'Support', // template
  'footer.link.faq': 'Faq', // template
  'footer.link.login': 'Login', // template
  'footer.opening.row1': 'Mon-Fri 09:00 to 21:00', // template
  'footer.opening.row2': 'Sat/Sun 10:00 a.m. to 3:00 p.m', // template
  'footer.opening.text': 'WhatsApp and emails will be processed again at 10:00 p.m.', // template
  'footer.legal.compliance': 'Notice of compliance  ', // template
  'footer.legal.refund': 'Refund policy  ', // template
  'footer.legal.gtc': 'GTC', // template
  'footer.legal.privacy': 'Privacy', // template
  'footer.legal.imprint': 'Imprint', // template







  'iconbar.btn.video.profile': 'View profile  ', // template
  'iconbar.btn.video.close': 'Close', // template




  'noticeicon.title': 'Messages', // template
  'noticeicon.btn.unread': 'Mark all as read', // template




  
  'regionfiltertags.title': 'Sex contacts in the following places', // template





  'rightcontent.tooltip.profile': 'Profiles', // template
  'rightcontent.tooltip.banner': 'Banner', // template
  'rightcontent.tooltip.settings': 'Settings', // template
  'rightcontent.tooltip.logout': 'Logout', // template
  'rightcontent.btn.login': 'Create account / login', // template







  'premium.modal.title': 'Premium profiles at escort.ch', // template



  'verifyicon.tooltip': 'Verified', // template



  'wishlist.tooltip.remove': 'Remove from favorites', // template
  'wishlist.tooltip.add': 'Add to favorites', // template
  'wishlist.drawer.title': 'Favourites', // template




  
  'faq.page.title': 'Frequently asked questions', // template
  'faq.support.title': 'Your question was not included?', // template
  





  'home.meta.title': 'Erotic adventures in your area - escort.ch', // template
  'home.meta.description': '★★★ Erotic adventures in your area. ♥ Find your suitable escort! escort.ch ▷ The new sex & erotic portal in Switzerland.', // template
  'home.page.title': 'Erotic adventures in your area', // template
  'home.list.title': 'Matching results', // template
  'home.list.title.ready': 'Search, discover & enjoy', // template





  'home.filter.banner.divider': 'Filters are presented by',
  'home.filter.tag.field_expired': 'Back soon  ', // template
  'home.filter.tag.field_verified': 'Verified', // template
  'home.filter.tag.field_secret_gallery': 'Secret gallery', // template
  'home.filter.tag.field_hot': 'Hot', // template
  'home.filter.tag.field_video': 'Video', // template
  'home.filter.tag.field_stories': 'Story', // template
  'home.filter.tag.field_trans': 'Trans', // template
  'home.filter.tag.field_female': 'Female', // template
  'home.filter.location.error': 'You must allow location access in your browser to use this feature', // template
  'home.filter.btn.show': 'Filter', // template
  'home.filter.drawer.title': 'Filter', // template
  'home.filter.keyword.divider': 'Search', // template
  'home.filter.keyword.placeholder': 'Name or other search term...', // template
  'home.filter.popular_tags.divider': 'Popular tags', // template
  'home.filter.more.divider': 'More filters', // template
  'home.filter.field_regions.header': 'Regions', // template
  'home.filter.field_regions.option.region': 'Region / canton', // template
  'home.filter.field_regions.option.near': 'Perimeter', // template
  'home.filter.field_rubrics.header': 'Rubrics', // template
  'home.filter.field_offers.header': 'Services', // template
  'home.filter.field_age.header': 'Age', // template
  'home.filter.field_height.header': 'Height', // template
  'home.filter.field_weight.header': 'Weight', // template
  'home.filter.field_eye_color.header': 'Eye color', // template
  'home.filter.field_hair_color.header': 'Hair color', // template
  'home.filter.field_hair_length.header': 'Hair length', // template
  'home.filter.field_language.header': 'Languages', // template
  'home.filter.field_person_types.header': 'Ethnic group', // template
  'home.filter.field_cup_size.header': 'Cup size', // template
  'home.filter.field_physique.header': 'Physique', // template
  'home.filter.field_smoking.header': 'Smoking', // template
  'home.filter.field_shoe_size.header': 'Shoe size', // template
  'home.filter.field_intimate_area.header': 'Intimate area', // template
  'home.filter.field_body_features.header': 'Body features', // template
  'home.filter.geolocation.error': 'You must allow location access in your browser to use this feature', // template
  'home.filter.geolocation.radius.label': 'Perimeter', // template
  'profile.list.expired': 'is absent', // template








  'login.page.title': 'Login / create account', // template
  'login.register.title': 'Create your account on escort.ch', // template
  'login.register.text': 'Register at escort.ch. The modern escort platform in Switzerland.', // template
  'login.register.benefit01': 'Completely free registration', // template
  'login.register.benefit02': 'Discreet, secure & anonymous thanks to SSL encryption', // template
  'login.register.benefit03': 'Access to the Profile Manager', // template
  'login.register.benefit04': 'Escort.ch is a Swiss company with high quality standards', // template
  'login.register.benefit05': 'User-friendly interface and modern design', // template
  'login.register.button.label': 'Sign up now for free', // template
  'login.form.card.title': 'Login', // template
  'login.card.benefit.title': 'The advantages of escort.ch', // template
  'login.card.benefit.text': 'At escort.ch you benefit from the following advantages. Registration is completely free. Our website is characterized by a user-friendly interface and a modern design. We also place great importance on customer service and privacy to ensure a safe and positive environment for all our members.', // template
  'login.card.benefit.icon01': '300 credits given', // template
  'login.card.benefit.icon02': 'Discreet & safe', // template
  'login.card.benefit.icon03': 'Modern & innovative', // template
  'login.card.benefit.icon04': 'Several profiles possible', // template
  'login.message.success': 'Login successful.', // template
  'login.form.email.label': 'E-mail address', // template
  'login.form.password.label': 'Password', // template
  'login.button.passwordForget': 'Forgot Password', // template
  'login.message.passwordSuccess': 'Further instructions have been sent to your email address.', // template
  'login.passwordForget.success': 'Your new password has been saved. Please log in.', // template
  'login.validate.email': 'No valid email-Address', // template
  'login.activationCode': 'Activation code', // template
  'login.newPassword': 'New Password', // template
  'login.newPasswordConfirm': 'Confirm new password', // template
  'login.newPassword.error': 'The passwords do not match.', // template
  'login.message.passwordForget': 'If you forgot your password, you can reset it. Enter your email address below. We will send you an activation code.', // template
  'register.form.gtc': 'By registering, you accept our general terms and conditions.', // template
  'register.form.privacy': 'By signing up, you accept our Privacy Policy.', // template
  'register.form.newsletter': 'Subscribe to Newsletter', // template
  'register.form.confirm.title': 'We have sent you the confirmation code via email. Please enter this to complete the registration.', // template
  'register.form.btn.back': 'Back', // template











  'payment.page.cancel.title': 'Payment aborted', // template
  'payment.page.cancel.subtitle': 'Please try again or contact support', // template
  'payment.page.cancel.button.credits': 'Buy credits', // template
  'payment.page.cancel.button.support': 'Support', // template
  'payment.page.confirm.title': 'Payment successful', // template
  'payment.page.confirm.subtitle': 'Have fun with your credits.', // template
  'payment.page.confirm.button.profile': 'To the profile manager', // template
  'payment.page.error.title': 'Payment failed', // template
  'payment.page.error.subtitle': 'Please try again or contact support', // template








  'price.profile.page.title': 'Profile prices', // template
  'price.profile.card.title': 'The main advantage', // template
  'price.profile.btn.create': 'Create a profile now', // template
  'price.banner.page.title': 'Banner prices', // template
  'price.banner.card.title': 'Important information', // template
  'price.banner.btn.creat': 'Create banners now', // template

  'profile.navigation.prev': 'Previous profile', // template
  'profile.navigation.next': 'Next profile', // template







  'support.form.succes': 'Your request has been sent.', // template
  'support.form.name': 'Your name', // template
  'support.form.email': 'E-mail', // template
  'support.form.phone': 'Phone', // template
  'support.form.message': 'Your message', // template






  'profile.report.btn': 'Report profile', // template
  'profile.share.btn': 'Share profile', // template
  'profile.report.success': 'Thank you for your message.', // template
  'profile.report.field_reason': 'Reason for reporting', // template
  'profile.report.body': 'Message', // template
  'profile.card.about.title': 'About me', // template
  'profile.text.more': 'Read more', // template
  'profile.text.less': 'Read less', // template
  'profile.translate.btn': 'Translate', // template
  'profile.card.data.title': 'Details', // template
  'profile.card.data.field_hair_color': 'Hair', // template
  'profile.card.data.field_body_features': 'Characteristics', // template
  'profile.card.data.field_physique': 'Physique', // template
  'profile.card.data.field_height': 'Height', // template
  'profile.card.data.field_weight': 'Weight', // template
  'profile.card.data.field_cup_size': 'Cup size', // template
  'profile.card.data.field_smoking': 'Smoking', // template
  'profile.card.data.field_intimate_area': 'Intimate area', // template
  'profile.card.data.field_shoe_size': 'Shoe size', // template
  'profile.field_website': 'Visit website', // template
  'profile.message.sms': ', i found you on escort.ch', // template
  'profile.message.whatsapp': '', // template
  'profile.secretgallery.message.hello': 'Hello ', // template
  'profile.secretgallery.modal.title': 'A pin code is required to access the images in the Secret Gallery.', // template
  'profile.secretgallery.title': 'Please use the contact options listed below to request this pin code.', // template
  'profile.secretgallery.message.sms': ' i would like the pin for your Secret Gallery on escort.ch.', // template
  'profile.secretgallery.message.error': 'The PIN entered was not correct.', // template


  'user.profile.updateRegionRubric.modal.success': 'Your regions and headings have been updated.',
  'user.profile.updateRegionRubric.modal.alert.region':'Update your regions now.',
  'user.profile.updateRegionRubric.modal.alert.rubric':'Update your rubrics now.',






  'user.banner.page.title': 'Banner Manager', // template
  'user.banner.btn.create': 'Create banner', // template
  'user.banner.btn.edit': 'Edit banner', // template
  'user.banner.form.success': 'Banner saved.', // template
  'user.banner.field_banner_size': 'Type', // template
  'user.banner.field_banner_clicks': 'Clicks', // template
  'user.banner.delete.success': 'Banner has been deleted', // template
  'user.banner.renew.success': 'Banner has been renewed', // template
  'user.banner.column.totalClicks': 'Clicks total', // template
  'user.banner.column.field_banner_clicks': 'Click Quota', // template
  'user.banner.btn.renew': 'Renew', // template
  'user.banner.btn.delete': 'Delete', // template
  'user.banner.filter.field_all': 'All', // template
  'user.banner.filter.field_active': 'Active', // template
  'user.banner.filter.field_expired': 'Expired', // template
  'user.banner.filter.field_review': 'Review', // template
  'user.banner.filter.field_decline': 'Declined', // template
  'user.banner.filter.field_deleted': 'Deleted', // template
  "user.banner.filter.field_draft": "Draft",


  "banner.visit.website": "Visit website",









  'user.credits.page.title': 'Current account balance: ', // template
  'user.credits.form.card.title': 'Buy credits', // template
  'user.credits.form.btn.payonline': 'Pay online', // template
  'user.credits.swissbill.additionalInformation': 'Invoice number: ', // template
  'user.credits.swissbill.btn': 'Pay by bank transfer', // template
  'user.credits.swissbill.btn.print': 'Print', // template
  'user.credits.swissbill.data.headline': 'Transfer details', // template
  'user.credits.swissbill.data.kontonummer': 'Account no.:', // template
  'user.credits.swissbill.data.additionalInformation': 'Additional info:', // template
  'user.credits.swissbill.data.refnr': 'Ref. Nr.:', // template
  'user.credits.swissbill.data.amount': 'Amount', // template
  'user.credits.swissbill.data.text': 'As soon as we have received the payment, we will credit the amount to your account.', // template
  'user.credits.swissbill.data.kontoinhaber': 'Account owner:', // template
  'user.credits.swissbill.data.bankkonto': 'Bank account:', // template
  'user.credits.swissbill.data.bank': '', // template
  'user.credits.swissbill.data.bank': 'Bank', // template
  'user.credits.swissbill.data.subject': 'Transfer subject:', // template
  'user.credits.table.headerTitle': 'Transactions', // template
  'user.credits.table.id': 'Transaction-ID', // template
  'user.credits.table.title': 'Type', // template
  'user.credits.table.created': 'Date', // template
  'user.credits.form.gtc': 'I confirm that I have read the terms and conditions and accept them. I also confirm that I am over 18 years old.', // template
  'user.credits.form.gtc.link': 'Link to our GTC', // template

  'user.credits.form.privacy': 'I confirm that I have read and accept the privacy policy.', // template
  'user.credits.form.privacy.link': 'Link to our privacy policy', // template
  

  'user.credits.address.modal.title': 'Billing address', // template
  'user.credits.address.modal.field_firstname': 'Firstname', // template
  'user.credits.address.modal.field_lastname': 'Lastname', // template
  'user.credits.address.modal.field_street': 'Street', // template
  'user.credits.address.modal.field_zip': 'ZIP', // template
  'user.credits.address.modal.field_city': 'City', // template









  'user.settings.page.title': 'Settings', // template
  'user.settings.field_web_push_subscriptions.title': 'Push notifications', // template
  'user.settings.field_web_push_subscriptions.description': 'Would you like to be informed directly via push notifications?', // template
  'user.settings.field_allow_notifications.title': 'Messages', // template
  'user.settings.field_allow_notifications.description': 'Do you want to be informed about all changes?', // template
  'user.settings.field_newsletter_subscription.title': 'Would you like to receive a newsletter?', // template
  'user.settings.password.success': 'Your password has been changed.', // template
  'user.settings.user.success': 'Your data has been changed.', // template
  'user.settings.user.divider': 'User', // template
  'user.settings.field_phone.tooltip': 'For international numbers, please include the country code (+xx).', // template
  'user.settings.field_user_notification_type.label': 'Notify by', // template
  'user.settings.password.divider': 'Change password', // template
  'user.settings.current_password': 'Current password', // template
  'user.settings.user.tabs.user.title': 'Personal Data', // template




  'user.profile.form.success': 'Your profile has been saved.', // template

  'user.profile.page.title': 'Profile Manager', // template
  'user.profile.vacation.modal.title': 'Inform your customers about your absence', // template
  'user.profile.vacation.modal.text': 'Keep your visitors informed by indicating how long you will be away. Your inactive ad will remain visible on our portal for a further 14 days, but images and contact details will be hidden.', // template
  'user.profile.vacation.modal.success': 'Your vacation has been saved.', // template
  'user.profile.vacation.modal.field_vacations.label': 'Current vacation:', // template
  'user.profile.vacation.modal.btn.delete': 'Delete vacation', // template
  'user.profile.upvote.modal.title': 'Run upvotes automatically for 5x more clicks', // template
  'user.profile.upvote.modal.success': 'Your upvote schedule has been saved.', // template
  'user.profile.upvote.modal.text': 'Set up to five times a day when your listing will be promoted to the first position (5 credits per upvote).', // template
  'user.profile.upvote.modal.field_upvote_scheduler': 'Add time', // template
  'user.profile.upvote.modal.hour': 'Hour', // template
  'user.profile.upvote.modal.hour.rule.01': 'Numbers only (00-23).', // template
  'user.profile.upvote.modal.hour.rule.02': 'Minimum 2 characters', // template
  'user.profile.upvote.modal.minute.rule.01': 'Numbers only (00-59).', // template
  'user.profile.upvote.modal.minute.rule.02': 'Minimum 2 characters', // template
  'user.profile.upvote.modal.minute': 'Minute', // template
  'user.profile.premium.modal.title': 'Premium upgrade for 30 credits.', // template
  'user.profile.premium.modal.success': 'Your premium upgrade has been completed.', // template
  'user.profile.premium.modal.field_valid_range': 'Date', // template
  'user.profile.premium.modal.empty_slots': 'We have no free premium upgrades available for this date.', // template
  'user.profile.support.btn.close': 'Mark as done', // template
  'user.profile.support.subject': 'Subject', // template
  'user.profile.support.comment_body': 'Message', // template
  'user.profile.story.modal.add.image': 'Create picture story', // template
  'user.profile.story.modal.add.video': 'Create video story', // template
  'user.profile.story.modal.form.success': 'Your story has been created.', // template
  'user.profile.story.modal.video.spinner': 'We are currently preparing your video. Please be patient.', // template
  'user.profile.story.modal.list.divider': 'My stories', // template
  'user.profile.story.modal.list.delete': 'Delete', // template
  'user.profile.story.modal.list.renew': 'Renew', // template
  'user.profile.story.modal.list.field_approved': 'Approved', // template
  'user.profile.story.modal.list.field_review': 'Review', // template
  'user.profile.story.modal.list.field_expired': 'Expired', // template
  'user.profile.secreetgallery.modal.field_review': 'Review', // template
  'user.profile.secreetgallery.modal.field_approved': 'Approved', // template
  'user.profile.secreetgallery.modal.text': 'Upload unique images and set an individual PIN code to grant access to your Secret Gallery only to selected visitors of your choice.', // template
  'user.profile.secreetgallery.modal.success': 'Your SecretGallery has been saved', // template
  'user.profile.secreetgallery.modal.btn.delete': 'Delete', // template
  'user.profile.renew.modal.title': 'Renew profile', // template
  'user.profile.renew.modal.success': 'Your profile has been renewed', // template
  'user.profile.renew.modal.field_start_type': 'Show profile', // template
  'user.profile.renew.modal.field_start_type.now': 'Now', // template
  'user.profile.renew.modal.field_start_type.date': 'At date', // template
  'user.profile.renew.modal.field_valid_range': 'Date / time', // template
  'user.profile.list.upvote.add': 'Book upvote for 5 credits?', // template
  'user.profile.list.upvote.success': 'Your upvote has been set.', // template
  'user.profile.list.clone.success': 'Your profile has been duplicated', // template
  'user.profile.list.field_all': 'All', // template
  'user.profile.list.field_active': 'Active', // template
  'user.profile.list.field_planned': 'Planned', // template
  'user.profile.list.field_expired': 'Expired', // template
  'user.profile.list.field_review': 'Review', // template
  'user.profile.list.field_decline': 'Declined', // template
  'user.profile.list.field_deleted': 'Deleted', // template
  "user.profile.list.field_draft": "Draft",
  'user.profile.list.menu.show': 'Show', // template
  'user.profile.list.menu.edit': 'Edit', // template
  'user.profile.list.menu.clone': 'Clone', // template
  'user.profile.list.menu.delete': 'Delete', // template
  'user.profile.list.menu.renew': 'Renew', // template
  'user.profile.list.menu.autorenew.enable': 'Enable auto renew', // template
  'user.profile.list.menu.autorenew.disable': 'Disable auto renew', // template
  'user.profile.list.menu.upvote.add': 'Book', // template
  'user.profile.list.menu.upvote.schedule': 'Schedule', // template
  'user.profile.list.menu.vacation': 'Absence', // template
  'user.profile.list.menu.secret_gallery': 'Secret gallery', // template
  'user.profile.list.menu.regionRubric': 'Regions & Rubrics', // template
  'user.profile.list.status.field_review': 'Review', // template
  'user.profile.list.status.field_approved': 'Approved', // template
  'user.profile.list.status.field_auto_renew': 'Auto renew', // template
  'user.profile.list.status.field_decline': 'Declined', // template
  'user.profile.list.status.field_expired': 'Expired', // template
  'user.profile.list.status.field_top_profile': 'Premium upgrade', // template
  'user.profile.list.status.field_deleted': 'Deleted', // template
  'user.profile.list.status.field_review_support': 'Support ticket', // template
  'user.profile.list.status.field_vip': 'Vip', // template
  'user.profile.edit': 'Edit profile', // template
  'user.profile.create': 'Create profile', // template
  'user.profile.drawer.title': 'Profile manager', // template
  'user.profile.edit.field_age_verification_images.divider': 'Age verification', // template
  'user.profile.edit.image_verification_images.divider': 'Image verification', // template
  'user.profile.edit.field_age_verification_images.strong': 'Three images are required for verification:', // template
  'user.profile.edit.field_age_verification_images.text01.title': 'Official Documents', // template
  'user.profile.edit.field_age_verification_images.text01.text': 'Well-lit picture of your official document (drivers license, passport or ID card', // template
  'user.profile.edit.field_age_verification_images.text02.title': 'Your photo with ID card', // template
  'user.profile.edit.field_age_verification_images.text02.text': 'Photo of you holding passport/national ID close to your face (same document used for previous photo) - both your face and the data on the document must be visible.', // template
  'user.profile.edit.field_age_verification_images.text03.title': 'Confirmation picture', // template
  'user.profile.edit.field_age_verification_images.text03.text': 'A photo with you and a piece of paper containing todays date and the words "escort.ch".', // template
  'user.profile.edit.field_cover.divider': 'Cover image', // template
  'user.profile.edit.field_cover.alert': 'Please select an image that best presents your profile.', // template
  'user.profile.edit.field_cover.private': 'Display as secret photo (will be blurred for visitors)', // template
  'user.profile.edit.field_gallery.divider': 'Gallery', // template
  'user.profile.edit.field_gallery.alert': 'You can upload up to 9 pictures. For more privacy use the private gallery.', // template
  'user.profile.edit.field_gallery.private': 'Private gallery', // template
  'user.profile.edit.field_gallery.public': 'Public gallery', // template
  
  'user.profile.edit.field_video.divider': 'Video', // template
  'user.profile.edit.field_video.alert': 'Videos give customers a better insight into your services. Show yourself in the best light!', // template
  'user.profile.edit.field_video.encoding.alert': 'We are currently preparing your video. Come back later.', // template
  'user.profile.edit.field_video.btn.delete': 'Delete video', // template
  'user.profile.edit.field_video.btn.help': 'Technical Tips', // template
  'user.profile.edit.field_video.help.text01': 'Make sure you have good light for the video', // template
  'user.profile.edit.field_video.help.text02': 'The video must not be larger than 100 MB', // template
  'user.profile.edit.field_video.help.text03': 'All common formats are supported', // template
  'user.profile.edit.field_video.help.text04': 'The maximum length of the video is 60 seconds', // template
  'user.profile.form.address.placeholder': 'Enter street and number to search and fill in the address automatically', // template
  'user.profile.form.costs.spinner': 'Calculating costs...', // template
  'user.profile.form.step.category_location': 'Regions & rubrics', // template
  'user.profile.form.step.field_profile_type.divider': 'Duration', // template
  'user.profile.form.field_regions.divider': 'Regions', // template
  'user.profile.form.field_regions.alert': 'First region free, additional region +5 credits.', // template
  'user.profile.form.field_rubrics.alert': 'First rubric free, additional rubric +5 credits.', // template
  'user.profile.form.field_rubrics.divider': 'Rubrics', // template
  'user.profile.form.step.media.title': 'Photos & Videos', // template
  'user.profile.form.step.base.title': 'Base', // template
  'user.profile.form.step.base.divider': 'Basic information', // template
  'user.profile.form.step.base.alert': 'Basic information of your profile.', // template
  'user.profile.form.field_auto_renew': 'Would you like your profile to be automatically renewed?', // template
  'user.profile.form.field_name': 'Display name', // template
  'user.profile.form.title': 'Title', // template
  'user.profile.form.field_text': 'Text', // template
  'user.profile.form.location.divider': 'Your location', // template
  'user.profile.form.location.alert': 'Your address will later be used for the area search.', // template
  'user.profile.form.location.input.label': 'Address', // template
  'user.profile.form.address.placeholder': 'Enter street and number to search and fill in the address automatically', // template
  'user.profile.form.address.button.delete': 'Delete address', // template
  'user.profile.form.location.field_hide_address': 'Hide address', // template
  'user.profile.form.location.field_zip': 'Zip', // template
  'user.profile.form.location.field_city': 'City', // template
  'user.profile.form.location.field_country': 'Country', // template
  'user.profile.form.contact.divider': 'Contact', // template
  'user.profile.form.contact.alert': 'How to contact you', // template
  'user.profile.form.contact.field_phone': 'Phone', // template
  'user.profile.form.contact.field_contact_type': 'Contact via', // template
  'user.profile.form.contact.field_website': 'Website', // template
  'user.profile.form.contact.field_instagram': 'Instagram', // template
  'user.profile.form.contact.field_onlyfans': 'Onlyfans', // template
  'user.profile.form.contact.field_telegram': 'Telegram', // template
  'user.profile.form.steps.details.title': 'Details', // template
  'user.profile.form.field_language.divider': 'Languages', // template
  'user.profile.form.field_language.alert': 'Choose the languages ​​you speak.', // template
  'user.profile.form.field_offers.divider': 'Services', // template
  'user.profile.form.field_offers.alert': 'Select the services you offer.', // template
  'user.profile.form.field_offers.btn.selectall': 'Select all', // template
  'user.profile.form.field_offers.btn.selectnone': 'Deselect all', // template
  'user.profile.form.field_body_features.divider': 'Body features', // template
  'user.profile.form.field_body_features.alert': 'If you have other body characteristics, please select them here.', // template
  'user.profile.form.field_gender.divider': 'Information about you', // template
  'user.profile.form.field_gender.alert': 'We use this information for our filters.', // template
  'user.profile.form.field_age': 'Age', // template
  'user.profile.form.field_gender': 'Gender', // template
  'user.profile.form.field_cup_size': 'Cup size', // template
  'user.profile.form.field_eye_color': 'Eye color', // template
  'user.profile.form.field_hair_color': 'Hair color', // template
  'user.profile.form.field_hair_length': 'Hair length', // template
  'user.profile.form.field_height': 'Height', // template
  'user.profile.form.field_person_types': 'Ethnic group', // template
  'user.profile.form.field_physique': 'Physique', // template
  'user.profile.form.field_shoe_size': 'Show size', // template
  'user.profile.form.field_intimate_area': 'Intimate area', // template
  'user.profile.form.field_smoking': 'Smoking', // template
  'user.profile.form.field_weight': 'Weight', // template
  'user.profile.form.step.age_verification': 'Age verification', // template
  'user.profile.edit.field_video.thumbnail.spinnner': 'We are currently preparing your video. You can continue editing your profile.', // template



  'status.message.alert.created': 'Your status message will be checked.', // template
  'status.message.alert.deleted': 'Your status message was deleted', // template
  'status.message.title': 'Status messages', // template
  'status.message.autorefresh': 'AutoRefresh', // template
  'status.message.label.profile': 'Profile', // template
  'status.message.label.body': 'Your message', // template
  'status.message.button.loadmore': 'Show more', // template
  'status.message.banner.title': 'Status messages are presented by', // template
  'profile.statusmessenger.header': 'Last status message',




  'blog.category.label': 'Blog categories', // template
  
  


  'modal.updater': 'New update available.', // template
  'user.profile.renew.modal.additionalCosts': 'incl. regions & categories', 
  'filterpagetags.title': 'Interesting links', // template
  'filter.button.more': 'Show more profiles', // template 



  
  'blog.meta.title': 'Our blog for erotic adventures - escort.ch', // template
  'blog.meta.description': '★★★ Our blog for erotic adventures in your area. ♥ Find your perfect escort! escort.ch ▷ The new sex & erotic portal in Switzerland.', // template


  'faq.meta.title': 'Your questions answered: Comprehensive FAQ', // template
  'faq.meta.description': '★★★ FAQ for erotic adventures in your area. ♥ Find your perfect escort! escort.ch ▷ The new sex & erotic portal in Switzerland.', // template

  'login.meta.title': 'Lets go! Log in & dive in', // template
  'login.meta.description': '★★★ Your login for erotic adventures in your area. ♥ Find your suitable escort! escort.ch ▷ The new sex & erotic portal in Switzerland.', // template


  'support.meta.title': 'Support Center for further questions', // template
  'support.meta.description': '★★★ Our support for erotic adventures in your area. ♥ Find your suitable escort! escort.ch ▷ The new sex & erotic portal in Switzerland.', // template


  'pwainstaller.title': 'Install the escort.ch app', // template
  'pwainstaller.description': 'The PWA (Progressive Web App) of escort.ch offers numerous advantages that significantly improve the experience on escort.ch. One of the main benefits is improved accessibility. Users can easily access the application through their browser, without the need to download a separate app. This saves storage space on the device and facilitates access.', // template





  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
  'template': '', // template
};
