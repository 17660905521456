import { useState, useEffect } from 'react';

import Helper from '@/helper/Helper';


export default () => {

  useEffect(() => {
    //let storiesViewedStorage = Helper.getItem('storiesViewed');
   



    try {
      let wishlistItemsStorage = Helper.getSessionStorageItem('wishlistItems');
      //console.log(wishlistItemsStorage);
      if(wishlistItemsStorage) {
        wishlistItemsStorage = JSON.parse(wishlistItemsStorage);
        setWishlistItems(wishlistItemsStorage);
      }
    } catch (e) {
      Helper.setSessionStorageItem("wishlistItems", []);
      return console.error(e); // error in the above string (in this case, yes)!
    }

  }, []);


  const [wishlistItems, setWishlistItems] = useState([]);
  const [showWishlist, setShowWishlist] = useState(false);



  const setWishlistItemsStorage = (state) => {
    //console.log(state);
    //console.log(JSON.stringify(state));
    Helper.setSessionStorageItem("wishlistItems", JSON.stringify(state));
  }




  return { wishlistItems, setWishlistItems, showWishlist, setShowWishlist, setWishlistItemsStorage };
};
