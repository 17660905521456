import React, { useState, useEffect } from 'react';
import { Button } from 'antd';


import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as AllBrandsIcons from '@fortawesome/free-brands-svg-icons';


import { useIntl } from 'umi';

import ReactGA from "react-ga4";

// You need to import the CSS only once
import './assets/css/index.css';



const PWAInstaller = (props) => {


  const intl = useIntl();

  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

  //console.log(pwaInstall);


  const handleClick = () => {

    ReactGA.event({
      category: "pwa",
      action: "pwa_install",
      //label: "credits error", // optional
      //value: 99, // optional, must be a number
      //nonInteraction: true, // optional, true/false
      //transport: "xhr", // optional, beacon/xhr/image
    });

    pwaInstall({
      title: 
      intl.formatMessage({ id: 'pwainstaller.title', defaultMessage: 'escort.ch App installieren' }),
      logo: '',
      
      description: 
      intl.formatMessage({ id: 'pwainstaller.description', defaultMessage: 'Die PWA (Progressive Web App) von escort.ch bietet zahlreiche Vorteile, die das escort.ch Erlebniss erheblich verbessern. Einer der Hauptvorteile ist die verbesserte Zugänglichkeit. Nutzer können die Anwendung einfach über ihren Browser aufrufen, ohne eine separate App herunterladen zu müssen. Dies spart Speicherplatz auf dem Gerät und erleichtert den Zugriff.' })
    });
      //.then(() => alert("App installiert"))
   // .catch(() => alert("User opted out from installing"));
  };


  return (
    <>
    {supported() && !isInstalled() && (
    <a href={'#'} onClick={handleClick} title="App">
      <Button size="large" type="text" title="App">
        <FontAwesomeIcon icon={AllBrandsIcons.faAppStore} />
      </Button>
    </a> )}
    </>
  );
};

export default PWAInstaller;
