//console.log('defaultSettings');

const Settings = {
  //"navTheme": "light",
  "navTheme": "light",
  "primaryColor": "#34302e",
  "layout": "top",
  "contentWidth": "Fluid",
  "disableContentMargin": true,
  "fixedHeader": true,
  "fixSiderbar": true,
  "pwa": true,
  "logo": "/images/mailLogo.png",
  "headerHeight": 48,
  "splitMenus": true,
  "headerRender": true,
  "footerRender": true,
  "disableMobile": true
};
export default Settings;
