import { ProTable } from '@ant-design/pro-components';
import { Button } from 'antd';
import { useRef, useState } from 'react';
import { getNotification } from '@/services/ant-design-pro/api';
import { useModel, useIntl } from 'umi';

import NotificationListItem from './NotificationListItem';

const columns = [
  {
    title: 'Titel',
    dataIndex: 'title',
    render: (_, record) => {
      //console.log(_, record);
      let node = record[1];
      return <>
        <NotificationListItem key={'notice-item'+node.nid[0].value} node={node} />
      </>;
    },
    sorter: false
  }
];
export default () => {

  const intl = useIntl();



  const { notifications, setNotifications } = useModel('notifications', (ret) => ({
    notifications: ret.notifications,
    setNotifications: ret.setNotifications,
  }));


  const [loading, setLoading] = useState(false);

  const readAllNotification = async () => {

    setLoading(true);
    //&setEllipsis(!ellipsis)
    let result = await getNotification({ action: 'readAllAction' }, {});
    //console.log(result);
    setNotifications(result.unread);
    setLoading(false);
    if(actionRef && actionRef.current) {
      actionRef.current.reload();
    }
    
    

    if ('setAppBadge' in navigator) {
      navigator.clearAppBadge();
    }



  }


  const actionRef = useRef();
  return (

    <div className="notification-table-wrapper">

      <ProTable
        className="notification-table"
        columns={columns}

        actionRef={actionRef}

        cardBordered

        headerTitle={<><Button loading={loading} type="primary" className="btn-read-all" onClick={() => readAllNotification()}>{intl.formatMessage({ id: 'noticeicon.btn.unread', defaultMessage: 'Alle als gelesen markieren' })}</Button></>}


        request={async (params = {}, sort, filter) => {
          //console.log(params, sort, filter);
          params.field_sort = Object.keys(sort)[0];
          params.sort_direction = Object.values(sort)[0];
          params.action = 'listAction';
          let result = await getNotification(params, {});
          //console.log(result);

          return {
            data: Object.entries(result.data),
            // Please return true for success.
            // otherwise the table will stop parsing the data, even if there is data
            success: result.success,
            // not passed will use the length of the data, if it is paged you must pass
            total: result.total,
          };

        }}
        options={{
          setting: false,
          density: false
        }}
        rowKey="uuid"

        search={false}

        pagination={{
          simple: true,
          pageSize: 5,
          showSizeChanger: false,
          showTotal: false,
        }}

        dateFormatter="string"

      />
    </div>
  )
};
