import { Component } from 'react';

import { EncryptStorage } from 'encrypt-storage';

import Helper from '@/helper/Helper';

const secretKey = 'lp9bx22qkfex7wd5asuxbstzfsb9ucs0';
const encryptStorage = new EncryptStorage(secretKey, {
  prefix: '@portal',
});


class Storage extends Component {
  constructor(props) {
    super(props);
  }

  static setItem(key, value) {
    encryptStorage.setItem(key, value);
    //localStorage.setItem(key, value);
  }

  static removeItem(key, value) {
    encryptStorage.removeItem(key);
    //localStorage.setItem(key, value);
  }

  static getItem(key) {
    try {
      const itemEncrypt = encryptStorage.getItem(key);
      return itemEncrypt;
    } catch(error) {
      //console.log(error);
      Helper.clearLocalStorage();
      return error;
    };
    //const item = localStorage.getItem(key);
    
  }
}

export default Storage;
