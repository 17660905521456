export default  {
  "logout.success": "Disconnessione riuscita",
  "common.banner.title": "Pubblicità su escort.ch",
  "common.yes": "SÌ",
  "common.no": "NO",
  "form.required": "Campo obbligatorio",
  "form.textPlaceholder": "Prego entra",
  "block.support.title": "avete domande?",
  "modal.sure": "Sei sicuro?",
  "footer.link.profile": "Prezzi del profilo",
  "footer.link.banner": "Prezzi all'insegna",
  "footer.link.support": "Supporto",
  "footer.link.faq": "FAQ",
  "footer.link.login": "Login",
  "footer.opening.row1": "Dal lunedì al venerdì dalle 09:00 alle 21:00",
  "footer.opening.row2": "Sab\/dom dalle 10:00 alle 15:00",
  "footer.opening.text": "WhatsApp e le e-mail verranno nuovamente elaborate alle 22:00",
  "footer.legal.compliance": " Avviso di conformità",
  "footer.legal.refund": " Politica di rimborso",
  "footer.legal.gtc": "CG",
  "footer.legal.privacy": "Riservatezza",
  "footer.legal.imprint": "Impronta",
  "iconbar.btn.video.profile": " Vedi profilo",
  "iconbar.btn.video.close": "Vicino",
  "noticeicon.title": "Messaggi",
  "noticeicon.btn.unread": "Segna tutti come letti",
  "regionfiltertags.title": "Contatti sessuali nei seguenti luoghi",
  "rightcontent.tooltip.profile": "Profili",
  "rightcontent.tooltip.banner": "Bandiera",
  "rightcontent.tooltip.settings": "Impostazioni",
  "rightcontent.tooltip.logout": "Disconnettersi",
  "rightcontent.btn.login": "Crea un account \/ accedi",
  "premium.modal.title": "Profili premium su escort.ch",
  "verifyicon.tooltip": "Verificato",
  "wishlist.tooltip.remove": "Rimuovi dai preferiti",
  "wishlist.tooltip.add": "Aggiungi ai preferiti",
  "wishlist.drawer.title": "Preferiti",
  "faq.page.title": "Domande frequenti",
  "faq.support.title": "La tua domanda non è stata inclusa?",
  "home.meta.title": "Avventure erotiche nella tua zona - escort.ch",
  "home.meta.description": "★★★ Avventure erotiche nella tua zona. ♥ Trova la tua scorta adatta! escort.ch ▷ Il nuovo portale di sesso ed erotismo in Svizzera.",
  "home.page.title": "Avventure erotiche nella tua zona",
  "home.list.title": "Risultati corrispondenti",
  'home.list.title.ready': 'Cerca, scopri e divertiti', // template
  'home.filter.banner.divider': 'I filtri sono presentati da',
  "home.filter.tag.field_expired": " Torno presto",
  "home.filter.tag.field_verified": "Verificato",
  "home.filter.tag.field_secret_gallery": "Galleria segreta",
  "home.filter.tag.field_hot": "Caldo",
  "home.filter.tag.field_video": "video",
  "home.filter.tag.field_stories": "Storia",
  "home.filter.tag.field_trans": "Trans",
  "home.filter.tag.field_female": "Femmina",
  "home.filter.location.error": "Devi consentire l'accesso alla posizione nel tuo browser per utilizzare questa funzione",
  "home.filter.btn.show": "Filtro",
  "home.filter.drawer.title": "Filtro",
  "home.filter.keyword.divider": "Ricerca",
  "home.filter.keyword.placeholder": "Nome o altro termine di ricerca...",
  "home.filter.popular_tags.divider": "Tag popolari",
  "home.filter.more.divider": "Più filtri",
  "home.filter.field_regions.header": "Regioni",
  "home.filter.field_regions.option.region": "Regione \/ cantone",
  "home.filter.field_regions.option.near": "Perimetro",
  "home.filter.field_rubrics.header": "Rubriche",
  "home.filter.field_offers.header": "Servizi",
  "home.filter.field_age.header": "Età",
  "home.filter.field_height.header": "Altezza",
  "home.filter.field_weight.header": "Peso",
  "home.filter.field_eye_color.header": "Colore degli occhi",
  "home.filter.field_hair_color.header": "Colore dei capelli",
  "home.filter.field_hair_length.header": "Lunghezza dei capelli",
  "home.filter.field_language.header": "Le lingue",
  "home.filter.field_person_types.header": "Gruppo etnico",
  "home.filter.field_cup_size.header": "dimensioni Coppa",
  "home.filter.field_physique.header": "Fisico",
  "home.filter.field_smoking.header": "Fumare",
  "home.filter.field_shoe_size.header": "Taglia di scarpe",
  "home.filter.field_intimate_area.header": "Zona intima",
  "home.filter.field_body_features.header": "Caratteristiche del corpo",
  "home.filter.geolocation.error": "Devi consentire l'accesso alla posizione nel tuo browser per utilizzare questa funzione",
  "home.filter.geolocation.radius.label": "Perimetro",
  "profile.list.expired": "è assente",
  "login.page.title": "Accedi \/ crea un account",
  "login.register.title": "Crea il tuo conto su escort.ch",
  "login.register.text": "Registrati su escort.ch. La moderna piattaforma di scorta in Svizzera.",
  "login.register.benefit01": "Registrazione completamente gratuita",
  "login.register.benefit02": "Discreto, sicuro e anonimo grazie alla crittografia SSL",
  "login.register.benefit03": "Accesso al Gestore profilo",
  "login.register.benefit04": "Escort.ch è un'azienda svizzera con elevati standard di qualità",
  "login.register.benefit05": "Interfaccia user-friendly e design moderno",
  "login.register.button.label": "Registrati subito gratuitamente",
  "login.form.card.title": "Login",
  "login.card.benefit.title": "I vantaggi di escort.ch",
  "login.card.benefit.text": "Su escort.ch beneficiate dei seguenti vantaggi. La registrazione è completamente gratuita. Il nostro sito Web è caratterizzato da un'interfaccia user-friendly e da un design moderno. Attribuiamo inoltre grande importanza al servizio clienti e alla privacy per garantire un ambiente sicuro e positivo per tutti i nostri membri.",
  "login.card.benefit.icon01": "300 crediti dati",
  "login.card.benefit.icon02": "Discreto e sicuro",
  "login.card.benefit.icon03": "Moderno e innovativo",
  "login.card.benefit.icon04": "Diversi profili possibili",
  "login.message.success": "Accesso riuscito.",
  "login.form.email.label": "Indirizzo e-mail",
  "login.form.password.label": "Parola d'ordine",
  "login.button.passwordForget": "Ha dimenticato la password",
  "login.message.passwordSuccess": "Ulteriori istruzioni sono state inviate al tuo indirizzo email.",
  "login.passwordForget.success": "La tua nuova password è stata salvata. Accedere prego.",
  "login.validate.email": "Nessun indirizzo email valido",
  "login.activationCode": "Codice di attivazione",
  "login.newPassword": "nuova password",
  "login.newPasswordConfirm": "Conferma la nuova password",
  "login.newPassword.error": "Le password non corrispondono.",
  "login.message.passwordForget": "Se hai dimenticato la password, puoi reimpostarla. Inserisci il tuo indirizzo email qui sotto. Ti invieremo un codice di attivazione.",
  "register.form.gtc": "Registrandoti, accetti i nostri termini e condizioni generali.",
  "register.form.privacy": "Iscrivendoti accetti la nostra Privacy Policy.",
  "register.form.newsletter": "Iscriviti alla Newsletter",
  "register.form.confirm.title": "Ti abbiamo inviato il codice di conferma via email. Inseriscilo per completare la registrazione.",
  "register.form.btn.back": "Indietro",
  "payment.page.cancel.title": "Pagamento interrotto",
  "payment.page.cancel.subtitle": "Riprova o contatta l'assistenza",
  "payment.page.cancel.button.credits": "Comprare crediti",
  "payment.page.cancel.button.support": "Supporto",
  "payment.page.confirm.title": "Pagamento andato a buon fine",
  "payment.page.confirm.subtitle": "Divertiti con i tuoi crediti.",
  "payment.page.confirm.button.profile": "Al gestore del profilo",
  "payment.page.error.title": "Pagamento fallito",
  "payment.page.error.subtitle": "Riprova o contatta l'assistenza",
  "price.profile.page.title": "Prezzi del profilo",
  "price.profile.card.title": "Il vantaggio principale",
  "price.profile.btn.create": "Crea un profilo ora",

  "profile.navigation.prev": "Profilo precedente", 
  "profile.navigation.next": "Profilo successivo", 

  "price.banner.page.title": "Prezzi all'insegna",
  "price.banner.card.title": "Informazioni importanti",
  "price.banner.btn.creat": "Crea banner ora",
  "support.form.succes": "La tua richiesta è stata inviata.",
  "support.form.name": "Il tuo nome",
  "support.form.email": "E-mail",
  "support.form.phone": "Telefono",
  "support.form.message": "Il tuo messaggio",
  "profile.report.btn": "Segnala profilo",
  "profile.share.btn": "Condividi profilo",
  "profile.report.success": "Grazie per il vostro messaggio.",
  "profile.report.field_reason": "Motivo della segnalazione",
  "profile.report.body": "Messaggio",
  "profile.card.about.title": "Su di me",
  "profile.text.more": "Per saperne di più",
  "profile.text.less": "Leggi di meno",
  "profile.translate.btn": "Tradurre",
  "profile.card.data.title": "Dettagli",
  "profile.card.data.field_hair_color": "Capelli",
  "profile.card.data.field_body_features": "Caratteristiche",
  "profile.card.data.field_physique": "Fisico",
  "profile.card.data.field_height": "Altezza",
  "profile.card.data.field_weight": "Peso",
  "profile.card.data.field_cup_size": "dimensioni Coppa",
  "profile.card.data.field_smoking": "Fumare",
  "profile.card.data.field_intimate_area": "Zona intima",
  "profile.card.data.field_shoe_size": "Taglia di scarpe",
  "profile.field_website": "Visita il sito web",
  "profile.message.sms": ", ti ho trovato su escort.ch",
  "profile.secretgallery.message.hello": " Ciao ",
  "profile.secretgallery.modal.title": "È necessario un codice pin per accedere alle immagini nella Galleria segreta.",
  "profile.secretgallery.title": "Si prega di utilizzare le opzioni di contatto elencate di seguito per richiedere questo codice pin.",
  "profile.secretgallery.message.sms": " vorrei la spilla per la tua Secret Gallery su escort.ch.",
  'profile.secretgallery.message.error': 'Il PIN inserito non è corretto.', // template

  'user.profile.updateRegionRubric.modal.success': 'Le regioni e le rubriche sono state aggiornate.',
  'user.profile.updateRegionRubric.modal.alert.region':'Aggiornate subito le vostre regioni.',
  'user.profile.updateRegionRubric.modal.alert.rubric':'Aggiornate subito le vostre rubriche.',

  "user.banner.page.title": "Gestore dello striscione",
  "user.banner.btn.create": "Crea striscione",
  "user.banner.btn.edit": "Modifica bandiera",
  "user.banner.form.success": "Bandiera salvata.",
  "user.banner.field_banner_size": "Tipo",
  "user.banner.field_banner_clicks": "Clic",
  "user.banner.delete.success": "Il banner è stato eliminato",
  "user.banner.renew.success": "Banner è stato rinnovato",
  "user.banner.column.totalClicks": "Clic totali",
  "user.banner.column.field_banner_clicks": "Fare clic su Quota",
  "user.banner.btn.renew": "Rinnovare",
  "user.banner.btn.delete": "Eliminare",
  "user.banner.filter.field_all": "Tutto",
  "user.banner.filter.field_active": "Attivo",
  "user.banner.filter.field_expired": "Scaduto",
  "user.banner.filter.field_review": "Revisione",
  "user.banner.filter.field_decline": "Rifiutato",
  "user.banner.filter.field_deleted": "Eliminato",


  "banner.visit.website": "Visita il sito web",

  "user.credits.page.title": " Saldo del conto corrente:",
  "user.credits.form.card.title": "Comprare crediti",
  "user.credits.form.btn.payonline": "Paga in linea",
  "user.credits.swissbill.additionalInformation": " Numero di fattura:",
  "user.credits.swissbill.btn": "Paga con bonifico bancario",
  "user.credits.swissbill.btn.print": "Stampa",
  "user.credits.swissbill.data.headline": "Dettagli del trasferimento",
  "user.credits.swissbill.data.kontonummer": "Conto n.:",
  "user.credits.swissbill.data.additionalInformation": "Informazioni addizionali:",
  "user.credits.swissbill.data.refnr": "Rif. Nr.:",
  "user.credits.swissbill.data.amount": "Quantità",
  "user.credits.swissbill.data.text": "Non appena avremo ricevuto il pagamento, accrediteremo l'importo sul tuo conto.",
  "user.credits.swissbill.data.kontoinhaber": "Titolare dell'account:",
  "user.credits.swissbill.data.bankkonto": "Conto bancario:",
  "user.credits.swissbill.data.bank": "Banca",
  "user.credits.swissbill.data.subject": "Oggetto del trasferimento:",
  "user.credits.table.headerTitle": "Transazioni",
  "user.credits.table.id": "ID transazione",
  "user.credits.table.title": "Tipo",
  "user.credits.table.created": "Data",
  'user.credits.form.gtc': 'Confermo di aver letto i termini e le condizioni e di accettarli. Confermo inoltre di avere più di 18 anni.', // template
  'user.credits.form.gtc.link': 'Link alle nostre CGC', // template

  
  'user.credits.form.privacy': 'Confermo di aver letto e accettato l informativa sulla privacy.', // template
  'user.credits.form.privacy.link': 'Link alla nostra informativa sulla privacy', // template

  'user.credits.address.modal.title': 'Indirizzo di fatturazione', // template
  'user.credits.address.modal.field_firstname': 'Nome di battesimo', // template
  'user.credits.address.modal.field_lastname': 'Cognome', // template
  'user.credits.address.modal.field_street': 'Strada', // template
  'user.credits.address.modal.field_zip': 'Codice Postale', // template
  'user.credits.address.modal.field_city': 'Città', // template

  "user.settings.page.title": "Impostazioni",
  "user.settings.field_web_push_subscriptions.title": "Le notifiche push",
  "user.settings.field_web_push_subscriptions.description": "Vuoi essere informato direttamente tramite notifiche push?",
  "user.settings.field_allow_notifications.title": "Messaggi",
  "user.settings.field_allow_notifications.description": "Vuoi essere informato su tutte le modifiche?",
  "user.settings.field_newsletter_subscription.title": "Vuoi ricevere una newsletter?",
  "user.settings.password.success": "La tua password è stata modificata.",
  "user.settings.user.success": "I tuoi dati sono stati modificati.",
  "user.settings.user.divider": "Utente",
  "user.settings.field_phone.tooltip": "Per i numeri internazionali, includi il prefisso internazionale (+xx).",
  "user.settings.field_user_notification_type.label": "Avvisa tramite",
  "user.settings.password.divider": "Cambiare la password",
  "user.settings.current_password": "Password attuale",
  "user.settings.user.tabs.user.title": "Dati personali",

  "user.profile.form.success": "Il vostro profilo è stato salvato.",

  "user.profile.page.title": "Gestore del profilo",
  "user.profile.vacation.modal.title": "Informa i tuoi clienti della tua assenza",
  "user.profile.vacation.modal.text": "Tieni informati i tuoi visitatori indicando per quanto tempo starai via. Il tuo annuncio inattivo rimarrà visibile sul nostro portale per altri 14 giorni, ma le immagini e i recapiti verranno nascosti.",
  "user.profile.vacation.modal.success": "La tua vacanza è stata salvata.",
  "user.profile.vacation.modal.field_vacations.label": "Vacanza attuale:",
  "user.profile.vacation.modal.btn.delete": "Elimina vacanza",
  "user.profile.upvote.modal.title": "Esegui automaticamente upvotes per 5 volte più clic",
  "user.profile.upvote.modal.success": "Il tuo programma di voti positivi è stato salvato.",
  "user.profile.upvote.modal.text": "Imposta fino a cinque volte al giorno quando la tua inserzione verrà promossa alla prima posizione (5 crediti per voto positivo).",
  "user.profile.upvote.modal.field_upvote_scheduler": "Aggiungi tempo",
  "user.profile.upvote.modal.hour": "Ora",
  "user.profile.upvote.modal.hour.rule.01": "Solo numeri (00-23).",
  "user.profile.upvote.modal.hour.rule.02": "Minimo 2 caratteri",
  "user.profile.upvote.modal.minute.rule.01": "Solo numeri (00-59).",
  "user.profile.upvote.modal.minute.rule.02": "Minimo 2 caratteri",
  "user.profile.upvote.modal.minute": "Minuto",
  "user.profile.premium.modal.title": "Aggiornamento premium per 30 crediti.",
  "user.profile.premium.modal.success": "Il tuo aggiornamento premium è stato completato.",
  "user.profile.premium.modal.field_valid_range": "Data",
  "user.profile.premium.modal.empty_slots": "Non sono disponibili upgrade premium gratuiti per questa data.",
  "user.profile.support.btn.close": "segna come fatto",
  "user.profile.support.subject": "Soggetto",
  "user.profile.support.comment_body": "Messaggio",
  "user.profile.story.modal.add.image": "Crea una storia per immagini",
  "user.profile.story.modal.add.video": "Crea una video storia",
  "user.profile.story.modal.form.success": "La tua storia è stata creata.",
  "user.profile.story.modal.video.spinner": "Attualmente stiamo preparando il tuo video. Per favore sii paziente.",
  "user.profile.story.modal.list.divider": "Le mie storie",
  "user.profile.story.modal.list.delete": "Eliminare",
  "user.profile.story.modal.list.renew": "Rinnovare",
  "user.profile.story.modal.list.field_approved": "Approvato",
  "user.profile.story.modal.list.field_review": "Revisione",
  "user.profile.story.modal.list.field_expired": "Scaduto",
  "user.profile.secreetgallery.modal.field_review": "Revisione",
  "user.profile.secreetgallery.modal.field_approved": "Approvato",
  "user.profile.secreetgallery.modal.text": "Carica immagini uniche e imposta un codice PIN individuale per concedere l'accesso alla tua Galleria segreta solo a visitatori selezionati di tua scelta.",
  "user.profile.secreetgallery.modal.success": "La tua SecretGallery è stata salvata",
  "user.profile.secreetgallery.modal.btn.delete": "Eliminare",
  "user.profile.renew.modal.title": "Rinnova il profilo",
  "user.profile.renew.modal.success": "Il tuo profilo è stato rinnovato",
  "user.profile.renew.modal.field_start_type": "Mostra profilo",
  "user.profile.renew.modal.field_start_type.now": "Ora",
  "user.profile.renew.modal.field_start_type.date": "Alla data",
  "user.profile.renew.modal.field_valid_range": "Appuntamento",
  "user.profile.list.upvote.add": "Prenota un voto positivo per 5 crediti?",
  "user.profile.list.upvote.success": "Il tuo voto positivo è stato impostato.",
  "user.profile.list.clone.success": "Il tuo profilo è stato duplicato",
  "user.profile.list.field_all": "Tutto",
  "user.profile.list.field_active": "Attivo",
  "user.profile.list.field_planned": "Pianificato",
  "user.profile.list.field_expired": "Scaduto",
  "user.profile.list.field_review": "Revisione",
  "user.profile.list.field_decline": "Rifiutato",
  "user.profile.list.field_deleted": "Eliminato",
  "user.profile.list.field_draft": "Bozza",
  "user.profile.list.menu.show": "Spettacolo",
  "user.profile.list.menu.edit": "Modificare",
  "user.profile.list.menu.clone": "Clone",
  "user.profile.list.menu.delete": "Eliminare",
  "user.profile.list.menu.renew": "Rinnovare",
  "user.profile.list.menu.autorenew.enable": "Abilita il rinnovo automatico",
  "user.profile.list.menu.autorenew.disable": "Disabilita il rinnovo automatico",
  "user.profile.list.menu.upvote.add": "Libro",
  "user.profile.list.menu.upvote.schedule": "Programma",
  "user.profile.list.menu.vacation": "Assenza",
  "user.profile.list.menu.secret_gallery": "Galleria segreta",
  "user.profile.list.menu.regionRubric": "Regioni e rubriche",
  "user.profile.list.status.field_review": "Revisione",
  "user.profile.list.status.field_approved": "Approvato",
  "user.profile.list.status.field_auto_renew": "Rinnovo Automatico",
  "user.profile.list.status.field_decline": "Rifiutato",
  "user.profile.list.status.field_expired": "Scaduto",
  "user.profile.list.status.field_top_profile": "Aggiornamento premium",
  "user.profile.list.status.field_deleted": "Eliminato",
  "user.profile.list.status.field_review_support": "Biglietto di supporto",
  "user.profile.list.status.field_vip": "Vip",
  "user.profile.edit": "Modifica Profilo",
  "user.profile.create": "Crea un profilo",
  "user.profile.drawer.title": "Gestore del profilo",
  "user.profile.edit.field_age_verification_images.divider": "Verifica dell'età",
  'user.profile.edit.image_verification_images.divider': 'Verifica dellimmagine', // template
  "user.profile.edit.field_age_verification_images.strong": "Per la verifica sono necessarie tre immagini:",
  "user.profile.edit.field_age_verification_images.text01.title": "Documenti ufficiali",
  "user.profile.edit.field_age_verification_images.text01.text": "Foto ben illuminata del tuo documento ufficiale (patente di guida, passaporto o carta d'identità",
  "user.profile.edit.field_age_verification_images.text02.title": "La tua foto con carta d'identità",
  "user.profile.edit.field_age_verification_images.text02.text": "Foto di te che tieni il passaporto\/documento d'identità vicino al tuo viso (stesso documento usato per la foto precedente) - sia il tuo viso che i dati sul documento devono essere visibili.",
  "user.profile.edit.field_age_verification_images.text03.title": "Foto di conferma",
  "user.profile.edit.field_age_verification_images.text03.text": "Una foto con te e un pezzo di carta contenente la data odierna e le parole \"escort.ch\".",
  "user.profile.edit.field_cover.divider": "Immagine di copertina",
  "user.profile.edit.field_cover.alert": "Seleziona un'immagine che meglio rappresenta il tuo profilo.",
  'user.profile.edit.field_cover.private': 'Visualizzazione come foto segreta (sarà sfocata per i visitatori)', // template
  "user.profile.edit.field_gallery.divider": "Galleria",
  "user.profile.edit.field_gallery.alert": "È possibile caricare fino a 9 immagini. Per una maggiore privacy, utilizzare la galleria privata.",
  'user.profile.edit.field_gallery.private': 'Galleria privata', // template
  'user.profile.edit.field_gallery.public': 'Galleria pubblica', // template
  "user.profile.edit.field_video.divider": "video",
  "user.profile.edit.field_video.alert": "I video offrono ai clienti una visione migliore dei tuoi servizi. Mostrati nella luce migliore!",
  "user.profile.edit.field_video.encoding.alert": "Attualmente stiamo preparando il tuo video. Torna più tardi.",
  "user.profile.edit.field_video.btn.delete": "Elimina video",
  "user.profile.edit.field_video.btn.help": "Suggerimenti tecnici",
  "user.profile.edit.field_video.help.text01": "Assicurati di avere una buona luce per il video",
  "user.profile.edit.field_video.help.text02": "Il video non deve superare i 100 MB",
  "user.profile.edit.field_video.help.text03": "Sono supportati tutti i formati comuni",
  "user.profile.edit.field_video.help.text04": "La durata massima del video è di 60 secondi",
  "user.profile.form.address.placeholder": "Inserisci via e numero civico per la ricerca e compila automaticamente l'indirizzo",
  "user.profile.form.address.button.delete": "Cancellare l indirizzo", 
  "user.profile.form.costs.spinner": "Calcolo dei costi...",
  "user.profile.form.step.category_location": "Regioni e rubriche",
  "user.profile.form.step.field_profile_type.divider": "Durata",
  "user.profile.form.field_regions.divider": "Regioni",
  "user.profile.form.field_regions.alert": "Prima regione gratis, regione aggiuntiva +5 crediti.",
  "user.profile.form.field_rubrics.alert": "Prima rubrica gratuita, rubrica aggiuntiva +5 crediti.",
  "user.profile.form.field_rubrics.divider": "Rubriche",
  "user.profile.form.step.media.title": "Foto e video",
  "user.profile.form.step.base.title": "Base",
  "user.profile.form.step.base.divider": "Informazioni di base",
  "user.profile.form.step.base.alert": "Informazioni di base del tuo profilo.",
  "user.profile.form.field_auto_renew": "Vuoi che il tuo profilo si rinnovi automaticamente?",
  "user.profile.form.field_name": "Nome da visualizzare",
  "user.profile.form.title": "Titolo",
  "user.profile.form.field_text": "Testo",
  "user.profile.form.location.divider": "La tua posizione",
  "user.profile.form.location.alert": "Il tuo indirizzo verrà successivamente utilizzato per la ricerca dell'area.",
  "user.profile.form.location.input.label": "Indirizzo",
  "user.profile.form.location.field_hide_address": "Nascondi indirizzo",
  "user.profile.form.location.field_zip": "Cerniera lampo",
  "user.profile.form.location.field_city": "Città",
  "user.profile.form.location.field_country": "Paese",
  "user.profile.form.contact.divider": "Contatto",
  "user.profile.form.contact.alert": "Come contattarti",
  "user.profile.form.contact.field_phone": "Telefono",
  "user.profile.form.contact.field_contact_type": "Contatto tramite",
  "user.profile.form.contact.field_website": "Sito web",
  "user.profile.form.contact.field_instagram": "Instagram",
  "user.profile.form.contact.field_onlyfans": "Solo fan",
  "user.profile.form.contact.field_telegram": "Telegramma",
  "user.profile.form.steps.details.title": "Dettagli",
  "user.profile.form.field_language.divider": "Le lingue",
  "user.profile.form.field_language.alert": "Scegli le lingue che parli.",
  "user.profile.form.field_offers.divider": "Servizi",
  "user.profile.form.field_offers.alert": "Seleziona i servizi che offri.",
  "user.profile.form.field_offers.btn.selectall": "Seleziona tutto",
  "user.profile.form.field_offers.btn.selectnone": "Deselezionare tutto",
  "user.profile.form.field_body_features.divider": "Caratteristiche del corpo",
  "user.profile.form.field_body_features.alert": "Se hai altre caratteristiche fisiche, selezionale qui.",
  "user.profile.form.field_gender.divider": "Informazioni su di te",
  "user.profile.form.field_gender.alert": "Utilizziamo queste informazioni per i nostri filtri.",
  "user.profile.form.field_age": "Età",
  "user.profile.form.field_gender": "Genere",
  "user.profile.form.field_cup_size": "dimensioni Coppa",
  "user.profile.form.field_eye_color": "Colore degli occhi",
  "user.profile.form.field_hair_color": "Colore dei capelli",
  "user.profile.form.field_hair_length": "Lunghezza dei capelli",
  "user.profile.form.field_height": "Altezza",
  "user.profile.form.field_person_types": "Gruppo etnico",
  "user.profile.form.field_physique": "Fisico",
  "user.profile.form.field_shoe_size": "Mostra dimensioni",
  "user.profile.form.field_intimate_area": "Zona intima",
  "user.profile.form.field_smoking": "Fumare",
  "user.profile.form.field_weight": "Peso",
  "user.profile.form.step.age_verification": "Verifica dell'età",
  "user.profile.edit.field_video.thumbnail.spinnner": "Attualmente stiamo preparando il tuo video. Puoi continuare a modificare il tuo profilo.",



  'status.message.alert.created': 'Il vostro messaggio di stato viene controllato.', // template
  'status.message.alert.deleted': 'Il tuo messaggio di stato è stato cancellato', // template
  'status.message.title': 'Messaggi di stato', // template
  'status.message.autorefresh': 'AutoRefresh', // template
  'status.message.label.profile': 'Profilo', // template
  'status.message.label.body': 'Il vostro messaggio', // template
  'status.message.button.loadmore': 'Mostra di più', // template
  'status.message.banner.title': 'I messaggi di stato sono presentati da', // template
  'profile.statusmessenger.header': 'Ultimo messaggio di stato',

  'blog.category.label': 'Categorie di blog', // template
  'modal.updater': 'Nuovo aggiornamento disponibile.', // template
  'user.profile.renew.modal.additionalCosts': 'incl. Regioni e rubriche',
  'filterpagetags.title': 'Collegamenti interessanti', // template 
  'filter.button.more': 'Mostra altri profili', // template 

  'blog.meta.title': 'Il nostro blog per le avventure erotiche - escort.ch', // template
  'blog.meta.description': '★★★ Il nostro blog per le avventure erotiche nella vostra zona. Trova la tua escort perfetta! escort.ch ▷ Il nuovo portale del sesso e dell erotismo in Svizzera.', // template

  'faq.meta.title': 'Risposte alle vostre domande: FAQ complete', // template
  'faq.meta.description': '★★★ FAQ per avventure erotiche nella tua zona. ♥ Trova la tua escort perfetta! escort.ch ▷ Il nuovo portale del sesso e dell erotismo in Svizzera.', // template


  'login.meta.title': 'Andiamo! Effettua il login e tuffati', // template
  'login.meta.description': '★★★ Il tuo login per le avventure erotiche nella tua zona. ♥ Trova la tua escort perfetta! escort.ch ▷ Il nuovo portale del sesso e dell erotismo in Svizzera.', // template

  'support.meta.title': 'Centro di assistenza per ulteriori domande', // template
  'support.meta.description': '★★★ Il nostro supporto per le avventure erotiche nella tua zona. ♥ Trova la tua escort perfetta! escort.ch ▷ Il nuovo portale del sesso e dell erotismo in Svizzera.', // template


  'pwainstaller.title': 'Installare l app escort.ch', // template
  'pwainstaller.description': 'La PWA (Progressive Web App) di escort.ch offre numerosi vantaggi che migliorano notevolmente l esperienza su escort.ch. Uno dei principali benefici è il miglioramento dell accessibilità. Gli utenti possono accedere all applicazione facilmente tramite il loro browser, senza la necessità di scaricare un app separata. Questo risparmia spazio di archiviazione sul dispositivo e facilita l accesso.', // template



 };
